import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { HttpLoadingService } from '../../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class MotherhoodVacationsService {
  private url = environment.ApiUrl + '/motherhood-vacation/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findAll(userId: string): Observable<any> {
    return this.http.get(
      this.url + userId + `?cache_bust=${new Date().getTime()}`
    );
  }
  create(userId: string, data: any): Observable<any> {
    return this.http.post(this.url + userId, data);
  }

  createStage(motherhoodId: string, data: any) {
    return this.http.post(this.url + 'stage/' + motherhoodId, data);
  }

  update(vacationId: string, data: any): Observable<any> {
    return this.http.patch(this.url + vacationId, data);
  }

  filter(userId: string, type: string, data: any): Observable<any> {
    return this.http.post(
      this.url + 'filter/' + userId + `?cache_bust=${new Date().getTime()}`,
      data
    );
  }

  remove(vacationId: string) {
    return this.http.delete(this.url + vacationId);
  }

  removeStage(motherhoodId: string, stageId: string) {
    return this.http.delete(this.url + 'stage/' + motherhoodId, {
      params: {
        stageId
      }
    });
  }
}
