<form>
  <div class="card-body">
    <div class="row border border-radius-25 bg-white mb-2">
      <div class="col-md-4">
        <div class="icheck-greensea ml-3">
          <input
            class="box"
            type="checkbox"
            name="employmentDegree"
            [(ngModel)]="item.isEmploymentDegree"
            id="isEmploymentDegree" />
          <label for="isEmploymentDegree"> ألشهادة ألمتعين عليها ؟ </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="icheck-greensea ml-3">
          <input
            class="box"
            type="checkbox"
            name="isRehireDegree"
            [(ngModel)]="item.isRehireDegree"
            id="isRehireDegree" />
          <label for="isRehireDegree"> شهادة أعادة ألتعيين ؟ </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="icheck-greensea ml-3">
          <input
            class="box"
            type="checkbox"
            name="currentDegree"
            [(ngModel)]="item.isCurrentDegree"
            id="isCurrentDegree" />
          <label for="isCurrentDegree"> ألشهادة ألحالية ؟ </label>
        </div>
      </div>
    </div>

    <app-location-info [Item]="item"></app-location-info>
    <div class="row">
      <div class="col-md-4">
        <app-datepicker
          [input]="item.degreeDate"
          [name]="'degreeDate'"
          [label]="'تاريخ ألحصول على ألشهادة'"
          (dateChange)="item.degreeDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <label for="documentNumber" class="form-label">
          رقم ألأمر ألأداري :</label
        >
        <div class="input-group">
          <input
            type="text"
            id="documentNumber"
            name="documentNumber"
            #Booknumber="ngModel"
            class="form-control text-center"
            [(ngModel)]="item.documentNumber" />
          <div class="input-group-prepend">
            <span class="input-group-text font-bold"> # </span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label for="schoolName" class="form-label">أسم ألمدرسة :</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            name="schoolName"
            id="schoolName"
            placeholder=""
            [(ngModel)]="item.schoolName"
            required
            #schoolName="ngModel"
            [ngClass]="{
              'is-invalid':
                schoolName.errors && degreeUtilService.isDegreeFormSubmitted
            }" />
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fas fa-school" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mb-2">
      <div class="col-md-3 mt-2 pr-1">
        <label class="form-label"> ألدراسة </label>
        <span class="float-left">:</span>
      </div>
      <div class="col-md-6 d-flex border border-radius-20">
        <div
          class="icheck-greensea ml-3 width-30"
          *ngFor="let type of SchoolStudyingTypes; let i = index">
          <input
            class="box"
            type="radio"
            name="SchooltudyingType"
            [(ngModel)]="item.studyingType"
            [value]="type"
            id="SchooltudyingType_{{ i }}" />
          <label for="SchooltudyingType_{{ i }}" class="px-5">
            {{ type }}
          </label>
        </div>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col-md-3 mt-2 pr-1">
        <label class="form-label"> ألتعليم ألــ</label>
        <span class="float-left">:</span>
      </div>
      <div class="col-md-6 d-flex border border-radius-20">
        <div
          class="icheck-greensea ml-3 width-30"
          *ngFor="let type of EducationTypes; let i = index">
          <input
            class="box"
            type="radio"
            name="educationType"
            [(ngModel)]="item.educationType"
            [value]="type"
            id="educationType_{{ i }}" />
          <label for="educationType_{{ i }}" class="px-5"> {{ type }} </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
          <mat-label class="px-3">ألملاحضات </mat-label>
          <textarea
            matInput
            placeholder=""
            name="notes"
            rows="3"
            class="py-0"
            [(ngModel)]="item.notes"></textarea>
          <mat-icon matSuffix class="text-secondary">comment</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <app-files
      [Files]="item.Files"
      [uploadedFiles]="item.files"
      (fileDelete)="degreeUtilService.removeFile(item._id, $event)"
      [header]="'ألوثائق و ألاوامر ألادارية'">
    </app-files>
  </div>
</form>
