<div class="card-body">
  <div class="row border border-radius-25 bg-white mb-2">
    <div class="col-md-4">
      <div class="icheck-greensea ml-3">
        <input
          class="box"
          type="checkbox"
          name="employmentDegree"
          [(ngModel)]="item.isEmploymentDegree"
          id="isEmploymentDegree" />
        <label for="isEmploymentDegree"> ألشهادة ألمتعين عليها ؟ </label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="icheck-greensea ml-3">
        <input
          class="box"
          type="checkbox"
          name="isRehireDegree"
          [(ngModel)]="item.isRehireDegree"
          id="isRehireDegree" />
        <label for="isRehireDegree"> شهادة أعادة ألتعيين ؟ </label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="icheck-greensea ml-3">
        <input
          class="box"
          type="checkbox"
          name="currentDegree"
          [(ngModel)]="item.isCurrentDegree"
          id="isCurrentDegree" />
        <label for="isCurrentDegree"> ألشهادة ألحالية ؟ </label>
      </div>
    </div>
  </div>

  <div class="row mb-0 mx-0">
    <div class="col-md-4 mt-2 pr-0">
      <label class="form-label mr-1"> نوع ألشهاده </label>
      <span class="float-left">:</span>
    </div>
    <div class="col-md-4 d-flex justify-content-between">
      <div class="icheck-greensea ml-3">
        <input
          class="box"
          type="radio"
          name="nodegreetype"
          [(ngModel)]="item.type"
          value="أمي"
          id="no-degree-1" />
        <label for="no-degree-1" class="px-2"> أمي </label>
      </div>
      <div class="icheck-greensea ml-3">
        <input
          class="box"
          type="radio"
          name="nodegreetype"
          [(ngModel)]="item.type"
          value="يقرأ ويكتب"
          id="no-degree-2" />
        <label for="no-degree-2" class="px-2"> يقرأ ويكتب </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
        <mat-label class="px-3">ألملاحضات </mat-label>
        <textarea
          matInput
          name="notes"
          placeholder=""
          rows="3"
          class="py-0"
          [(ngModel)]="item.notes"></textarea>
        <mat-icon matSuffix class="text-secondary">comment</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>
