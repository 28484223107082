import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FilesService {
  public url = environment.ApiUrl + '/files/';
  constructor(private http: HttpClient) {}
  getFile(id: string) {
    this.http.get(this.url + 'file/' + id);
  }
  getFileUrl(id: string) {
    return this.url + 'file/' + id;
  }

  getFileAttachment(id: string): Observable<any> {
    return this.http.get(this.url + 'file/' + id, {
      responseType: 'blob',
      reportProgress: true
    });
  }

  downloadArchive(id: string) {
    return this.url + 'archive/' + id;
  }

  downloadAllEmployeeFiles(id: string): Observable<Blob> {
    return this.http
      .get(this.url + 'allemployeefiles/' + id, {
        responseType: 'blob',
        reportProgress: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const message =
            error.error instanceof ErrorEvent
              ? `Error: ${error.error.message}`
              : `Error Code: ${error.status}\nMessage: ${error.message}`;
          alert(message);
          return of(null);
        })
      );
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this.url + id);
  }

  getOwnerFiles(id: string): Observable<any> {
    return this.http.get(this.url + 'owner/' + id);
  }
}
