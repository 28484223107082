<div class="card summary" #content id="content">
  <div class="card-header">
    <h4><i class="fa fa-list"></i> خلاصة ألعلاوات وألترفيعات</h4>
    <label class="px-2 d-none d-print-block">
      {{ currentDate | formatDate | arabicDate }}</label
    >
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="print()" title="طباعة ألأستمارة">
        <i class="fa fa-print fa-1x"></i>
      </button>
    </div>
  </div>
  <div class="card-body p-0 bg-white">
    <table id="summary" class="table border-0 my-0">
      <tbody class="p-0 m-0">
        <tr>
          <td>
            <div class="row">
              <div class="col-2 title border-left">ألكلية أو ألمركز</div>
              <div class="col-10 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">ألأسم ألرباعي</div>
              <div class="col-5 value"></div>
              <div class="col-2 title text-center">ألجنس</div>
              <div class="col-2 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">ألعنوان ألوظيفي ألحالي</div>
              <div class="col-8 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">تاريخ ألتعيين ألأول وألشهادة</div>
              <div class="col-3 value"></div>
              <div class="col-2 title text-center">ألشهادة</div>
              <div class="col-3 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">تاريخ أنهاء ألخدمات</div>
              <div class="col-8 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">تاريخ أعادة ألتعيين وألشهادة</div>
              <div class="col-3 value"></div>
              <div class="col-2 title text-center">ألشهادة</div>
              <div class="col-3 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">ألدرجة ألوظيفية ألحالية</div>
              <div class="col-4 value"></div>
              <div class="col-2 title text-center">ألمرحله</div>
              <div class="col-2 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-2 title">تاريخ أخر ترفيع</div>
              <div class="col-2 value"></div>
              <div class="col-4 title">رقم وتاريخ ألأمر ألجامعي بالترفيع</div>
              <div class="col-2 value"></div>
              <div class="col-2 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">ألشهادات ألحاصل عليها</div>
              <div class="col-2 value"></div>
              <div class="col-3 title">تاريخ ألحصول عليها</div>
              <div class="col-3 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-9 title">
                قدم ألشهادة ألحاصل عليها على أن لا تكون أتخذت أساسا لتحديد راتبه
                ألحالي
              </div>
              <div class="col-3 value"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title text-justify">
                ألأجازات ألغير محسوبة لأغراض ألعلاوة وألترفيع ألممنوحة حصرا بعد
                أخر ترفيع .
              </div>
              <div class="col-8 px-0 border-0">
                <div class="row">
                  <div class="col-3 title">من تاريخ</div>
                  <div class="col-3 title">ألى تاريخ</div>
                  <div class="col-3 title">مدة ألأجازه</div>
                  <div class="col-3 title">نوع ألأجازه</div>
                </div>
                <div class="row">
                  <div class="col-3 value">2022-01-01</div>
                  <div class="col-3 value">2022-01-01</div>
                  <div class="col-3 value">0 يوم</div>
                  <div class="col-3 value">بدون راتب</div>
                </div>
                <div class="row">
                  <div class="col-3 value">2022-01-01</div>
                  <div class="col-3 value">2022-01-01</div>
                  <div class="col-3 value">0 يوم</div>
                  <div class="col-3 value">خمس سنوات</div>
                </div>
                <div class="row">
                  <div class="col-3 title middle">وألبالغه</div>
                  <div class="col-9 px-0 border-0">
                    <div class="row">
                      <div class="col-4 title text-center">يوم</div>
                      <div class="col-4 title text-center">شهر</div>
                      <div class="col-4 title text-center">سنه</div>
                    </div>
                    <div class="row">
                      <div class="col-4 value text-center">0</div>
                      <div class="col-4 value text-center">0</div>
                      <div class="col-4 value text-center">0</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-6 title">
                رقم وتاريخ ألأمر ألجامعي بأضافة مدة ألفصل ألسياسي
              </div>
              <div class="col-6 value"></div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-4 middle title text-justify">
                كتب ألشكر ألممنوحة بالقدم حصرا بعد أخر ترفيع
              </div>
              <div class="col-8 px-0 border-0">
                <div class="row">
                  <div class="col-3 title">رقم ألكتاب</div>
                  <div class="col-3 title">تاريخ ألكتاب</div>
                  <div class="col-3 title">مدة القدم</div>
                  <div class="col-3 title">ألجهة ألمانحة</div>
                </div>
                <div class="row">
                  <div class="col-3 value">أ/55/ج</div>
                  <div class="col-3 value">2022-01-01</div>
                  <div class="col-3 value">1 شهر</div>
                  <div class="col-3 value">رئيس ألجمهورية</div>
                </div>
                <div class="row">
                  <div class="col-3 value">أ/55/ج</div>
                  <div class="col-3 value">2022-01-01</div>
                  <div class="col-3 value">1 شهر</div>
                  <div class="col-3 value">رئيس جامعه</div>
                </div>

                <div class="row">
                  <div class="col-6 title middle">مدة ألقدم ألكلية</div>
                  <div class="col-3 value text-center">0</div>
                  <div class="col-3 title text-center">شهر</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 middle title">ألعقوبات بعد أخر ترفيع</div>
              <div class="col-8 px-0 border-0">
                <div class="row">
                  <div class="col-3 title">نوع ألعقوبة</div>
                  <div class="col-3 value">توبيخ</div>
                  <div class="col-3 title">مدة ألتأخير</div>
                  <div class="col-3 value">----</div>
                </div>
                <div class="row">
                  <div class="col-3 title">للفتره من</div>
                  <div class="col-3 value">2022-01-01</div>
                  <div class="col-3 title">ولغاية</div>
                  <div class="col-3 value">2022-02-01</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">ألملاحظات</div>
              <div class="col-8 value min-height-64"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-12 title text-justify text-center">
                يرفق نسخة من ألأمر ألجامعي بأخر ترفيع مع نسخ مصورة من كتب ألشكر
                ألحاصل عليها و ألعقوبات وبخلافه سوف يتم اهمال معاملة ألترفيع دون
                ألرجوع أليكم
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">تاريخ ألأستحقاق</div>
              <div class="col-8 value"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row font-weight-bold my-10">
      <div class="col-md-3 text-center">منظم ألأستماره</div>
      <div class="col-md-3 text-center">مسؤول ألموارد ألبشريه</div>
      <div class="col-md-3 text-center">مدقق ألكليه</div>
      <div class="col-md-3 text-center">عميد ألكليه</div>
    </div>
    <div class="row font-weight-bold px-3">
      <div class="col-md-6">
        أعضاء أللجنة ألمشكلة بالأمر ألوزاري في رئاسة ألجامعة
      </div>
    </div>
    <div class="row mt-10 font-weight-bold">
      <div class="col-md-4 text-center">عضوا</div>
      <div class="col-md-4 text-center">عضوا</div>
      <div class="col-md-4 text-center">عضوا</div>
    </div>
  </div>
</div>
