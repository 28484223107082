import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { ItemFlatNode, ItemNode } from 'src/app/models/system/structure';
import { StructureService } from 'src/app/services/system-manager/structure-http-service';

@Directive()
export class Tree {
  constructor(protected structureService: StructureService) {}

  hasChild = (_: number, node: ItemFlatNode) => node.expandable;
  getLevel = (node: ItemFlatNode) => node.level;

  protected _transformer = (node: ItemNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      type: node.type,
      _id: node._id
    };
  };

  treeControl = new FlatTreeControl<ItemFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  findCurrentNode(nodeName: string) {
    if (nodeName != null) {
      const Index = this.treeControl.dataNodes.findIndex(
        n => n.name == nodeName
      );
      const currentNode = this.treeControl.dataNodes[Index];
      if (currentNode) this.treeControl.expand(currentNode);
      else this.treeControl.expandAll();
    } else {
      this.treeControl.expandAll();
    }
  }

  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html'
})
export class TreeComponent extends Tree implements OnInit, OnChanges {
  @Input() treeData: any[] = [];
  @Input() searchTerm = '';
  ngOnInit(): void {
    this.dataSource.data = this.treeData;
    this.expandTree();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.treeData && changes.treeData.currentValue) {
      this.dataSource.data = this.treeData;
      if (this.treeControl.dataNodes.length) {
        const rootNode = this.treeControl.dataNodes[0];
        this.treeControl.expand(rootNode);
      }
    }
    if (changes.searchTerm && changes.searchTerm.currentValue) {
      this.filterTree(this.searchTerm.trim());
    }
  }

  filterTree(filterText: string) {
    if (this.searchTerm.length) {
      this.dataSource.data = this.filterRecursive(
        filterText,
        this.treeData,
        'name'
      );
    } else {
      this.dataSource.data = this.treeData;
    }
    this.expandTree();
  }

  expandTree() {
    if (this.treeControl.dataNodes.length) {
      const rootNode = this.treeControl.dataNodes[0];
      this.treeControl.expand(rootNode);
    }
  }

  filterRecursive(filterText: string, array: any[], property: string) {
    let filteredData;

    //make a copy of the data so we don't mutate the original
    function copy(o: any) {
      return Object.assign({}, o);
    }

    // has string
    if (filterText.length) {
      filteredData = array.map(copy).filter(function x(y) {
        if (y[property].toLowerCase().includes(filterText)) {
          return true;
        }
        // if children match
        if (y.children) {
          return (y.children = y.children.map(copy).filter(x)).length;
        }
      });
      // no string, return whole array
    } else {
      filteredData = array;
    }

    return filteredData;
  }
}

@Component({
  selector: 'app-workplace-tree-view',
  templateUrl: './workplace-tree-view.html',
  styleUrls: ['./tree.scss']
})
export class WorkplaceTreeViewComponent
  extends Tree
  implements OnInit, OnChanges
{
  @Input() treeData: any[] = [];

  ngOnInit() {
    this.dataSource.data = this.treeData;
    this.treeControl.expandAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.treeData && changes.treeData.currentValue) {
      this.dataSource.data = this.treeData;
      this.treeControl.expandAll();
    }
  }
}

@Component({
  selector: 'app-workplace-entry-view',
  templateUrl: './workplace-entry-view.html'
})
export class WorkplaceEntryViewComponent implements OnInit {
  @Input() titles: string[] = [];
  @Input() Data: any[] = [];
  @Input() label = false;
  @Output() dataChange = new EventEmitter<string>();

  ngOnInit() {
    if (this.Data.length == 0) {
      this.push();
    }
  }

  push() {
    this.Data.push({
      name: ''
    });
  }

  remove(i: number) {
    this.Data.splice(i, 1);
    this.dataChange.emit('true');
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      if (this.Data.length < this.titles.length) this.push();
    }
  }
  onChange(event: any) {
    const value = event.target.value;
    if (value.length != 0) {
      this.dataChange.emit('true');
    }
  }
}

@Component({
  selector: 'app-workplace-collapse-view',
  templateUrl: './workplace-collapse.html',
  styleUrls: ['./tree.scss']
})
export class WorkplaceCollapseComponent {
  @Input() treeData: any[] = [];
  @Input() header = '';
  id = 'workpalce-collapse';
}
