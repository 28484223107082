<div class="row justify-content-center">
  <div class="col-8 col-md-6 col-lg-4">
    <app-files-image-file-uploader
      (fileChange)="basicInfoService.uploadPhoto()"
      [image]="basicInfoService.profileImage"
      [dimensions]="{
        width: '400px !important',
        height: '280px !important'
      }"></app-files-image-file-uploader>
  </div>
</div>
