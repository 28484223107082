import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DataTablesModule } from 'angular-datatables';
import {
  BsDatepickerConfig,
  BsDatepickerModule
} from 'ngx-bootstrap/datepicker';

import { MaterialModule } from '../material';
import {
  DatepickerComponent,
  SelectpickerComponent
} from './controls/controls';
import { DataImporterComponent } from './data-injection/data-injection';
import { DatseFilterComponent } from './datse-filter/datse-filter.component';
import {
  ConfirmationDialogComponent,
  NoDataComponent,
  PreloadedStructureDialogComponent,
  StructureDialogComponent
} from './dialogs/dialogs';
import {
  FilesComponent,
  ImageFileUploaderComponent
} from './files/files.component';
import { PageHeaderInfoComponent } from './page-header-info/page-header-info.component';
import {
  ArabicDatePipe,
  FormatDate,
  TranslateDigitsPipe
} from './pipes/translate-digits.pipe';
import { SpinnerComponent } from './spinner/spinner.component';
import { EmployeeServiceSummaryComponent } from './summary/service.summary';
import {
  TreeComponent,
  WorkplaceCollapseComponent,
  WorkplaceEntryViewComponent,
  WorkplaceTreeViewComponent
} from './tree/tree.component';
import { ChangeUserWorkplaceFormComponent } from './users/move.user';
import { UserEntityFormComponent } from './users/users';

@NgModule({
  declarations: [
    DatepickerComponent,
    SelectpickerComponent,
    FilesComponent,
    ImageFileUploaderComponent,
    DatseFilterComponent,
    PageHeaderInfoComponent,
    SpinnerComponent,
    StructureDialogComponent,
    ConfirmationDialogComponent,
    TreeComponent,
    UserEntityFormComponent,
    TranslateDigitsPipe,
    ArabicDatePipe,
    FormatDate,
    ChangeUserWorkplaceFormComponent,
    NoDataComponent,
    EmployeeServiceSummaryComponent,
    WorkplaceTreeViewComponent,
    WorkplaceEntryViewComponent,
    WorkplaceCollapseComponent,
    PreloadedStructureDialogComponent,
    DataImporterComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    MaterialModule,
    DataTablesModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    DatepickerComponent,
    SelectpickerComponent,
    FilesComponent,
    ImageFileUploaderComponent,
    DatseFilterComponent,
    PageHeaderInfoComponent,
    SpinnerComponent,
    StructureDialogComponent,
    UserEntityFormComponent,
    TranslateDigitsPipe,
    ArabicDatePipe,
    FormatDate,
    ChangeUserWorkplaceFormComponent,
    NoDataComponent,
    EmployeeServiceSummaryComponent,
    WorkplaceTreeViewComponent,
    WorkplaceEntryViewComponent,
    WorkplaceCollapseComponent,
    PreloadedStructureDialogComponent,
    DataImporterComponent
  ],
  providers: [BsDatepickerConfig, DatePipe, TranslateDigitsPipe, ArabicDatePipe]
})
export class SharedComponentsModule {}
