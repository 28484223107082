import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasicInfoUtilService } from 'src/app/hr/employee/bacic-info/basicinfo-util-service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EmployeeWorkplacesService } from 'src/app/services/employee/employee-workplaces';
import { StructureService } from 'src/app/services/system-manager/structure-http-service';
import { WorkplaceUtilService } from 'src/app/services/util/workplace.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {
  roleLevels: string[] = ['قراءه', 'كتابه', 'حذف'];

  constructor(
    public authService: AuthenticationService,
    public employeeWorkplaceService: EmployeeWorkplacesService,
    private structureServise: StructureService,
    public workplaceUtilService: WorkplaceUtilService,
    public basicInfoService: BasicInfoUtilService,
    private router: Router
  ) {}
  workplaceList: string[];
  ngOnInit() {
    if (!this.authService.isLoggedIn()) return this.navigateTo('login');
    this.basicInfoService.userId = this.authService.getUserDetails().userId;
    this.basicInfoService.getUserPhoto();
    this.getWorkplace();
  }

  navigateTo(route: string) {
    this.router.navigate(['/' + route]);
  }

  getWorkplace() {
    this.structureServise
      .findOne(this.authService.getUserDetails().workplaceId)
      .subscribe(response => {
        this.workplaceList =
          this.workplaceUtilService.getBranchesList(response);
      });
  }

  isChecked(role: any, level: string) {
    return role.priviliges.indexOf(level) !== -1;
  }
  noDataMessage =
    'ليس لديك أي صلاحيات داخل نظام أدارة ألموظفين في ألوقت ألحالي . أذا كانت لديك أي صلاحيات أدارية يرجى ألتواصل مع ادارة ألنظام في رئاسة ألجامعه لغرض أضافة ألصلاحيات ألمخول بها .';
}
