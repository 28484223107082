<div *ngIf="SecondaryWorkplace === null" class="text-center">
  <app-no-data-message [message]="noDataMessage"></app-no-data-message>
</div>

<div class="card" *ngIf="SecondaryWorkplace !== null">
  <div class="card-header">
    <h4 class="pl-5"><i class="fa fa-university"> </i> ألجهة ألمنسب أليها</h4>
    <div class="icheck-greensea ml-3 my-2 d-none d-md-block">
      <input
        class="box"
        type="checkbox"
        name="terminate"
        [(ngModel)]="terminateSecondaryWorkplace"
        id="terminateAllocation" />
      <label for="terminateAllocation"> أنهاء ألتنسيب</label>
    </div>

    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button
        class="btn btn-print btn-block"
        title="تحديث ألجهة ألمنسب أليها "
        (click)="openDialog()">
        <i class="fa fa-university mx-2"></i>
      </button>
      <button
        class="btn btn-print"
        (click)="removeSecondaryWorkplace()"
        title="حفظ ألمعلومات ">
        <i class="fa fa-save fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-8 mb-2">
        <div
          class="border px-3 border-radius-25 bg-white border-success border-success"
          *ngIf="SecondaryWorkplace.fullname.length">
          <app-workplace-tree-view
            [treeData]="
              getWorkplaceTree(SecondaryWorkplace)
            "></app-workplace-tree-view>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="h-100"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          ">
          <div
            class="d-flex justify-content-between border pt-2 pr-3 badge-pill mt-0 mt-sm-1 bg-white border-success">
            <label class="d-block w-50">
              ملاك ألموظف
              <span class="float-left font-weight-bold px-2">:</span>
            </label>
            <div class="d-flex w-50">
              <span>{{ SecondaryWorkplace.employmentType }} </span>
            </div>
          </div>
          <div
            class="d-flex justify-content-between border pt-2 pr-3 badge-pill my-2 bg-white border-success">
            <label class="d-block w-50">
              نوع ألأنتساب
              <span class="float-left font-weight-bold px-2">:</span>
            </label>
            <div class="d-flex w-50">
              <span>{{ SecondaryWorkplace.movementType }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <app-datepicker
          [input]="SecondaryWorkplace.fromDate"
          [name]="'startDate'"
          [label]="'تاريخ ألمباشره'"
          (dateChange)="SecondaryWorkplace.fromDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <app-datepicker
          [input]="SecondaryWorkplace.fromDocumentDate"
          [name]="'fromDocumentDate'"
          [label]="'تاريخ ألأمر ألأداري بالمباشره '"
          (dateChange)="SecondaryWorkplace.fromDocumentDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <label for="fromDocumentNumber" class="form-label">
          رقم ألامر ألأداري باالمباشره:</label
        >
        <div class="input-group">
          <input
            type="text"
            id="fromDocumentNumber"
            name="fromDocumentNumber"
            #fromDocumentNumber="ngModel"
            class="form-control text-center"
            [(ngModel)]="SecondaryWorkplace.fromDocumentNumber" />
          <div class="input-group-prepend">
            <span class="input-group-text font-bold"> # </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="terminateSecondaryWorkplace === true">
      <div class="col-md-4">
        <app-datepicker
          [input]="SecondaryWorkplace.toDate"
          [name]="'endDate'"
          [label]="'تاريخ ألأنفكاك'"
          (dateChange)="SecondaryWorkplace.toDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <app-datepicker
          [input]="SecondaryWorkplace.toDocumentDate"
          [name]="'toDocumentDate'"
          [label]="'تاريخ ألأمر ألأداري بالأنفكاك '"
          (dateChange)="SecondaryWorkplace.toDocumentDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <label for="toDocumentNumber" class="form-label">
          رقم ألامر ألأداري بالأنفكاك:</label
        >
        <div class="input-group">
          <input
            type="text"
            id="toDocumentNumber"
            name="toDocumentNumber"
            #toDocumentNumber="ngModel"
            class="form-control text-center"
            [(ngModel)]="SecondaryWorkplace.toDocumentNumber" />
          <div class="input-group-prepend">
            <span class="input-group-text font-bold"> # </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
          <mat-label class="px-3">ألملاحضات </mat-label>
          <textarea
            matInput
            placeholder=""
            name="notes"
            rows="3"
            class="py-0"
            [(ngModel)]="SecondaryWorkplace.notes"></textarea>
          <mat-icon matSuffix class="text-secondary">comment</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-files
          [Files]="SecondaryWorkplace.Files"
          [uploadedFiles]="SecondaryWorkplace.files"
          (fileDelete)="removeFile(SecondaryWorkplace._id, $event)"
          [header]="'ألأوامر ألأدارية ألخاصة  بالتنسيب و ألمباشره'">
        </app-files>
      </div>
    </div>
  </div>
</div>
