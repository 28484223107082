import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { HttpLoadingService } from '../../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class VacationSummaryService {
  private url = environment.ApiUrl + '/employeevacation/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findAll(userId: string, isCurrentDate: boolean): Observable<any> {
    return this.http.get(
      this.url + userId + `?cache_bust=${new Date().getTime()}`,
      {
        params: {
          isCurrentDate
        }
      }
    );
  }

  filter(userId: string, data: any): Observable<any> {
    return this.http.post(
      this.url + userId + `?cache_bust=${new Date().getTime()}`,
      data
    );
  }
}
