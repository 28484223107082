import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DatatableUtil } from 'src/app/classes/datatable';
import { Grade, privateGrades } from 'src/app/classes/grades';
import { positions } from 'src/app/classes/positions';
import { PositionModel } from 'src/app/models/hr/employee/positions';
import { DataPersistanceService } from 'src/app/services/data-persistence/data-persistance';
import { UniversityService } from 'src/app/services/data-persistence/university-info';
import { EmployeePositionService } from 'src/app/services/employee/employee-positions';
import { StructureService } from 'src/app/services/system-manager/structure-http-service';
import { WorkplaceUtilService } from 'src/app/services/util/workplace.service';
import {
  ConfirmationDialogComponent,
  PreloadedStructureDialogComponent
} from 'src/app/shared-components/dialogs/dialogs';

import { PositionViewsHandler } from './employee-positions-utils-service';

@Component({
  selector: 'app-administrative-positions',
  templateUrl: './administrative-positions.component.html',
  styleUrls: ['./administrative-positions.component.scss']
})
export class AdministrativePositionsComponent {}

@Component({
  selector: 'app-position-list',
  templateUrl: './templates/position-list.html'
})
export class PositionListComponent implements OnInit, AfterViewInit {
  @Input() title = '';
  @Input() query: any = {};
  items: any[] = [];
  SelectedItem: any = null;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();

  constructor(
    private employeePositionService: EmployeePositionService,
    private dialog: MatDialog,
    public workplaceUtilService: WorkplaceUtilService,
    public viewHandler: PositionViewsHandler,
    private Activeroute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.viewHandler.getSubmissionEvent().subscribe(() => {
      this.findAll();
    });
  }
  async ngAfterViewInit() {
    this.viewHandler.userId =
      await this.Activeroute.parent.parent.snapshot.params['userId'];
    this.dt.setDtElement(this.dtElement);
    this.findAll();
    this.dt.trigger();
  }

  findAll() {
    this.employeePositionService
      .findAll(this.viewHandler.userId, this.query)
      .subscribe(response => {
        this.items = response;
        this.dt.reload();
        this.viewHandler.changeViewEvent('list');
      });
  }

  getWorkplaceTree(Item: any) {
    return this.workplaceUtilService.convertToTree(
      Item.OfficeFullname.split('-')
    );
  }

  newOrEdit(view: string) {
    if (view == 'edit') {
      this.viewHandler.SelectedItem = this.SelectedItem;
    }
    this.viewHandler.changeViewEvent(view);
  }

  removePosition() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      position: {
        top: '65px'
      },
      data: {
        title: ` حذف ألمصب ألأداري `,
        warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات هذا ألمنصب بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
        confirmationMessage: 'هل أنت متأكد من أتمام عملية ألحذف ؟',
        yesNo: { yes: 'نعم', no: 'كلا' }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.employeePositionService
          .removePosition(this.SelectedItem._id)
          .subscribe(() => {
            this.findAll();
          });
      }
    });
  }
}

@Component({
  selector: 'app-position-form',
  templateUrl: './templates/position-form.html'
})
export class PositionFormComponent implements OnInit {
  @Input() Item: PositionModel;
  @Input() titles: string[] = [];
  @Input() type = '';
  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
  positions: string[] = positions;
  privateGrades: Array<Grade> = privateGrades;
  employmentTypes: string[] = ['دائمي', 'عقد', 'أجور يومية'];
  SelectedWorkplace: any = null;
  treeData: any[] = [];

  constructor(
    private employeepositionService: EmployeePositionService,
    private structureService: StructureService,
    public workplaceUtilService: WorkplaceUtilService,
    public viewHandler: PositionViewsHandler,
    private dialog: MatDialog,
    public University: UniversityService,
    private DataPersistant: DataPersistanceService
  ) {}
  ngOnInit() {
    this.Item.fromDate = new Date(this.Item.fromDate);
    this.Item.toDate = new Date(this.Item.toDate);
    this.Item.fromDocumentDate = new Date(this.Item.fromDocumentDate);
    this.Item.toDocumentDate = new Date(this.Item.toDocumentDate);
    this.getWorkplaceTree();
  }
  submit() {
    if (this.Item._id == null) {
      this.employeepositionService
        .create(this.viewHandler.userId, this.Item.toFormData())
        .subscribe(() => {
          this.viewHandler.trigerSubmissionEvent();
        });
    } else {
      this.employeepositionService
        .update(this.Item._id, this.Item.toFormData())
        .subscribe(() => {
          this.viewHandler.trigerSubmissionEvent();
        });
    }
  }

  checkPrivateGrades(selectedPosition: string) {
    const index = this.privateGrades.findIndex(
      grade => grade.jobTitles.includes(selectedPosition) == true
    );

    this.Item.privateGrade = index == -1 ? '' : privateGrades[index].grade;
  }

  onDataChange() {
    const stringArray = this.treeData.map((obj: { name: string }) => obj.name);
    this.Item.OfficeFullname = stringArray.join('-');
  }

  async openUniversityDialog() {
    const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
      width: '61vw',
      position: {
        top: '65px'
      },
      data: {
        title: this.University.Name,
        treeData: await this.DataPersistant.getUniversityData()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getWorkplace(result._id);
      }
    });
  }

  async openMinstryDialog() {
    const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
      width: '61vw',
      position: {
        top: '65px'
      },
      data: {
        title: 'وزارة ألتعليم ألعالي وألدوائر ألتابعه لها',
        treeData: await this.DataPersistant.getMinistryData()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getWorkplace(result._id);
      }
    });
  }

  getWorkplace(selectedpositionId: string) {
    this.structureService.findOne(selectedpositionId).subscribe(response => {
      this.Item.OfficeFullname =
        this.workplaceUtilService.getFullPath(response);
      this.Item.workplaceId = selectedpositionId;
      this.getWorkplaceTree();
      this.Item.office = response.ancestors[0].name;
    });
  }

  getWorkplaceTree() {
    if (this.Item.isMoh) {
      this.treeData = this.workplaceUtilService.convertToTree(
        this.Item.OfficeFullname.split('-')
      );
    } else {
      if (this.Item.OfficeFullname.length) {
        this.treeData = this.Item.OfficeFullname.split('-').map(
          (name: string) => ({ name })
        );
      }
    }
  }

  removeFile(positionId: string, fileId: string) {
    this.employeepositionService
      .removePositionFile(positionId, { fileId })
      .subscribe(() => null);
  }
}

@Component({
  selector: 'app-employee-university-positions',
  templateUrl: './templates/positions.html'
})
export class UniversityPositionComponent implements OnInit {
  titles: string[] = [];
  view = 'list';
  title: string = this.University.Name;
  query: any = {};

  position: PositionModel = new PositionModel();
  constructor(
    private viewHandler: PositionViewsHandler,
    private University: UniversityService
  ) {}

  ngOnInit(): void {
    this.title = this.University.Name;
    this.query = {
      $and: [{ isMoh: true }, { office: { $eq: this.University.Name } }]
    };

    this.viewHandler.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.position = new PositionModel();
    if (view == 'edit') {
      this.position = Object.assign(
        this.position,
        this.viewHandler.SelectedItem
      );
    }
    this.view = view;
  }
}

@Component({
  selector: 'app-employee-moh-positions',
  templateUrl: './templates/positions.html'
})
export class MohPositionComponent implements OnInit {
  view = 'list';
  title = 'وزارة ألتعليم ألعالي';
  query: any = {
    $and: [{ isMoh: true }, { office: { $ne: this.University.Name } }]
  };
  titles: string[] = [];

  position: PositionModel = new PositionModel();
  constructor(
    private viewHandler: PositionViewsHandler,
    private University: UniversityService
  ) {}

  ngOnInit(): void {
    this.viewHandler.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.position = new PositionModel();
    if (view == 'edit') {
      this.position = Object.assign(
        this.position,
        this.viewHandler.SelectedItem
      );
    }
    this.view = view;
  }
}

@Component({
  selector: 'app-employee-other-positions',
  templateUrl: './templates/positions.html'
})
export class OtherPositionComponent implements OnInit {
  view = 'list';
  title = 'وزارات و دوائر أخرى';
  query: any = {
    isMoh: false
  };
  titles: string[] = [
    'ألوزارة أو ألرئاسة',
    'ألمديرية أو ألدائره',
    'ألقسم',
    'ألشعبه',
    'ألوحده'
  ];

  position: PositionModel = new PositionModel(false);
  constructor(private viewHandler: PositionViewsHandler) {}

  ngOnInit(): void {
    this.viewHandler.getViewEvent().subscribe(view => {
      view == 'list' ? (this.view = view) : this.newOrEdit(view);
    });
  }
  newOrEdit(view: string) {
    this.position = new PositionModel(false);
    if (view == 'edit') {
      this.position = Object.assign(
        this.position,
        this.viewHandler.SelectedItem
      );
    }
    this.view = view;
  }
}
