<form
  #frm="ngForm"
  class="needs-validation"
  (ngSubmit)="basicInfoService.submit(frm)">
  <div class="card">
    <div class="card-header">
      <h4><i class="fa fa-id-card"></i> معلومات ألاسم</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button type="submit" class="btn btn-print" title="حفظ ألمعلومات">
          <i class="fa fa-save fa-lg"></i>
        </button>
      </div>
    </div>

    <div class="card-body">
      <div class="row mb-1">
        <div class="col-md-6">
          <div class="row my-1">
            <div class="col-md-6 pt-2">
              <label for="firstname" class="label-arabic">ألاسم ألاول </label>
              <span class="font-bold pr-2 float-none float-md-left">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="firstname"
                id="firstname"
                placeholder=""
                [(ngModel)]="basicInfoService.employee.aFirstName"
                required
                #aFirstName="ngModel"
                minlength="3"
                lang="ar"
                [ngClass]="{
                  'is-invalid': aFirstName.errors && frm.submitted
                }" />
              <div *ngIf="aFirstName.invalid" class="invalid-feedback">
                <div *ngIf="aFirstName.errors.required !== null">
                  الاسم ألأول مطلوب
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-6 pt-2">
              <label for="secondname" class="form-label label-arabic"
                >أسم ألأب
              </label>
              <span class="font-bold pr-2 float-none float-md-left">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="secondname"
                id="secondname"
                placeholder=""
                [(ngModel)]="basicInfoService.employee.aFatherName"
                required
                #aFatherName="ngModel"
                minlength="3"
                lang="ar"
                [ngClass]="{
                  'is-invalid': aFatherName.errors && frm.submitted
                }" />
              <div *ngIf="aFatherName.invalid" class="invalid-feedback">
                <div *ngIf="aFatherName.errors.required !== null">
                  اسم ألاب مطلوب
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-md-6 pt-2">
              <label for="thirdname" class="form-label label-arabic"
                >أسم ألجد
              </label>
              <span class="font-bold pr-2 float-none float-md-left">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="thirdname"
                id="thirdname"
                placeholder=""
                [(ngModel)]="basicInfoService.employee.aGrandFatherName"
                required
                #aGrandFatherName="ngModel"
                minlength="3"
                lang="ar"
                [ngClass]="{
                  'is-invalid': aGrandFatherName.errors && frm.submitted
                }" />
              <div *ngIf="aGrandFatherName.invalid" class="invalid-feedback">
                <div *ngIf="aGrandFatherName.errors.required !== null">
                  أسم ألجد مطلوب
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-md-6 pt-2">
              <label for="fourthname" class="form-label label-arabic"
                >ألاسم ألرابع
              </label>
              <span class="font-bold pr-2 float-none float-md-left">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="fourthname"
                id="fourthname"
                placeholder=""
                [(ngModel)]="basicInfoService.employee.aFourthName"
                required
                #aFourthName="ngModel"
                minlength="3"
                lang="ar"
                [ngClass]="{
                  'is-invalid': aFourthName.errors && frm.submitted
                }" />
              <div *ngIf="aFourthName.invalid" class="invalid-feedback">
                <div *ngIf="aFourthName.errors.required !== null">
                  الاسم ألرابع مطلوب
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-md-6 pt-2">
              <label for="fourthname" class="form-label label-arabic"
                >أللقب
              </label>
              <span class="font-bold pr-2 float-none float-md-left">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="surename"
                id="surename"
                placeholder=""
                [(ngModel)]="basicInfoService.employee.aSureName"
                required
                #aSureName="ngModel"
                minlength="3"
                lang="ar"
                [ngClass]="{
                  'is-invalid': aSureName.errors && frm.submitted
                }" />
              <div *ngIf="aSureName.invalid" class="invalid-feedback">
                <div *ngIf="aSureName.errors.required !== null">
                  اللقب مطلوب
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row my-1" dir="ltr">
            <div class="col-md-6 pt-2 text-left">
              <label
                for="efirstname"
                class="form-label label-english text-secondary"
                >First Name</label
              >
              <span class="font-bold pl-2 float-none float-md-right">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="efirstname"
                id="efirstname"
                placeholder=""
                dir="ltr"
                [(ngModel)]="basicInfoService.employee.eFirstName"
                required
                #eFirstName="ngModel"
                minlength="3"
                lang="en"
                [ngClass]="{
                  'is-invalid': eFirstName.errors && frm.submitted
                }" />
              <div *ngIf="eFirstName.invalid" class="invalid-feedback">
                <div *ngIf="eFirstName.errors.required !== null">
                  الاسم ألأول مطلوب
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1" dir="ltr">
            <div class="col-md-6 pt-2 text-left">
              <label
                for="efirstname"
                class="form-label label-english text-secondary"
                >Father Name</label
              >
              <span class="font-bold pl-2 float-none float-md-right">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="esecondname"
                id="esecondname"
                placeholder=""
                dir="ltr"
                [(ngModel)]="basicInfoService.employee.eFatherName"
                required
                #eFatherName="ngModel"
                lang="en"
                [ngClass]="{
                  'is-invalid': eFatherName.errors && frm.submitted
                }" />
              <div *ngIf="eFatherName.invalid" class="invalid-feedback">
                <div *ngIf="eFatherName.errors.required !== null">
                  اسم ألاب مطلوب
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1" dir="ltr">
            <div class="col-md-6 pt-2 text-left">
              <label
                for="efirstname"
                class="form-label label-english text-secondary"
                >Grandfather Name</label
              >
              <span class="font-bold pl-2 float-none float-md-right">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="ethirdname"
                id="ethirdname"
                placeholder=""
                dir="ltr"
                [(ngModel)]="basicInfoService.employee.eGrandFatherName"
                required
                #eGrandFatherName="ngModel"
                minlength="3"
                lang="en"
                [ngClass]="{
                  'is-invalid': eGrandFatherName.errors && frm.submitted
                }" />
              <div *ngIf="eGrandFatherName.invalid" class="invalid-feedback">
                <div *ngIf="eGrandFatherName.errors.required !== null">
                  أسم ألجد مطلوب
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1" dir="ltr">
            <div class="col-md-6 pt-2 text-left">
              <label
                for="efirstname"
                class="form-label label-english text-secondary"
                >Fourth Name</label
              >
              <span class="font-bold pl-2 float-none float-md-right">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="efourthname"
                id="efourthname"
                placeholder=""
                dir="ltr"
                [(ngModel)]="basicInfoService.employee.eFourthName"
                required
                #eFourthName="ngModel"
                minlength="3"
                lang="en"
                [ngClass]="{
                  'is-invalid': eFourthName.errors && frm.submitted
                }" />
              <div *ngIf="eFourthName.invalid" class="invalid-feedback">
                <div *ngIf="eFourthName.errors.required !== null">
                  الاسم ألرابع مطلوب
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1" dir="ltr">
            <div class="col-md-6 pt-2 text-left">
              <label
                for="efirstname"
                class="form-label label-english text-secondary"
                >Surename</label
              >
              <span class="font-bold pl-2 float-none float-md-right">:</span>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                name="esurename"
                id="esurename"
                placeholder=""
                dir="ltr"
                [(ngModel)]="basicInfoService.employee.eSureName"
                required
                #eSureName="ngModel"
                minlength="3"
                lang="en"
                [ngClass]="{
                  'is-invalid': eSureName.errors && frm.submitted
                }" />
              <div *ngIf="eSureName.invalid" class="invalid-feedback">
                <div *ngIf="eSureName.errors.required !== null">
                  أللقب مطلوب :
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 pt-2">
          <label for="firstname"> أسم ألأم ألكامل </label>
          <span class="font-bold pr-2 float-none float-md-left">:</span>
        </div>
        <div class="col-md-9">
          <input
            type="text"
            class="form-control"
            name="mothername"
            id="mothername"
            placeholder=""
            [(ngModel)]="basicInfoService.employee.motherName"
            required
            #motherName="ngModel"
            minlength="3"
            lang="ar"
            [ngClass]="{ 'is-invalid': motherName.errors && frm.submitted }" />
          <div *ngIf="motherName.invalid" class="invalid-feedback">
            <div *ngIf="motherName.errors.required !== null">
              اسم ألام مطلوب
            </div>
          </div>
        </div>
      </div>

      <div class="row my-1">
        <div class="col-md-3">
          <app-datepicker
            [input]="basicInfoService.employee.birthDate"
            [name]="'birthDate'"
            [label]="'تاريخ ألميلاد'"
            (dateChange)="basicInfoService.employee.birthDate = $event">
          </app-datepicker>
        </div>
        <div class="col-md-3">
          <label class=" ">ألجنس : </label>
          <div
            class="d-flex justify-content-between border pr-3 pt-1 border-radius-25 bg-white">
            <div class="icheck-greensea ml-3 d-inline">
              <input
                class="box"
                type="radio"
                name="gender"
                [(ngModel)]="basicInfoService.employee.gender"
                value="ذكر"
                id="gendermale" />
              <label for="gendermale"> ذكر </label>
            </div>
            <div class="icheck-greensea ml-3 d-inline">
              <input
                class="box"
                type="radio"
                name="gender"
                [(ngModel)]="basicInfoService.employee.gender"
                value="أنثى"
                id="genderfemale" />
              <label for="genderfemale"> أنثى </label>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label for="religion" class="form-label">ألديانه :</label>
          <select
            class="custom-select form-control"
            [(ngModel)]="basicInfoService.employee.religion"
            name="religion"
            required>
            <option
              *ngFor="let item of religions; let i = index"
              [value]="item">
              {{ item }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <label for="nationalisim" class="form-label">ألقوميه :</label>
          <select
            class="custom-select form-control"
            [(ngModel)]="basicInfoService.employee.nationalisim"
            name="nationalisim"
            required>
            <option
              *ngFor="let item of nationalisims; let i = index"
              [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</form>
