<div class="wrapper">
  <div class="contents">
    <div class="container">
      <app-page-header-info
        [title2]="'أدارة صلاحيات ألمستخدم'"
        icon="fa fa-laptop"></app-page-header-info>
      <div class="content-container my-7 mx-md-4">
        <div class="card">
          <div class="card-header">
            <h4><i class="fa fa-user-lock"> </i> صلاحيات ألمستخدم</h4>

            <div class="type">
              <i class="fas fa-user-tag pl-2"></i>{{ User.fullname }}
            </div>
            <div
              class="toolbar d-flex d-inline-flex w-30 justify-content-between">
              <button
                id="back-to-main"
                class="btn btn-print"
                title="رجوع ألى ألقائمه"
                (click)="viewChange.emit('list')">
                <i class="fa fa-share fa-lg"></i>
              </button>
            </div>
          </div>
          <div
            class="card-body p-0"
            *ngIf="this.User._id"
            style="min-height: 200px"
            id="user-roles"
            cdkDropList
            (cdkDropListDropped)="openDialog($event)">
            <ol class="px-0">
              <li
                *ngFor="let role of userRoles; let i = index"
                class="px-4 py-3"
                style="font-size: 15px"
                [ngClass]="{
                  'border-bottom border-width-2': i < userRoles.length - 1
                }">
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label class="control-label d-block">
                      ألقسم
                      <span class="float-left">:</span>
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label class="control-label">
                      {{ role.roleId.category }}
                    </label>
                  </div>
                  <div class="col-md-2">
                    <label class="control-label d-block">
                      ألصلاحية
                      <span class="float-left">:</span>
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label class="control-label">
                      {{ role.roleId.name }}
                    </label>
                  </div>

                  <div class="col-md-1 text-center">
                    <button
                      class="btn p-0 text-secondary hover-danger"
                      (click)="removeUserRole(role._id)"
                      title="حذف ألصلاحية">
                      <i class="fa fa-trash fa-lg"></i>
                    </button>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label class="control-label d-block">
                      ألجهة ألمسؤول عنها
                      <span class="float-left">:</span>
                    </label>
                  </div>
                  <div class="col-md-9">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control px-1 bg-creamy"
                        style="font-size: 14px"
                        [value]="
                          workplaceUtilService.getFullPath(role.workplaceId)
                        "
                        disabled
                        name="workplace_{{ i }}"
                        id="workplace_{{ i }}"
                        placeholder="" />
                      <div class="input-group-prepend">
                        <span
                          class="input-group-text"
                          (click)="updateWorkplace(role._id)"
                          ><i class="fa fa-university fa-lg"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-md-3">
                    <label class="control-label d-block">
                      مستوى ألصلاحية
                      <span class="float-left">:</span>
                    </label>
                  </div>
                  <div class="col-md-9 pt-1 d-flex">
                    <div *ngFor="let level of roleLevels">
                      <label
                        *ngIf="isChecked(role.roleId, level)"
                        class="pl-10">
                        <i class="fas fa-check-square checked-item pl-3"></i>
                        {{ level }}
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar left-sidebar border-right">
    <div class="sidebar-tree-menue">
      <ul class="list-unstyled">
        <li
          *ngFor="let category of groupedRoles; let i = index"
          class="collapse-item"
          cdkDropList
          [cdkDropListConnectedTo]="['user-roles']">
          <a
            href="#category_{{ i }}"
            data-toggle="collapse"
            aria-expanded="true"
            class="dropdown-toggle"
            >{{ category.category }}
            <span class="badge badge-pill count">{{
              category.roles.length
            }}</span>
          </a>
          <ul class="collapse show list-unstyled" id="category_{{ i }}">
            <li
              *ngFor="let role of category.roles"
              [cdkDragData]="{ roleId: role._id, userId: User._id }"
              cdkDrag
              [cdkDragDisabled]="isCategoryExist(role.category)"
              title="Drag and Drop on Canvas">
              <div>
                <div
                  class="drag-handle d-inline pl-2"
                  cdkDragHandle
                  *ngIf="!isCategoryExist(category.category)">
                  <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </div>
                <i
                  class="fas fa-check-square checked-item"
                  aria-hidden="true"
                  *ngIf="
                    isCategoryExist(role.category) && isRoleExist(role._id)
                  "></i>
                <i
                  class="fas fa-ban checked-item text-danger"
                  aria-hidden="true"
                  *ngIf="
                    isCategoryExist(role.category) && !isRoleExist(role._id)
                  "></i>
                <span>{{ role.name }}</span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
