import { Component } from '@angular/core';

import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-error',
  template: `
    <div *ngIf="errorMessage | async as error" class="error-overlay">
      <div class="error-message">
        <p>{{ error }}</p>
        <button (click)="close()">Close</button>
      </div>
    </div>
  `,
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {
  errorMessage = this.errorService.error$;

  constructor(private errorService: ErrorService) {}

  close() {
    this.errorService.clearError();
  }
}
