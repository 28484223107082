<div>
  <div class="row mb-1 px-0" *ngFor="let item of Data; let i = index">
    <div class="col-md-9">
      <label for="item_{{ i }}" class="form-label" *ngIf="label">
        {{ titles[i] }} :
      </label>
      <div class="input-group">
        <input
          type="text"
          id="item_{{ i }}"
          name="item_{{ i }}"
          class="form-control"
          [placeholder]="titles[i]"
          [(ngModel)]="item.name"
          (keydown)="onKeyDown($event)"
          (change)="onChange($event)" />
        <div class="input-group-prepend">
          <span class="input-group-text font-bold">
            <i class="fa fa-university"></i>
          </span>
        </div>
      </div>
    </div>
    <div
      class="col-md-3 d-flex justify-content-between"
      *ngIf="i === Data.length - 1"
      [ngClass]="{ 'mt-5': label === true }">
      <button
        class="btn text-secondary hover-green"
        title="أضافة تفرع "
        (click)="push()"
        [disabled]="Data.length === titles.length || item.name.trim() === ''">
        <i class="fa fa-plus fa-lg"></i>
      </button>
      <button
        class="btn text-secondary hover-danger"
        title="حذف "
        (click)="remove(i)"
        [disabled]="Data.length === 1">
        <i class="fa fa-times fa-lg"></i>
      </button>
    </div>
  </div>
</div>
