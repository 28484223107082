<form #frm="ngForm" (ngSubmit)="submit(frm)">
  <div class="card">
    <div class="card-header">
      <h4 *ngIf="Item._id"><i class="fa fa-edit"></i> تعديل ألمعلومات</h4>
      <h4 *ngIf="!Item._id"><i class="fa fa-child"></i> معلومات ألطفل</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button type="submit" class="btn btn-print" title="حفظ ألمعلومات ">
          <i class="fa fa-save fa-lg"></i>
        </button>
        <button
          class="btn btn-print"
          title="رجوع ألى ألقائمه"
          (click)="viewChange.emit('list')">
          <i class="fa fa-share fa-lg"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <label for="childname_name" class="form-label">ألاسم ألكامل :</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="childname_name"
              id="childname_name"
              [(ngModel)]="Item.name"
              required
              #childName="ngModel"
              minlength="3"
              lang="ar"
              [ngClass]="{
                'is-invalid': childName.errors && frm.submitted
              }" />

            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-child" aria-hidden="true"></i>
              </span>
            </div>
            <div *ngIf="childName.invalid" class="invalid-feedback">
              <div *ngIf="childName.errors.required !== null">
                اسم ألطفل مطلوب
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.birthDate"
            [name]="'child_birthdate{{i}}'"
            [label]="'تاريخ الميلاد'"
            (dateChange)="Item.birthDate = $event">
          </app-datepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <label class=" ">ألجنس : </label>
          <div class="d-flex border pr-3 pt-1 border-radius-25 bg-white">
            <div class="icheck-greensea ml-3 w-50">
              <input
                class="box"
                type="radio"
                name="gender"
                (change)="onGenderChange()"
                [(ngModel)]="Item.gender"
                value="ذكر"
                id="gendermale" />
              <label for="gendermale"> ذكر </label>
            </div>
            <div class="icheck-greensea ml-3 w-50">
              <input
                class="box"
                type="radio"
                name="gender"
                (change)="onGenderChange()"
                [(ngModel)]="Item.gender"
                value="أنثى"
                id="genderfemale" />
              <label for="genderfemale"> أنثى </label>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <label for="childMaritialStatus">ألحالة ألزوجية :</label>
          <select
            #mySelect
            id="childMaritialStatus"
            class="custom-select form-control"
            [(ngModel)]="Item.maritialStatus"
            name="childMAritialStatus"
            [value]="Item.maritialStatus"
            required>
            <option
              *ngFor="let item of MaritialStatuses; let i = index"
              [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-files
            [Files]="Item.Files"
            [uploadedFiles]="Item.files"
            (fileDelete)="removeFile($event)"
            [header]="'ألوثائق ألخاصة بالطفل'">
          </app-files>
        </div>
      </div>
    </div>
  </div>
</form>
