<div class="bg-header cairo topheader">
  <header class="border">
    <div class="row px-0 mx-0 py-2">
      <div class="col-md-4 d-none d-md-block">
        <img src="assets/img/0.png" class="logo-university mx-3" />
      </div>
      <div class="col-md-4 mt-3 text-center">
        <h2 class="cairo border-bottom border-dark pb-3 font-weight-bold">
          {{ University.Name }}
        </h2>
        <h2 class="cairo font-weight-bold">نظام أدارة ألموارد ألبشرية</h2>
      </div>
      <div class="col-md-4 d-none d-md-block">
        <img
          src="assets/img/1.png"
          class="logo-ministry mx-3 mb-2 float-left" />
      </div>
    </div>

    <nav class="navbar navbar-expand-md border-top py-0 pr-0 pl-4 cairo">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="تبديل التنقل">
          <span class="fa fa-list text-success"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul
            class="navbar-nav py-1"
            *ngIf="auth.isLoggedIn() && this.currentRoute === mainRoutes[0]">
            <li class="nav-item">
              <a class="nav-link" routerLink="/hr-admin"
                ><i class="fa fa-users-cog pl-2"></i> ألموارد ألبشرية</a
              >
            </li>
          </ul>
          <ul
            class="navbar-nav py-1"
            *ngIf="auth.isLoggedIn() && this.currentRoute === mainRoutes[1]">
            <li class="nav-item">
              <a class="nav-link" routerLink="/systemmanager"
                ><i class="fas fa-laptop pl-2"></i> أدارة ألنظام</a
              >
            </li>
          </ul>

          <ul class="nav navbar-nav mr-auto ml-0 float-left">
            <li class="nav-item" *ngIf="!auth.isLoggedIn()">
              <a class="nav-link text-dark" routerLink="/login">
                <i class="fas fa-sign-in-alt px-1 fa-rotate-180 py-0"></i>
                تسجيل الدخول
              </a>
            </li>

            <li class="nav-item" *ngIf="auth.isLoggedIn()">
              <div class="dropdown">
                <a
                  class="nav-item user-name"
                  role="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <img
                    [src]="this.auth.getUserPhoto()"
                    class="user-photo rounded-circle img-thumbnail mx-3" />
                  {{ this.auth.getFullname() }}
                </a>

                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li
                    *ngFor="let role of auth.getRoles()"
                    class="dropdown-item"
                    (click)="navigateTo(role.roleId.link)">
                    <i
                      class="fa fa-users-cog text-secondary fa-lg pr-0 pl-2"></i>
                    {{ role.roleId.name }}
                  </li>

                  <div class="dropdown-divider"></div>
                  <li class="dropdown-item" (click)="auth.logout()">
                    <i
                      class="fa fa-sign-out-alt fa-lg pl-2 pr-0 text-secondary"></i>
                    تسجيل ألخروج
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</div>
