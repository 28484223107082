import { Component, EventEmitter, Input, Output } from '@angular/core';

const editorOptions = {
  plugins:
    'advlist autolink lists link image charmap  preview anchor insertdatetime media table paste  wordcount',
  init: {
    height: 300,

    menubar: false,
    language: 'ar',
    directionality: 'rtl' as 'rtl' | 'ltr',

    content_style:
      'body {font-size:20px;font-family:Arial,sans-serif; color:#7b8186 !important;}',

    toolbar:
      'undo redo | formatselect |fontselect | bold italic backcolor| \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat |image'
  }
};

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent {
  @Input() input: string;
  @Output() inputChange = new EventEmitter<string>();
  options = editorOptions;
}
