import { Injectable } from '@angular/core';
import { FamilyInfo } from 'src/app/models/hr/employee/familyinfo';
import { BasicinfoService } from 'src/app/services/employee/basicinfo.service';
import { FamilyInfoService } from 'src/app/services/employee/family-info.service';

@Injectable({
  providedIn: 'root'
})
export class FamilyInfoUtilService {
  EmployeeFamilyInfo: FamilyInfo = new FamilyInfo();
  maleMaritialStatuses: string[] = ['أعزب', 'متزوج', 'مطلق', 'أرمل'];
  femaleMaritialStatuses: string[] = ['باكر', 'متزوجة', 'مطلقة', 'أرملة'];
  MaritialStatuses: string[] = this.maleMaritialStatuses;
  userId: string = null;
  isMale = true;
  constructor(
    public familyinfoService: FamilyInfoService,
    private basicInfoService: BasicinfoService
  ) {}

  getBasicInfo() {
    this.basicInfoService.findOne(this.userId).subscribe(response => {
      this.getFamilyInfo();
      if (response) {
        this.isMale = response.gender == 'ذكر' ? true : false;
        this.MaritialStatuses = this.isMale
          ? this.maleMaritialStatuses
          : this.femaleMaritialStatuses;
      }
    });
  }
  getFamilyInfo() {
    this.familyinfoService.findOne(this.userId).subscribe(response => {
      this.EmployeeFamilyInfo = new FamilyInfo();
      if (response != null) {
        this.EmployeeFamilyInfo = Object.assign(
          this.EmployeeFamilyInfo,
          response
        );
      } else {
        this.EmployeeFamilyInfo.maritialStatus = this.isMale ? 'أعزب' : 'باكر';
      }
    });
  }

  get showChildern(): boolean {
    return (
      this.EmployeeFamilyInfo._id != null &&
      (this.EmployeeFamilyInfo.maritialStatus != this.MaritialStatuses[0] ||
        this.EmployeeFamilyInfo.children.length != 0)
    );
  }
}
