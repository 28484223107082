<div>
  <div class="card mt-5">
    <div class="card-header">
      <h4><i class="fa fa-school"></i> {{ title }}</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button
          class="btn btn-print"
          (click)="newOrEdit('new')"
          title="أضافة مكان عمل جديد  ">
          <i class="fa fa-plus-circle fa-2x"></i>
        </button>
      </div>
    </div>
    <div class="card-block px-0">
      <table
        id="timeoff"
        datatable
        [dtOptions]="this.dt.dtOptions"
        [dtTrigger]="this.dt.dtTrigger"
        class="table w-100 border-bottom table-striped">
        <thead>
          <tr class="border-bottom">
            <th class="d-none d-md-table-cell">#</th>
            <th class="d-sm-flex d-md-table-cell">تاريخ ألمباشره</th>
            <th class="d-none d-md-table-cell">تاريخ ألأنفكاك</th>
            <th class="w-25">ألجهة ألمنتسب أليها</th>
            <th class="text-center d-print-none"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i = index">
            <th class="d-none d-md-table-cell border-left">{{ i + 1 }}</th>
            <td class="d-block d-md-table-cell pr-3">
              {{ item.fromDate | formatDate | arabicDate }}
            </td>
            <td class="d-block d-md-table-cell pr-3">
              {{ item.toDate | formatDate | arabicDate }}
            </td>
            <td class="w-50 border-right">
              <div class="my-2 px-1" style="min-height: 100px !important">
                <app-workplace-tree-view
                  [treeData]="getWorkplaceTree(item)"></app-workplace-tree-view>
              </div>
              <div class="d-md-flex justify-content-md-between py-2">
                <span
                  class="badge badge-light border border-success badge-pill p-2 d-block"
                  [ngClass]="{
                    'border-danger': item.employmentType !== 'دائمي'
                  }">
                  ملاك ألموظف : {{ item.employmentType }}
                </span>
                <span
                  class="badge badge-light badge-pill border border-success p-2 d-block mt-1 mt-md-0"
                  [ngClass]="{
                    'border-danger': item.movementType !== 'دائمي'
                  }">
                  نوع ألأنتساب : {{ item.movementType }}
                </span>
              </div>
            </td>
            <td class="border-right d-print-none">
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  <i class="fa fa-ellipsis-v text-secondary"></i>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('edit')">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل مكان ألعمل
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="this.SelectedItem = item; this.removeWorkplace()">
                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2"></i>
                    حذف مكان ألعمل
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
