import { Files } from 'src/app/classes/Files';

export class AwardModel {
  _id: string = null;
  type = 'شكر';
  issuer = 'رئيس جامعة';
  issueDate: Date = new Date();
  documentNumber = '';
  affectMonths = 0;
  issuerOffice = '';

  notes = '';

  Files: Files = new Files();
  files: any[] = [];

  toFormData(): FormData {
    const data: FormData = new FormData();
    if (this._id) {
      data.append('_id', this._id);
    }
    data.append('type', this.type);
    data.append('issuer', this.issuer);
    data.append('issuerOffice', this.issuerOffice);
    data.append('issueDate', this.issueDate?.toString());
    data.append('affectMonths', this.affectMonths.toString());
    data.append('documentNumber', this.documentNumber);
    data.append('notes', this.notes);
    this.Files.appendFilesToForm(data);
    return data;
  }
}

export class PunishmentModel {
  _id: string = null;
  type = 'عقوبه';
  punishmentType = 'لفت نظر';
  issuer = 'رئيس جامعة';
  issueDate: Date = new Date();
  affectMonths = 0;
  issuerOffice = '';
  documentNumber = '';
  notes = '';

  Files: Files = new Files();
  files: any[] = [];

  toFormData(): FormData {
    const data: FormData = new FormData();
    if (this._id) {
      data.append('_id', this._id);
    }
    data.append('type', this.type);
    data.append('punishmentType', this.punishmentType);
    data.append('issuer', this.issuer);
    data.append('issuerOffice', this.issuerOffice);
    data.append('issueDate', this.issueDate?.toString());
    data.append('affectMonths', this.affectMonths.toString());
    data.append('notes', this.notes);
    data.append('documentNumber', this.documentNumber);
    this.Files.appendFilesToForm(data);
    return data;
  }
}
