<div class="wrapper">
  <div class="contents">
    <div id="main-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="sidebar left-sidebar border-right" id="main-sidebar">
    <ul class="list-unstyled mb-10">
      <li class="border-top-0 text-nowrap">
        <a routerLink="roles-management" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> أدارة ألأدوار وألصلاحيات
        </a>
      </li>
      <li class="text-nowrap">
        <a routerLink="accounts-management" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> أدارة حسابات ألمستخدمين
        </a>
      </li>
    </ul>
  </div>
</div>
