<div class="container">
  <app-page-header-info
    title2="أدارة حسابات ألمستخدمين"
    id="main-header"></app-page-header-info>
  <div class="content-container mx-md-4">
    <div class="my-7" [ngClass]="{ 'd-none': view !== 'list' }">
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="treeData.length">
            <app-workplace-collapse-view
              [treeData]="treeData"
              [header]="header">
            </app-workplace-collapse-view>
          </div>
        </div>
      </div>

      <div class="card mt-6">
        <div class="card-header">
          <h4 class="pl-3">
            <i class="fa fa-users"></i> موظفين
            {{ SelectedWorkplace ? SelectedWorkplace.name : '' }}
          </h4>

          <div
            class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button
              *ngIf="SelectedWorkplace"
              class="btn btn-print"
              (click)="newOrEdit('new')"
              title="أضافة موظف">
              <i class="fa fa-user-plus fa-lg"></i>
            </button>
            <button
              class="btn btn-print"
              (click)="openDialog()"
              title="أختيار ألمؤسسه">
              <i class="fa fa-university"></i>
            </button>
          </div>
        </div>
        <div class="card-block px-0">
          <table
            id="timeoff"
            datatable
            [dtOptions]="this.dt.dtOptions"
            [dtTrigger]="this.dt.dtTrigger"
            class="table table-bordered table-striped border-0">
            <thead>
              <tr>
                <th>#</th>
                <th>ألاسم</th>
                <th>ألكلية أو ألمركز</th>
                <th class="text-center">
                  <i class="fa fa-ellipsis-v"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Items; let i = index">
                <th>{{ i + 1 }}</th>
                <td>{{ item.fullname }}</td>
                <td>{{ item.workplace.name }}</td>
                <th class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary"></i>
                    </button>
                    <ul
                      class="dropdown-menu bg-creamy"
                      aria-labelledby="dropdownMenuButton">
                      <li
                        class="dropdown-item"
                        (click)="newOrEdit('edit'); SelectedUser = item">
                        <i class="fa fa-user-edit pl-2 pr-0 text-secondary"></i>
                        تعديل بيانات ألمستخدم
                      </li>
                      <li
                        class="dropdown-item"
                        (click)="newOrEdit('password'); SelectedUser = item">
                        <i class="fa fa-key px-1 pl-2 text-secondary"></i>
                        تغيير كلمة ألمرور
                      </li>
                      <li
                        class="dropdown-item"
                        (click)="newOrEdit('user-roles'); SelectedUser = item">
                        <i class="fa fa-user-lock px-1 pl-2 text-secondary"></i>
                        أدارة صلاحيات ألمستخدم
                      </li>
                    </ul>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="my-7">
      <app-user-form
        *ngIf="view === 'new'"
        [entityId]="SelectedWorkplaceId"
        (viewChange)="this.findEmployees()">
      </app-user-form>
      <app-user-form
        *ngIf="view === 'edit' || view === 'password'"
        [entityId]="SelectedWorkplaceId"
        [userId]="SelectedUser._id"
        [action]="this.view"
        (viewChange)="this.findEmployees()">
      </app-user-form>
    </div>
  </div>
</div>

<app-user-roles
  *ngIf="view === 'user-roles'"
  [userId]="SelectedUser._id"
  (viewChange)="this.findEmployees()">
</app-user-roles>
