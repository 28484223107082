<div class="card">
  <div class="card-header">
    <h4><i class="fa fa-edit px-2"></i>{{ 'تعديل ' + Item.name }}</h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألمعلومات ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <label for="entityname">أسم ألتشكيل :</label>
        <input
          type="text"
          class="form-control"
          id="entityname"
          name="entityname"
          [(ngModel)]="Item.name" />
      </div>
      <div class="col-md-6" dir="ltr">
        <label for="enName" class="float-left">English Name :</label>
        <input
          type="text"
          class="form-control"
          id="enName"
          name="enName"
          [(ngModel)]="Item.enName" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label for="entityType" class="form-label">نوع ألتشكيل :</label>
        <select
          #mySelect
          *ngIf="isUnitOrDivision()"
          class="custom-select form-control"
          [(ngModel)]="Item.type"
          name="entityType"
          id="entityType"
          required>
          <option
            *ngFor="let item of changeableEntities; let i = index"
            [value]="item">
            {{ item }}
          </option>
        </select>
        <input
          type="text"
          *ngIf="!isUnitOrDivision()"
          disabled
          class="form-control"
          id="entityType"
          name="entityType"
          [(ngModel)]="Item.type" />
      </div>
      <div class="col-md-6">
        <label for="manager">ألمدير ألاداري :</label>
        <input
          type="text"
          class="form-control"
          disabled
          id="manager"
          name="manager"
          [(ngModel)]="manager.fullname" />
      </div>
      <div class="col-md-3" dir="ltr" *ngIf="isCollege()">
        <label for="code" class="float-left"> code :</label>
        <input
          type="text"
          class="form-control"
          id="code"
          name="code"
          [(ngModel)]="Item.code" />
      </div>
    </div>
  </div>
</div>
