<div *ngIf="view === 'tree'">
  <div class="card">
    <div class="card-header">
      <h4>
        <i class="fa fa-university"></i>
        ألهيكل ألتنظيمي : {{ title }}
      </h4>
      <div class="toolbar d-md-flex d-inline-flex w-30 justify-content-between">
        <button
          (click)="deleteAll()"
          class="btn btn-print d-none"
          title="حفظ ألمعلومات ">
          <i class="fa fa-trash fa-lg"></i>
        </button>
        <button mat-icon-button class="mt-0" (click)="toggleTree()">
          <mat-icon
            >{{ this.isExpanded ? 'chevron_left' : 'expand_more' }}
          </mat-icon>
        </button>
      </div>
    </div>
    <div class="card-body pr-0">
      <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        dir="rtl"
        class="bg-creamy text-secondary">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <div class="d-flex justify-content-between w-100">
            <div class="middle">
              <button mat-icon-button disabled>
                <mat-icon>remove</mat-icon>
              </button>
              <span>{{ node.name }}</span>
              <span class="badge badge-pill badge-warning mr-4">{{
                node.type
              }}</span>
            </div>
            <div class="d-inline d-print-none">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                *ngIf="!isUnit(node)">
                <mat-icon>add_circle</mat-icon>
              </button>
              <div class="dropdown d-inline">
                <button
                  mat-icon-button
                  id="dropdownMenuButton"
                  data-toggle="dropdown">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li class="dropdown-item" (click)="changeView('users', node)">
                    <i class="fa fa-users fa-lg pl-1 text-secondary"></i>
                    موظفين {{ node.name }}
                  </li>
                  <li class="dropdown-item" (click)="changeView('edit', node)">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل {{ node.name }}
                  </li>
                  <li class="dropdown-item" (click)="deleteNode(node)">
                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2"></i>
                    حذف {{ node.name }}
                  </li>
                </ul>
              </div>

              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let item of _database.getTitle(node.type).children"
                  (click)="addNewItem(node, item)">
                  <mat-icon class="mx-2">add</mat-icon>
                  <span>{{ item.name }}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasNoContent"
          matTreeNodePadding
          class="mt-2 w-100">
          <div class="row mb-1 px-0 w-75">
            <div class="col-md-9">
              <div class="input-group mr-3">
                <input
                  type="text"
                  id="item"
                  name="item"
                  class="form-control"
                  [placeholder]="_database.getTitle(node.type).title"
                  #itemValue />
                <div class="input-group-prepend">
                  <span class="input-group-text font-bold">
                    <i class="fa fa-university"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex justify-content-between">
              <button
                class="btn text-secondary hover-green"
                (click)="saveNode(node, itemValue.value)"
                [title]="'حفظ' + itemValue.value">
                <i class="fa fa-save fa-lg"></i>
              </button>
              <button
                class="btn text-secondary hover-danger"
                title="حذف "
                (click)="this.SelectedItem = node; confirmDelete()">
                <i class="fa fa-trash fa-lg"></i>
              </button>
            </div>
          </div>
        </mat-tree-node>

        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding>
          <div class="d-flex d-inline-flex justify-content-between w-100">
            <div class="middle">
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(node)
                      ? 'expand_more'
                      : 'chevron_right'
                  }}
                </mat-icon>
              </button>
              <span class="nowrap hover-green pr-2">
                {{ node.name }}
              </span>
              <span
                class="badge badge-pill badge-secondary mr-4 d-none d-sm-inline-block"
                >{{ node.type }}</span
              >
            </div>

            <div class="d-inline d-inline-flex d-print-none">
              <button
                mat-icon-button
                *ngIf="!isUnit(node)"
                [matMenuTriggerFor]="menu"
                title="اضافة تشكيل ألى  {{ node.name }}">
                <mat-icon>add_circle</mat-icon>
              </button>
              <div class="dropdown d-inline">
                <button
                  mat-icon-button
                  id="dropdownMenuButton"
                  data-toggle="dropdown">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li class="dropdown-item" (click)="changeView('users', node)">
                    <i class="fa fa-users fa-lg pl-1 text-secondary"></i>
                    موظفين {{ node.name }}
                  </li>
                  <li class="dropdown-item" (click)="changeView('edit', node)">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل {{ node.name }}
                  </li>
                  <li class="dropdown-item" (click)="deleteNode(node)">
                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2"></i>
                    حذف {{ node.name }}
                  </li>

                  <li
                    class="dropdown-item"
                    (click)="changeView('import', node)">
                    <i
                      class="fa fa-file-download text-secondary fa-lg px-1 pl-2"></i>
                    أستيراد ألبيانات {{ node.name }}
                  </li>
                </ul>
              </div>

              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let item of _database.getTitle(node.type).children"
                  (click)="addNewItem(node, item)">
                  <mat-icon class="mx-2">add</mat-icon>
                  <span>{{ item.name }}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
</div>

<app-organization-entity-form
  *ngIf="view === 'edit'"
  [node]="this.selectedNode"
  (viewChange)="this.updateTree()">
</app-organization-entity-form>

<app-organization-users
  *ngIf="view === 'users'"
  [node]="this.selectedNode"
  (viewChange)="this.updateTree()">
</app-organization-users>

<app-data-import
  *ngIf="view === 'import'"
  [node]="this.selectedNode"
  (viewChange)="this.updateTree()">
</app-data-import>
