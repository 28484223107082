import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpLoadingService } from 'src/app/services/shared/http-loading.service';
import { WorkplaceUtilService } from 'src/app/services/util/workplace.service';
@Injectable({
  providedIn: 'root'
})
export class HrDataService {
  users: any[] = [];
  hrRole: any = null;
  roleWorkplace: string;
  selectedWorkplace: any = null;
  selectedWorkplacePath = '';
  treeData: any[] = [];
  header = '';

  constructor(
    private authenticationService: AuthenticationService,
    public workplaceService: WorkplaceUtilService,
    private loadingService: HttpLoadingService,
    public dialog: MatDialog
  ) {
    this.authenticationService.getRolesObservable().subscribe(roles => {
      const role = roles.find(r => r.roleId.category == 'ألموارد ألبشرية');
      if (role) {
        this.hrRole = role;
        this.setHeaderTitle();
      }
    });
  }

  setHeaderTitle() {
    if (this.hrRole) {
      this.loadingService.Title = this.workplaceService.getMainPath(
        this.hrRole.workplaceId
      );
    }
  }

  getWorkplaceTree() {
    this.treeData = this.workplaceService.convertToTree(
      this.workplaceService.getFullPath(this.selectedWorkplace).split('-')
    );
    this.header = this.selectedWorkplace.name;
  }
}
