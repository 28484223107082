<div class="card">
  <div class="card-header">
    <h4 *ngIf="!Item._id"><i class="fa fa-plus"></i> درجة وظيفية جديدة</h4>
    <h4 *ngIf="Item._id">
      <i class="fa fa-edit"></i> تعديل ألدرجه {{ Item.grade }}
    </h4>

    <div class="icheck-greensea">
      <input
        class="box"
        type="checkbox"
        name="gradeCompleted"
        [(ngModel)]="Item.isCompleted"
        id="gradeCompleted" />
      <label for="gradeCompleted">
        قفل ألدرجه بأخر علاوه
        <i
          class="fa px-1 text-secondary"
          [ngClass]="{
            'fa-lock': Item.isCompleted === true,
            'fa-lock-open': Item.isCompleted === false
          }"></i>
      </label>
    </div>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألاجازة ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body px-0">
    <form class="px-4">
      <div class="row">
        <div class="col-md-4">
          <app-selectpicker
            [values]="gradesList"
            [selected]="Item.grade"
            [label]="'ألدرجة ألوظيفية'"
            [inputGroupText]="{
              class: 'material-icons textt-secondary mx-1',
              text: 'stairs'
            }"
            (valueChange)="onGradeChange($event)">
          </app-selectpicker>
        </div>

        <div class="col-md-4" *ngIf="changing === false">
          <app-selectpicker
            [values]="jobTitles"
            [selected]="Item.jobTitle"
            [label]="'ألعنوان ألوظيفي'"
            [leftLabel]="Item.employeeType"
            [inputGroupText]="{
              class: 'fa fa-user-tie px-2',
              text: ''
            }"
            (valueChange)="onJobTitleChange($event)">
          </app-selectpicker>
        </div>

        <div class="col-md-4">
          <label for="category" class="form-label">
            صنف ألعنوان ألوظيفي :</label
          >
          <div class="d-md-flex border pt-1 px-3 border-radius-25 bg-white">
            <div
              class="icheck-greensea w-50"
              *ngFor="let type of employeeCategories; let i = index">
              <input
                class="box"
                type="radio"
                name="paymentType"
                [(ngModel)]="Item.category"
                [value]="type"
                id="paymentType_{{ i }}" />
              <label for="paymentType_{{ i }}">
                {{ type }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.deserveDate"
            [name]="'fromDate'"
            [label]="'تاريخ ألاستحاق  '"
            (dateChange)="Item.deserveDate = $event">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.assignDate"
            [name]="'documentDate'"
            [label]="'تاريخ ألأمر ألأداري '"
            (dateChange)="Item.assignDate = $event">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <label for="documentNumber" class="form-label">
            رقم ألأمر ألأداري :</label
          >
          <div class="input-group">
            <input
              type="text"
              id="documentNumber"
              name="documentNumber"
              #Booknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="Item.documentNumber" />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 offset-md-8">
          <label for="county" class="form-label">سبب ألترفيع :</label>
          <select
            class="custom-select shadow-none"
            id="reason"
            name="reason"
            [(ngModel)]="Item.upgradeReason"
            required>
            <option *ngFor="let item of reasons; let i = index" [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
            <mat-label class="w-50 px-3">ألملاحضات </mat-label>
            <textarea
              matInput
              placeholder=""
              name="notes"
              rows="3"
              class="py-0"
              [(ngModel)]="Item.notes"></textarea>
            <mat-icon matSuffix class="text-secondary">comment</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-files
            [Files]="Item.Files"
            [uploadedFiles]="Item.files"
            (fileDelete)="removeGradeFile(Item._id, $event)"
            [header]="'ألأوامر ألأدارية ألخاصة بالترفيع'">
          </app-files>
        </div>
      </div>
    </form>
  </div>
</div>
