<div *ngIf="Item === null">
  <div class="card">
    <div class="card-header">
      <h4 class="pr-1">
        <i class="fa fa-certificate px-2"></i> أضافة شهادة جديده
      </h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button
          class="btn btn-print"
          title="رجوع ألى ألقائمه"
          (click)="viewChange.emit('list')">
          <i class="fa fa-share fa-lg"></i>
        </button>
      </div>
    </div>
    <div
      class="card-body bg-white"
      cdkDropList
      style="min-height: 400px; background-image: none"
      id="container"
      (cdkDropListDropped)="dropDegree($event)">
      <div class="middle h-100"></div>
    </div>
  </div>
</div>
<div class="card" [id]="Item.type" [ngSwitch]="Item.type" *ngIf="Item !== null">
  <div class="card-header">
    <h4 class="pr-1">
      <i class="fa fa-certificate px-2"></i> {{ 'شهادة ' + Item.type }}
    </h4>

    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألشهادة ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <app-degree-nodegree
    *ngSwitchCase="'أمي'"
    [item]="Item"></app-degree-nodegree>
  <app-degree-nodegree
    *ngSwitchCase="'يقرأ ويكتب'"
    [item]="Item"></app-degree-nodegree>
  <app-degree-elementry
    *ngSwitchCase="'أبتدائيه'"
    [item]="Item"></app-degree-elementry>
  <app-degree-intermediate
    *ngSwitchCase="'متوسطه'"
    [item]="Item"></app-degree-intermediate>
  <app-degree-highschool
    *ngSwitchCase="'أعدادية'"
    [item]="Item"></app-degree-highschool>
  <app-degree-diploma
    *ngSwitchCase="'دبلوم'"
    [item]="Item"></app-degree-diploma>
  <app-degree-undergraduate
    *ngSwitchCase="'بكالوريوس'"
    [item]="Item"></app-degree-undergraduate>
  <app-degree-graduate
    *ngSwitchCase="'دبلوم عالي'"
    [item]="Item"
    [studyVacations]="studyVacations"></app-degree-graduate>
  <app-degree-graduate
    *ngSwitchCase="'ماجستير'"
    [item]="Item"
    [studyVacations]="studyVacations"></app-degree-graduate>
  <app-degree-graduate
    *ngSwitchCase="'دكتوراه'"
    [item]="Item"
    [studyVacations]="studyVacations"></app-degree-graduate>
</div>
