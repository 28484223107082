// src/app/interceptors/spinner.interceptor.ts
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone({ reportProgress: true });
    this.spinnerService.show();
    return next.handle(modifiedReq).pipe(
      tap(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(
            (100 * event.loaded) / (event.total ?? 1)
          );
          this.spinnerService.setUploadProgress(progress);
        } else if (event.type === HttpEventType.DownloadProgress) {
          const progress = Math.round(
            (100 * event.loaded) / (event.total ?? 1)
          );
          this.spinnerService.setDownloadProgress(progress);
        }
      }),
      finalize(() => {
        this.spinnerService.hide();
        this.spinnerService.setUploadProgress(0);
        this.spinnerService.setDownloadProgress(0);
      })
    );
  }
}
