<div class="row page-header-info d-flex justify-content-between">
  <div class="d-print-none">
    <button id="right-sidebar-collapse" class="btn right-sidebar-collapse">
      <i class="fa fa-align-left text-secondary"></i>
    </button>
  </div>

  <div class="d-flex justify-content-between info-contents">
    <div class="py-0 px-2">
      <h4 class="text-secondary">
        <i [ngClass]="'fa d-print-none ' + icon + ' fa-lg pl-3'"></i
        >{{ loadingService.Title }}
      </h4>
    </div>
    <div class="px-2 d-none d-md-inline-block">
      <h4 class="text-secondary">{{ title2 }}</h4>
    </div>
  </div>

  <div class="d-print-none">
    <button id="left-sidebar-collapse" class="btn left-sidebar-collapse">
      <i class="fa fa-align-right text-secondary"></i>
    </button>
  </div>
</div>
