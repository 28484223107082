<div class="card mt-6">
  <div class="card-header">
    <h4 *ngIf="!stage._id"><i class="fas fa-signal pl-3"></i>علاوة جديدة</h4>
    <h4 *ngIf="stage._id">
      <i class="fas fa-signal pl-3"></i>تعديل ألمرحلة ( {{ stage.stage }} )
    </h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="أضافة مرحله">
        <i class="fa fa-save fa-2x"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <label for="stage" class="form-label">ألمرحلة :</label>
        <select
          class="custom-select shadow-none"
          id="stage"
          name="stage"
          [(ngModel)]="stage.stage"
          required>
          <option
            *ngFor="let item of StagesList; let i = index"
            [value]="item + 1">
            {{ i + 1 }}
          </option>
        </select>
      </div>

      <div class="col-md-4 offset-md-1">
        <div class="icheck-greensea mt-6 float-left">
          <input
            class="box"
            type="checkbox"
            name="stage_complementry"
            [(ngModel)]="stage.isComplementry"
            id="stage_complementry" />
          <label for="stage_complementry"> تعويضيه ؟ </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <app-datepicker
          [input]="stage.deserveDate"
          [name]="'deserveDate'"
          [label]="' تاريخ ألعلاوه'"
          (dateChange)="stage.deserveDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <app-datepicker
          [input]="stage.assignDate"
          [name]="'assignDate'"
          [label]="'تاريخ ألأمر ألاداري '"
          (dateChange)="stage.assignDate = $event">
        </app-datepicker>
      </div>

      <div class="col-md-4">
        <label for="documentNumber" class="form-label">
          رقم ألأمر ألأداري :</label
        >
        <div class="input-group">
          <input
            type="text"
            id="documentNumber"
            name="documentNumber"
            #Booknumber="ngModel"
            class="form-control text-center"
            [(ngModel)]="stage.documentNumber" />
          <div class="input-group-prepend">
            <span class="input-group-text font-bold"> # </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-files
          [Files]="stage.Files"
          [uploadedFiles]="stage.files"
          (fileDelete)="removeStageFile(stage._id, $event)"
          [header]="'ألاوامر ألادارية ألخاصة  بالعلاوه  '">
        </app-files>
      </div>
    </div>
  </div>
</div>
