import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateUtil } from 'src/app/classes/Utils';
import { EmployeeSummariesService } from 'src/app/services/employee/employee-summaries.service';
import { WorkplaceUtilService } from 'src/app/services/util/workplace.service';

@Component({
  selector: 'app-service-summary',
  templateUrl: 'service.summary.html'
})
export class EmployeeServiceSummaryComponent implements OnInit {
  @Input() userId: string;
  currentDate: string = new Date().toString();
  public info: any = null;
  awards: any = null;
  punishments: any = null;
  absenseVacations: any = null;
  fiveYearsVacations: any = null;
  unpaidVacations: any = null;
  longVacations: any = null;
  inMohServiceDuration: any = DateUtil.findYearMonthsDaysObject(0);
  outOfMohServiceDuration: any = DateUtil.findYearMonthsDaysObject(0);
  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private employeeSummariesService: EmployeeSummariesService,
    private workplaceUtilSevice: WorkplaceUtilService
  ) {}
  ngOnInit() {
    this.getSummary();
  }

  getSummary() {
    this.employeeSummariesService
      .findGeneralSummary(this.userId)
      .subscribe(response => {
        this.info = response[0];
        if (this.info.degree.issuer)
          this.info.degree.issuer = this.workplaceUtilSevice.convertToTree(
            this.info.degree.issuer
          );
        if (this.info.workplace) {
          const workplaces = this.workplaceUtilSevice.getBranchesList(
            this.info.workplace
          );
          this.info.workplace =
            this.workplaceUtilSevice.convertToTree(workplaces);
        }

        this.awards = this.getAwardsPunishments('شكر');
        this.punishments = this.getAwardsPunishments('عقوبه');
        this.absenseVacations = this.getVacationByType('غياب');
        this.unpaidVacations = this.getVacationByType('بدون راتب');
        this.fiveYearsVacations = this.getVacationByType('خمس سنوات');
        this.longVacations = this.getVacationByType('أعتياديه طويله');
        this.inMohServiceDuration = this.findInOutMohServiceDuration(true);
        this.outOfMohServiceDuration = this.findInOutMohServiceDuration(false);

        this.getContractService();

        if (this.info.firstPlace) {
          this.info.firstPlace = this.workplaceUtilSevice.convertToTree(
            this.info.firstPlace.fullname.split('-')
          );
        }
      });
  }
  getAwardsPunishments(type: string) {
    return this.info.awardspunishments.find(item => item._id == type);
  }

  findInOutMohServiceDuration(type: boolean) {
    const minstryDuration = this.info.employeeWorkplaces.ministryGroup.find(
      item => item._id == type
    );
    if (minstryDuration) {
      return DateUtil.findYearMonthsDaysObject(minstryDuration.duration);
    }
    return DateUtil.findYearMonthsDaysObject(0);
  }

  getVacationByType(type: string) {
    const vacation = this.info.vacations.typeGroup.find(
      item => item._id == type
    );
    if (vacation) {
      vacation.durationObject = DateUtil.findYearMonthsDaysObject(
        vacation.duration
      );
    }
    return vacation;
  }
  contractServiceDuration: any = DateUtil.findYearMonthsDaysObject(0);
  getContractService() {
    const contractDuration = this.info.employeeWorkplaces.employmentType.find(
      item => item._id == 'عقد'
    );
    if (contractDuration) {
      this.contractServiceDuration = DateUtil.findYearMonthsDaysObject(
        contractDuration.duration
      );
    }
  }

  print() {
    window.print();
  }
}
