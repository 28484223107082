import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UniversityService } from 'src/app/services/data-persistence/university-info';
import { RoutingInfoService } from 'src/app/services/shared/routing-info.service';

import { AuthenticationService } from '../../../services/authentication.service';
@Component({
  selector: 'app-topheader',
  templateUrl: './topheader.component.html',
  styleUrls: ['./topheader.component.scss']
})
export class TopheaderComponent implements OnInit {
  currentRoute: string;

  mainRoutes: string[] = ['hr-admin', 'systemmanager'];
  constructor(
    public auth: AuthenticationService,
    public router: Router,
    private routingService: RoutingInfoService,
    public University: UniversityService
  ) {}

  ngOnInit(): void {
    this.routingService.getRouteObservable().subscribe(route => {
      if (this.mainRoutes.indexOf(route) != -1) this.currentRoute = route;
    });
  }
  navigateTo(route: string) {
    this.router.navigate(['/' + route]);
  }
}
