<div class="card">
  <div class="card-header">
    <h4 *ngIf="!Item._id"><i class="fa fa-plus"></i> أضافة عقوبه</h4>
    <h4 *ngIf="Item._id"><i class="fa fa-edit"></i> تعديل ألعقوبه</h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألكتاب ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <form #frm="ngForm" class="needs-validation" (ngSubmit)="submit()">
      <div class="row">
        <div class="col-md-8">
          <app-selectpicker
            [values]="leaders"
            [selected]="Item.issuer"
            [label]="'رتبة ألجهة ألمصدره '"
            [inputGroupText]="{
              class: 'fa fa-user-tie px-2',
              text: ''
            }"
            (valueChange)="Item.issuer = $event">
          </app-selectpicker>
        </div>

        <div class="col-md-4">
          <label for="punishmentType" class="form-label"> نوع ألعقوبة :</label>
          <div class="input-group">
            <select
              class="custom-select shadow-none"
              id="punishmentType"
              name="punishmentType"
              [(ngModel)]="Item.punishmentType"
              required>
              <option
                *ngFor="let item of punishmentTypes; let i = index"
                [value]="item">
                {{ item }}
              </option>
            </select>

            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-lock"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <label for=""> ألجهة ألمصدره للعقوبه :</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="issuerOffice"
              id="issuerOffice"
              placeholder=""
              [(ngModel)]="Item.issuerOffice"
              required
              #issurOffice="ngModel"
              minlength="6"
              lang="ar"
              [ngClass]="{
                'is-invalid': issurOffice.errors && frm.submitted
              }" />
            <div *ngIf="issurOffice.invalid" class="invalid-feedback">
              <div *ngIf="issurOffice.errors.required !== null">
                أسم ألجهة ألمانحة مطلوب
              </div>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-university fa-1x" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.issueDate"
            [name]="'issueDate'"
            [label]="'تاريخ ألكتاب  '"
            (dateChange)="Item.issueDate = $event">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <label for="documentNumber" class="form-label">
            رقم ألأمر ألأداري :</label
          >
          <div class="input-group">
            <input
              type="text"
              id="documentNumber"
              name="documentNumber"
              #Booknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="Item.documentNumber" />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <label for="stage" class="form-label">مدة تأخير ألترفيع :</label>
          <div class="input-group">
            <select
              class="custom-select shadow-none"
              id="affectMonthse"
              name="affectMonths"
              [(ngModel)]="Item.affectMonths"
              required>
              <option
                *ngFor="let item of monthsList; let i = index"
                [value]="item">
                {{ item }}
              </option>
            </select>

            <div class="input-group-prepend">
              <span class="input-group-text"> شهر </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
            <mat-label class="w-50 px-3">ألملاحضات </mat-label>
            <textarea
              matInput
              placeholder=""
              name="notes"
              rows="3"
              class="py-0"
              [(ngModel)]="Item.notes"></textarea>
            <mat-icon matSuffix class="text-secondary">comment</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-files
            [Files]="Item.Files"
            [uploadedFiles]="Item.files"
            (fileDelete)="removeFile(Item._id, $event)"
            [header]="'ألأمر ألاداري ألخاص بكتاب ألعقوبه'">
          </app-files>
        </div>
      </div>
    </form>
  </div>
</div>
