<div [ngClass]="{ 'd-none': view !== 'list' }">
  <div class="card mt-6">
    <div class="card-header">
      <h4><i class="fas fa-signal pl-3"></i>ألدرجات ألوظيفية</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button
          class="btn btn-print"
          (click)="insertMany()"
          title="أضافة ألدرجات ألوظيفيه">
          <i class="fa fa-plus-circle fa-2x"></i>
        </button>

        <button
          class="btn btn-print d-none"
          (click)="deleteMany()"
          title="حذف ألكل">
          <i class="fa fa-trash fa-2x"></i>
        </button>
      </div>
    </div>
    <div class="card-block px-0">
      <table
        id="upgradesummary"
        datatable
        [dtOptions]="this.dt.dtOptions"
        [dtTrigger]="this.dt.dtTrigger"
        class="table table-bordered table-striped border-0 my-0">
        <thead>
          <tr>
            <th>#</th>
            <th>ألدرجة ألوظيفية</th>
            <th>ألراتب ألأسمي</th>
            <th>ألشهادة ألدنيا</th>
            <th>عدد ألعناوين ألوظيفية</th>

            <th class="text-center" style="width: 15%">
              <i class="fa fa-ellipsis-v"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Items; let i = index">
            <th>{{ i + 1 }}</th>
            <th class="pr-3 text-secondary">ألدرجة {{ item.grade }}</th>
            <td class="pr-3">{{ item.salary }}</td>
            <td class="pr-3">{{ item.minimumDegree }}</td>
            <td class="text-center">{{ item.jobTitles.length }}</td>
            <th class="text-center">
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  <i class="fa fa-ellipsis-v text-secondary"></i>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('edit')">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل ألدرجة ألوظيفية
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('stages')">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل مراحل ألدرجة {{ item.grade }}
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('jobTitle')">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل ألعناوين ألوظيفية
                  </li>
                </ul>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-system-grades-form
  *ngIf="view === 'edit'"
  [Item]="SelectedItem"
  (viewChange)="this.findAll()"></app-system-grades-form>
<app-system-jobtitles-form
  *ngIf="view === 'jobTitle'"
  [Item]="SelectedItem"
  (viewChange)="this.findAll()"></app-system-jobtitles-form>
<app-system-stages-form
  *ngIf="view === 'stages'"
  [Item]="SelectedItem"
  (viewChange)="this.findAll()"></app-system-stages-form>
