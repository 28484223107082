import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { LocationService } from '../location.service';
@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html'
})
export class LocationInfoComponent implements OnInit, AfterViewInit {
  @Input() Item: any;

  public countries: string[] = [];
  public provinces: string[] = [];
  cities: string[] = [];

  constructor(
    public locationService: LocationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.countries = this.locationService.getCountries();
    this.provinces = this.locationService.getProvinces();
    if (this.isContryIraq) {
      this.getCities(this.Item.province);
    }
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  isContryIraq(): boolean {
    return this.Item.country == 'ألعراق';
  }

  getCities(province: string) {
    this.cities = this.locationService.getCities(province);
  }
  onProvinceChange(province: string) {
    this.getCities(province);
    this.Item.county = this.cities[0];
    this.Item.province = province;
  }

  onCountryChange(value: string) {
    this.Item.country = value;
    this.Item.province = '';
    this.Item.county = '';
    if (this.isContryIraq()) {
      this.Item.province = this.provinces[0];
      this.onProvinceChange(this.Item.province);
    }
  }
}
