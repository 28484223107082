import { ImageDimension, ImageFile } from '../../../classes/Files';
export class Documents {
  documents: Array<any> = [];
  addDocument(item: any) {
    this.documents.push(item);
  }
  removeDocument(item: any) {
    const index = this.documents.indexOf(item);
    this.documents.splice(index, 1);
  }

  isValid(): boolean {
    const length = this.documents.length;
    const validDocuments = this.documents.filter(
      item => item.isValid == true
    ).length;
    const result = length != 0 && validDocuments === length;
    return result;
  }
}

export class Document {
  type = '';
  isValid = false;
  isNew = true;
  faceOne: ImageFile = new ImageFile();
  faceTwo: ImageFile = new ImageFile();
  constructor(type: string) {
    this.type = type;
  }

  toFormData() {
    const data = new FormData();
    if (this.faceOne?.file?.name?.length) {
      data.append(
        'faceone',
        this.faceOne.file,
        encodeURIComponent(
          this.type + '- وجه' + '.' + this.getFileExtension(this.faceOne.file)
        )
      );
    }
    if (this.faceTwo.file.name.length) {
      data.append(
        'facetwo',
        this.faceTwo.file,
        encodeURIComponent(
          this.type + '- ظهر' + '.' + this.getFileExtension(this.faceTwo.file)
        )
      );
    }

    const document = JSON.parse(JSON.stringify(this));
    delete document.faceOne;
    delete document.faceTwo;
    delete document.dimensions;
    delete document.isValid;
    data.append('document', JSON.stringify(document));
    return data;
  }

  getFileExtension(file: File): string {
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }

    return fileName.substring(lastDotIndex + 1).toLowerCase();
  }
}

export class NationalId extends Document {
  dimensions: ImageDimension;
  nationalIdNumber = '';
  name = '';
  father = '';
  grandFather = '';
  sureName = '';
  mother = '';
  grandMother = '';
  gender = '';
  bloodCategory = '';
  idNumber = '';
  issuer = '';
  issueDate = '';
  expirationDate = '';
  placeOfBirth = '';
  dateOfBirth = '';
  familyIdNumber = '';

  faceone: any = null;
  facetwo: any = null;

  constructor(type: string) {
    super(type);
    this.dimensions = {
      width: '400px !important',
      height: '250px !important'
    };
  }
}
export class Passport extends Document {
  dimensions: ImageDimension;
  passportNumber = '';
  passportType = 'P';
  arabicFullName = '';
  englishFullName = '';
  country = 'IRQ';
  arabicSurname = '';
  englishSurname = '';
  arabicNationality = 'عراقي';
  englishNationality = 'IRAQI';
  arabicGender = 'ذكر';
  englishGender = 'M';
  arabicMotherName = '';
  englishMotherName = '';
  issueDate = '';
  expirationDate = '';
  dateOfBirth = '';
  arabicIssuer = '';
  englishIssuer = '';
  placeOfBirth = '';
  placeOfBirthCountry = 'IRQ';

  faceone: any = null;

  constructor(type: string) {
    super(type);
    this.dimensions = {
      width: '400px !important',
      height: '500px !important'
    };
  }
}
export class RationCard extends Document {
  dimensions: ImageDimension;
  province = '';
  fromYear: number;
  toYear: number;
  cardNumber = '';
  houseHoldName = '';
  numberOfFamilyMembers: number;
  address: { m: string; z: string; d: string } = {
    m: '',
    z: '',
    d: ''
  };
  rationCenterNumber = '';
  rationCenterName = '';
  foodAgentNumber = '';
  foodAgentName = '';
  wheatAgentNumber = '';
  wheatAgentName = '';
  issueDate = '';
  familyMembers: Array<{ name: string }> = [];

  faceone: any = null;

  constructor(type: string) {
    super(type);
    this.dimensions = {
      width: '400px !important',
      height: '750px !important'
    };
    this.numberOfFamilyMembers = this.familyMembers.length;
  }
  addMember() {
    this.familyMembers.push({ name: '' });
  }
  removeMember(i: number) {
    this.familyMembers.splice(i, 1);
  }
}

export class CertificateOfNationality extends Document {
  dimensions: ImageDimension;
  walletNumber = '';
  certificateDate = '';
  certificateNumber = '';
  name = '';
  dateAndPlaceOfBirth = '';
  religion = '';
  defects = '';
  fatherName = '';
  fatherPlaceOfBirth = '';
  motherName = '';
  motherPlaceOfBirth = '';

  faceone: any = null;

  constructor(type: string) {
    super(type);
    this.dimensions = {
      width: '400px !important',
      height: '500px !important'
    };
  }
}
export class UniversityId extends Document {
  dimensions: ImageDimension;

  faceone: any = null;
  facetwo: any = null;

  constructor(type: string) {
    super(type);
    this.dimensions = {
      width: '400px !important',
      height: '350px !important'
    };
  }
}
export class CivilId extends Document {
  dimensions: ImageDimension;
  office = '';
  recordNumber = '';
  sheetNumber = '';
  cardNumber = '';
  name = '';
  fatherName = '';
  motherName = '';
  sureName = '';
  gender = '';
  employeeName = '';
  issueDate = '';
  officerName = '';

  job = '';
  religion = '';
  dateOfBirth = '';
  dateOfBirthWritten = '';
  placeOfBirth = '';
  maritialStatus = '';
  partenerName = '';
  placeOfRecording = '';
  defects = '';
  eyeColor = '';
  faceColor = '';
  hairColor = '';
  length = '';
  blood = '';

  faceone: any = null;
  facetwo: any = null;

  constructor(type: string) {
    super(type);
    this.dimensions = {
      width: '400px !important',
      height: '400px !important'
    };
  }
}
export class AddressCard extends Document {
  dimensions: ImageDimension;
  informationOffice = '';
  householdName = '';
  address: { m: string; z: string; d: string } = {
    m: '',
    z: '',
    d: ''
  };
  cardNumber: number;
  cardType = '';
  char = '';
  sequence = '';
  issueDate = '';
  officerName = '';

  faceone: any = null;
  facetwo: any = null;

  constructor(type: string) {
    super(type);
    this.dimensions = {
      width: '400px !important',
      height: '250px !important'
    };
  }
}
