import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DatatableUtil } from 'src/app/classes/datatable';
import { leaders } from 'src/app/classes/leaders';
import { PunishmentModel } from 'src/app/models/hr/employee/awardspunishments';
import { AwardsPunishmentsService } from 'src/app/services/employee/awards-punishments.service';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmationDialogComponent } from 'src/app/shared-components/dialogs/dialogs';

@Component({
  selector: 'app-punishments',
  templateUrl: './punishments.component.html',
  styleUrls: ['./punishments.component.scss']
})
export class PunishmentsComponent implements OnInit {
  view = 'list';
  public userId: string;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();
  type = 'عقوبه';
  items: any[] = [];

  SelectedItem: any = null;
  constructor(
    private awardsPunishmentsService: AwardsPunishmentsService,
    private Activeroute: ActivatedRoute,
    public modal: ModalService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.userId =
      await this.Activeroute.parent.parent.snapshot.params['userId'];
    this.dt.setDtElement(this.dtElement);
    this.findAll();
    this.dt.trigger();
  }
  newOrEdit(view: string) {
    if (view == 'edit') {
      this.SelectedItem = Object.assign(
        new PunishmentModel(),
        this.SelectedItem
      );
    }
    this.view = view;
  }
  findAll() {
    this.awardsPunishmentsService
      .findAll(this.userId, this.type)
      .subscribe(response => {
        this.items = response;
        this.dt.reload();
        this.view = 'list';
      });
  }

  remove() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      position: {
        top: '65px'
      },
      data: {
        title: `حذف عقوبة`,
        warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات ألعقوبة بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
        confirmationMessage: 'هل أنت متأكد من أتمام عملية ألحذف ؟',
        yesNo: { yes: 'نعم', no: 'كلا' }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.awardsPunishmentsService
          .remove(this.SelectedItem._id)
          .subscribe(() => {
            this.findAll();
          });
      }
    });
  }
}

@Component({
  selector: 'app-punishments-form',
  templateUrl: './punishment-form.component.html'
})
export class PunishmentsFormComponent implements OnInit {
  @Input() userId: string;
  @Input() Item = new PunishmentModel();
  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
  leaders: string[] = leaders;
  isLoading = false;

  punishmentTypes: string[] = [
    'لفت نظر',
    'أنذار',
    'قطع راتب',
    'توبيخ',
    'أنقاص راتب',
    'تنزيل درجه'
  ];
  monthsList: number[] = [];
  constructor(private awardsPunishmentService: AwardsPunishmentsService) {}

  ngOnInit(): void {
    this.monthsList = Array.from(Array(37).keys());
    this.Item.issueDate = new Date(this.Item.issueDate);
  }
  submit() {
    this.isLoading = true;
    this.awardsPunishmentService
      .createOrUpdate(this.userId, this.Item.toFormData())
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.viewChange.emit('list');
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  removeFile(documentId: string, fileId: string) {
    this.awardsPunishmentService.removeFile(documentId, { fileId }).subscribe();
  }
}
