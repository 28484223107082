<div class="wrapper">
  <div class="contents">
    <div class="container">
      <app-page-header-info
        title2="كتب ألشكر وألعقوبات "></app-page-header-info>
      <div class="content-container my-7 mx-md-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="sidebar left-sidebar border-right">
    <button class="btn close-sidebar-button border border-radius-25 btn-light">
      <i class="fa fa-times hover-danger text-dark"></i>
    </button>
    <ul class="list-unstyled mb-10">
      <li class="border-top-0">
        <a routerLink="awards" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> كتب ألشكر
        </a>
      </li>
      <li>
        <a routerLink="punishments" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> كتب ألعقوبات
        </a>
      </li>
    </ul>
  </div>
</div>
