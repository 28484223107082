import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { FilesService } from './files/file.service';
import { USerRolesService } from './system-manager/system-roles.service';

export interface UserDetails {
  userId: string;
  email: string;
  exp: number;
  photo: string;
  fullname: string;
  workplaceId: string;
}
interface TokenResponse {
  access_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  url = environment.ApiUrl + '/auth/';

  private roleSubject = new BehaviorSubject<any[]>([]);

  userRoles: any[] = [];

  currentRouter = 'employee';

  private http: HttpClient;

  private token = '';
  constructor(
    handler: HttpBackend,
    private router: Router,
    private fileService: FilesService,
    private userRoleService: USerRolesService
  ) {
    this.http = new HttpClient(handler);
    if (this.isLoggedIn()) {
      this.getUserRoles();
    }
  }

  private saveToken(token: string): void {
    localStorage.setItem('usertoken', token);
    this.token = token;
  }

  getToken(): string {
    if (!this.token) {
      this.token = <string>localStorage.getItem('usertoken');
    }
    return this.token;
  }

  getUserPhoto(): string {
    const user = this.getUserDetails();
    let imgSrc = 'assets/img/user-vatar.png';
    if (user) {
      if (user.photo) {
        imgSrc = this.fileService.getFileUrl(user.photo);
      }
    }
    return imgSrc;
  }

  getFullname(): string {
    const fullname = this.getUserDetails().fullname;
    return fullname;
  }
  public getUserDetails(): UserDetails {
    const token = this.getToken();
    if (token) {
      let payload = token.split('.')[1];
      payload = window.atob(payload);
      const user: UserDetails = JSON.parse(payload);
      user.fullname = decodeURIComponent(user.fullname);
      return user;
    }
    return null as any;
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      const isExpired = user.exp > Date.now() / 1000;
      if (isExpired) this.logout;
      return isExpired;
    }
    return false;
  }

  public login(user: any): Observable<any> {
    const base = this.http.post<TokenResponse>(this.url + 'signin', user, {
      withCredentials: true
    });
    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.access_token) {
          this.saveToken(data.access_token);
          this.getUserRoles();
        }
        return data;
      })
    );
    return request;
  }

  hasRole(route: string): boolean {
    return this.userRoles.findIndex(role => role.roleId.link == route) > -1;
  }

  getRoles() {
    return this.userRoles;
  }
  getUserRoles() {
    this.userRoleService
      .findAll(this.getUserDetails().userId)
      .subscribe(response => {
        this.userRoles = response;
        this.roleSubject.next(this.userRoles);
      });
  }
  getRolesObservable(): Observable<any[]> {
    return this.roleSubject.asObservable();
  }
  public resetPassword(data: any): Observable<any> {
    return this.http.post(this.url + '/account/resetpassword', data);
  }
  public validatePasswordChange(data: any): Observable<any> {
    return this.http.post(this.url + '/account/validatepasswordreset', data);
  }
  public changePassword(data: any): Observable<any> {
    return this.http.post(this.url + '/account/changepassword', data);
  }

  public resendConfirmationEmail(data: any): Observable<any> {
    return this.http.post(this.url + '/account/resendConfirmationemail', data);
  }

  public logout(): void {
    this.token = null;
    window.localStorage.removeItem('usertoken');
    this.userRoles = [];
    this.roleSubject.next(this.userRoles);
    window.location.href = `https://accounts.uomosul.edu.iq/logout?return_to=${window.location.href}`;
  }

  public handleError(errorMessage: any): string {
    let error = 'Undefined error';
    if (errorMessage.error) {
      const e = JSON.stringify(errorMessage.error);
      error = JSON.parse(e)['error'];
    }
    return error;
  }
}
