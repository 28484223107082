import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpLoadingService } from '../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class DataInjectionService {
  private url = environment.ApiUrl + '/datainjection/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  create(workplaceId: string, data: FormData): Observable<any> {
    return this.http.post(this.url + workplaceId, data);
  }

  deleteAll(workplaceId: string): Observable<any> {
    return this.http.delete(this.url + workplaceId);
  }
}
