import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  submitted = false;
  error: string = null;
  unverifiedError = null;
  ConfirmationSuccess = null;
  ConfirmationError = null;
  isLoading = true;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/profile']);
    } else {
      this.auth
        .login({ username: 'stub@uomosul.edu.iq', password: '123456' })
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.router.navigate(['/profile']);
          },
          error: () => {
            // User is not logged in
            // TODO: Refactor into an environment variable?
            window.location.href = `https://accounts.uomosul.edu.iq/login?return_to=${window.location.href}`;
          }
        });
    }

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  login() {
    this.submitted = true;
    this.error =
      this.unverifiedError =
      this.ConfirmationError =
      this.ConfirmationSuccess =
        null;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.auth.login(this.loginForm.value).subscribe({
      next: () => {
        this.isLoading = false;
        this.router.navigate(['/profile']);
      },
      error: () => {
        this.error = 'أسم ألمستخدم أو كلمة ألمرور غير صحيحه !!';
        this.isLoading = false;
      }
    });
  }
}
