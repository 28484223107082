<div [ngClass]="{ 'd-none': view !== 'list' }">
  <div class="card mt-6">
    <div class="card-header">
      <h4>
        <i class="fas fa-list pl-3"></i> ألدرجة {{ Item.grade }} : ألمراحل
        ألوظيفية
      </h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button class="btn btn-print" (click)="submit()" title="حفظ ألدرجه ">
          <i class="fa fa-save fa-lg"></i>
        </button>
        <button
          class="btn btn-print"
          title="رجوع ألى ألقائمه"
          (click)="viewChange.emit('list')">
          <i class="fa fa-share fa-lg"></i>
        </button>
      </div>
    </div>
    <div class="card-block px-0">
      <table
        id="upgradesummary"
        datatable
        [dtOptions]="this.dt.dtOptions"
        [dtTrigger]="this.dt.dtTrigger"
        class="table table-bordered table-striped border-0 my-0">
        <thead>
          <tr>
            <th>#</th>
            <th class="text-center">ألمرحلة</th>
            <th class="text-center">ألراتب ألأسمي</th>

            <th class="text-center" style="width: 15%">
              <i class="fa fa-ellipsis-v"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Item.stages; let i = index">
            <th>{{ i + 1 }}</th>
            <td class="text-center">{{ item.stage }}</td>
            <td class="text-center">{{ item.salary }}</td>
            <th class="text-center">
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  <i class="fa fa-ellipsis-v text-secondary"></i>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit()">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل ألمرحلة
                  </li>
                </ul>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="card mt-6" *ngIf="view === 'edit'">
  <div class="card-header">
    <h4 class="pl-5">
      <i class="fas fa-list pl-3"></i> تعديل ألمرحلة : (
      {{ SelectedItem.stage }}
      )
    </h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="saveStage()" title="حفظ ألعنوان">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="this.view = 'list'">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <label for="stage"> ألمرحله ألوظيفية :</label>
        <input
          type="text"
          class="form-control text-center"
          id="stage"
          name="stage"
          [(ngModel)]="SelectedItem.stage" />
      </div>
      <div class="col-md-4 offset-md-4">
        <label for="salary"> ألراتب ألأسمي :</label>
        <input
          type="number"
          class="form-control text-center"
          id="salary"
          name="salary"
          [(ngModel)]="SelectedItem.salary" />
      </div>
    </div>
  </div>
</div>
