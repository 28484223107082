<div class="wrapper">
  <div class="contents">
    <div class="container">
      <app-page-header-info
        id="structure-headers"
        title2="ألدرجات و ألعناوين ألوظيفية"></app-page-header-info>
      <div class="content-container my-7 mx-md-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="sidebar left-sidebar border-right" id="structure-routes">
    <ul class="list-unstyled mb-10">
      <li class="border-top-0">
        <a routerLink="permanent-grades" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> ألموظفين ألدائميين
        </a>
      </li>
      <li>
        <a routerLink="contract-grades" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> ألعقود
        </a>
      </li>
      <li>
        <a routerLink="daily-grades" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> ألأجور أليومية
        </a>
      </li>
    </ul>
  </div>
</div>
