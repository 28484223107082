import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { DatatableUtil } from 'src/app/classes/datatable';
import { Role } from 'src/app/models/system/roles';
import {
  SystemRolesService,
  USerRolesService
} from 'src/app/services/system-manager/system-roles.service';
import { WorkplaceUtilService } from 'src/app/services/util/workplace.service';

const roleLevels: string[] = ['قراءه', 'كتابه', 'حذف'];
@Component({
  selector: 'app-roles-management',
  templateUrl: './roles-management.component.html'
})
export class RolesManagementComponent implements OnInit, AfterViewInit {
  roleCategories: string[] = [
    'ألموارد ألبشرية',
    'ألحسابات وألرواتب',
    'ألتخطيط وألمعلوماتيه',
    'ألهويات',
    'ألبصمة ألألكترونية',
    'ألترقيات ألعلميه',
    'ألتقييم وألأداء',
    'مدير ألنظام'
  ];

  roleLevels = roleLevels;

  groupedRoles: any[] = [];

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();
  view = 'list';
  Items: any[] = [];
  SelectedItem: Role = null;
  selectedCategory: string = null;

  constructor(private roleService: SystemRolesService) {}

  ngOnInit(): void {
    this.selectedCategory = this.roleCategories[0];
    this.roleService.findAll().subscribe(response => {
      this.groupedRoles = response;
    });
  }

  findCategoryRoles(category: string) {
    let groupRoles: any[] = [];
    const group = this.groupedRoles.find(g => g.category == category);
    if (group) {
      groupRoles = group.roles;
    }
    return groupRoles;
  }

  async ngAfterViewInit() {
    this.dt.setDtElement(this.dtElement);
    this.findAll();
    this.dt.trigger();
  }

  changeCategory(category: string) {
    this.selectedCategory = category;
    this.Items = this.findCategoryRoles(this.selectedCategory);
    this.dt.reload();
  }

  findAll() {
    this.roleService.findAll().subscribe(response => {
      this.groupedRoles = response;
      this.Items = this.findCategoryRoles(this.selectedCategory);
      this.dt.reload();
      this.view = 'list';
    });
  }

  newOrEdit(view: string) {
    if (view == 'new') {
      this.SelectedItem = new Role(this.selectedCategory);
    }
    this.view = view;
  }

  submit() {
    if (this.SelectedItem._id) {
      this.roleService
        .update(this.SelectedItem._id, this.SelectedItem)
        .subscribe(() => {
          this.findAll();
        });
    } else {
      this.roleService.create(this.SelectedItem).subscribe(() => {
        this.findAll();
      });
    }
  }

  onChange(event: any) {
    const checkedValue = event.target.value;
    if (event.target.checked) {
      this.SelectedItem.priviliges.push(checkedValue);
    } else {
      const index = this.SelectedItem.priviliges.indexOf(checkedValue);
      this.SelectedItem.priviliges.splice(index, 1);
    }
  }

  isChecked(level: string) {
    return this.SelectedItem.priviliges.indexOf(level) !== -1;
  }
}

@Component({
  selector: 'app-role-users',
  templateUrl: './role-users.html'
})
export class RoleUsersComponent implements AfterViewInit {
  @Input() role: Role;
  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();
  view = 'list';
  users: any[] = [];
  SelectedUser: any = null;

  constructor(
    private userService: USerRolesService,
    public workplaceUtilsevice: WorkplaceUtilService
  ) {}
  async ngAfterViewInit() {
    this.dt.setDtElement(this.dtElement);
    this.findAll();
    this.dt.trigger();
  }

  findAll() {
    this.userService.getRoleUsers(this.role._id).subscribe(response => {
      this.view = 'list';
      console.log(response.filter(Boolean).length);
      this.users = response.filter(Boolean);
      this.dt.reload();
    });
  }
}
