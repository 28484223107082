<div class="files bg-creamy border border-radius-20">
  <div class="row header mb-0" *ngIf="header">
    <div class="col-md-12 py-1 px-2">
      <h4 class="w-100">
        <a
          [href]="'#' + id"
          data-toggle="collapse"
          aria-expanded="true"
          class="dropdown-toggle">
          <i class="fa fa-calendar-alt px-2"></i
          ><span class="text-secondary">{{ header + ' :' }}</span>
        </a>
      </h4>
    </div>
  </div>
  <div class="collapse show" [id]="id">
    <hr class="mt-0 pt-0" />
    <div class="row pt-1 pb-5 px-3">
      <div class="col-md-3">
        <app-datepicker
          [input]="filterDates.fromDate"
          [name]="'fromDate'"
          [label]="'من تـاريخ'"
          (dateChange)="filterDates.fromDate = $event">
        </app-datepicker>
      </div>

      <div class="col-md-3">
        <app-datepicker
          [input]="filterDates.toDate"
          [name]="'toDate'"
          [label]="'ألى تـاريخ'"
          (dateChange)="filterDates.toDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-3 pt-4">
        <button
          type="button"
          class="btn btn-success btn-block mt-1 border-radius-25"
          (click)="isFilter = true; this.filterChange.emit(true)">
          <i *ngIf="isFilter" class="fa fa-check-circle pl-3 fa-lg mt-1"></i>
          <i *ngIf="!isFilter" class="fa fa-filter pl-3"></i> فرز ألاجازات
        </button>
      </div>

      <div class="col-md-3 text-left off pt-4">
        <button
          type="button"
          class="btn btn-success btn-block px-2 mt-1 border-radius-25"
          title="جميع ألنتائج"
          (click)="isFilter = false; this.filterChange.emit(false)">
          <i *ngIf="!isFilter" class="fa fa-check-circle pl-3 fa-lg mt-1"></i>
          <i *ngIf="isFilter" class="fas fa-list fa-rotate-180 fa-1x pr-3"></i>
          جميع ألنتائج
        </button>
      </div>
    </div>
  </div>
</div>
