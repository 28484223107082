<div class="container">
  <app-page-header-info
    title2="ألأدوار وألصلاحيات"
    id="main-header"></app-page-header-info>
  <div class="content-container my-7 mx-md-4">
    <div [ngClass]="{ 'd-none': view !== 'list' }">
      <div class="card mt-6">
        <div class="card-header">
          <h4>
            <i class="fas fa-user-lock pl-3"></i> ألأدوار وألصلاحيات
            {{ this.selectedCategory ? ' : ' + this.selectedCategory : '' }}
          </h4>
          <div
            class="toolbar d-flex d-inline-flex w-30 justify-content-between"
            *ngIf="selectedCategory">
            <button
              class="btn btn-print"
              (click)="newOrEdit('new')"
              title="أضافة دور جديد">
              <i class="fa fa-plus-circle fa-2x"></i>
            </button>
          </div>
        </div>
        <div class="card-block px-0">
          <table
            id="upgradesummary"
            datatable
            [dtOptions]="this.dt.dtOptions"
            [dtTrigger]="this.dt.dtTrigger"
            class="table table-bordered table-striped border-0 my-0">
            <thead>
              <tr>
                <th>#</th>
                <th>ألقسم</th>
                <th>ألصلاحية</th>
                <th class="w-25">عدد ألمستخدمين</th>
                <th class="text-center" style="width: 15%">
                  <i class="fa fa-ellipsis-v"></i>
                </th>
              </tr>
            </thead>
            <tbody class="text-secondary">
              <tr *ngFor="let item of Items; let i = index">
                <th>{{ i + 1 }}</th>
                <th class="pr-3">{{ item.category }}</th>
                <th class="pr-3">{{ item.name }}</th>
                <th class="text-center">
                  {{ item.userRolesCount }}
                </th>
                <th class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true">
                      <i class="fa fa-ellipsis-v text-secondary"></i>
                    </button>
                    <ul
                      class="dropdown-menu bg-creamy"
                      aria-labelledby="dropdownMenuButton">
                      <li
                        class="dropdown-item"
                        (click)="SelectedItem = item; newOrEdit('edit')">
                        <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                        تعديل ألصلاحية
                      </li>
                      <li
                        class="dropdown-item"
                        (click)="SelectedItem = item; newOrEdit('users')">
                        <i class="fa fa-users fa-lg pl-1 text-secondary"></i>
                        موظفين ألصلاحية
                      </li>
                    </ul>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card mt-6" *ngIf="view === 'new' || view === 'edit'">
      <div class="card-header">
        <h4 *ngIf="view === 'new'">
          <i class="fa fa-plus"></i>
          {{ this.SelectedItem.category }} : أضافة دور جديد
        </h4>
        <h4 *ngIf="view === 'edit'">
          <i class="fa fa-edit"></i> تعديل
          {{ this.SelectedItem.category }}
        </h4>
        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
          <button class="btn btn-print" (click)="submit()" title="حفظ ألدرجه ">
            <i class="fa fa-save fa-lg"></i>
          </button>
          <button
            class="btn btn-print"
            title="رجوع ألى ألقائمه"
            (click)="this.view = 'list'">
            <i class="fa fa-share fa-lg"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <label for="roletitle" class="form-label"> أسم ألصلاحية :</label>
            <input
              type="text"
              class="form-control"
              name="roletitle"
              id="roletitle"
              [(ngModel)]="SelectedItem.name" />
          </div>
          <div class="col-md-4 offset-md-3">
            <label for="rolename" class="form-label"> ألرابط:</label>
            <input
              type="text"
              dir="ltr"
              lang="en"
              class="form-control"
              name="rolename"
              id="rolename"
              [(ngModel)]="SelectedItem.link" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pt-1">
            <label class="control-label d-block">
              مستوى ألصلاحية <span class="float-left">:</span>
            </label>
          </div>
          <div class="col-md-7 d-flex justify-content-between">
            <div
              class="icheck-greensea"
              *ngFor="let level of roleLevels; let i = index">
              <input
                class="box"
                type="checkbox"
                name="level"
                [checked]="isChecked(level)"
                (change)="onChange($event)"
                [value]="level"
                id="level_{{ i }}" />
              <label for="level_{{ i }}"> {{ level }} </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
              <mat-label class="w-50 px-3">وصف ألصلاحيه</mat-label>
              <textarea
                matInput
                placeholder=""
                rows="3"
                class="py-0"
                [(ngModel)]="SelectedItem.description"></textarea>
              <mat-icon matSuffix class="text-secondary">comment</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <app-role-users
      *ngIf="view === 'users'"
      [role]="SelectedItem"
      (viewChange)="this.findAll()">
    </app-role-users>
  </div>
</div>
