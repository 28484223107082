import { Files } from 'src/app/classes/Files';

export class WorkplaceModel {
  _id: string = null;
  isMoh = true;
  employmentType = 'دائمي';
  office = '';
  fromDate: Date = new Date();
  fromDocumentDate: Date = new Date();
  fromDocumentNumber = '';
  toDate: Date = new Date();
  toDocumentDate: Date = new Date();
  toDocumentNumber = '';
  Files: Files = new Files();
  files: Array<any> = [];
  serviceImpactFactor = 1;
  fullname = '';
  mainWorkplace = false;
  secondaryWorkplace = false;
  movementType = 'دائمي';
  notes = '';
  constructor(type = true) {
    this.isMoh = type;
  }
  toFormData(): FormData {
    const data = new FormData();
    data.append('isMoh', this.isMoh.toString());
    data.append('office', this.office);
    data.append('fromDate', this.fromDate?.toString());
    data.append('fromDocumentDate', this.fromDocumentDate?.toString());
    data.append('fromDocumentNumber', this.fromDocumentNumber.toString());
    data.append('employmentType', this.employmentType);

    if (this.mainWorkplace == false) {
      data.append('toDate', this.toDate?.toString());
      data.append('toDocumentDate', this.toDocumentDate?.toString());
      data.append('toDocumentNumber', this.toDocumentNumber.toString());
    }

    data.append('movementType', this.movementType);
    data.append('serviceImpactFactor', this.serviceImpactFactor.toString());
    data.append('fullname', this.fullname);
    data.append('mainWorkplace', this.mainWorkplace.toString());
    data.append('secondaryWorkplace', this.secondaryWorkplace.toString());
    data.append('notes', this.notes);
    this.Files.appendFilesToForm(data);
    return data;
  }
}

export class MinstryWorkplace extends WorkplaceModel {
  workplaceId: string = null;
  override toFormData(): FormData {
    this.serviceImpactFactor = 1;
    const data = super.toFormData();
    data.append(
      'workplaceId',
      this.isMoh ? this.workplaceId : '645ca823725629319e3069ac'
    );
    return data;
  }
}
