import { Files } from 'src/app/classes/Files';

export class GradeModel {
  _id: string = null;
  grade = 'الثالثة';
  jobTitle = 'استاذ مساعد';
  deserveDate: Date = new Date();
  assignDate: Date = new Date();
  upgradeReason = 'ترفيع أعتيادي';
  documentNumber = '';

  stages: Array<StageModel> = [];
  category = 'فني';
  employeeType = 'موظف'; //موظف أو تدريسي
  isCompleted = false;
  notes = '';

  Files: Files = new Files();
  files: any[] = [];

  addBonus(): void {
    const newStage = new StageModel();
    const stagesLength = this.stages.length;
    if (stagesLength) {
      const lastStage = this.stages[stagesLength - 1].stage;
      newStage.stage = lastStage + 1;
    }
    this.stages.push(newStage);
  }
  removeBonus(i: number): void {
    this.stages.splice(i, 1);
  }

  toFormData() {
    const data = new FormData();
    if (this._id) {
      data.append('_id', this._id);
    }
    data.append('grade', this.grade);
    data.append('jobTitle', this.jobTitle);
    data.append('deserveDate', this.deserveDate?.toString());
    data.append('assignDate', this.assignDate?.toString());
    data.append('documentNumber', this.documentNumber);
    data.append('upgradeReason', this.upgradeReason);
    data.append('category', this.category);
    data.append('employeeType', this.employeeType);
    data.append('isCompleted', this.isCompleted.toString());
    this.Files.appendFilesToForm(data);
    return data;
  }
}

export class StageModel {
  _id: string = null;
  stage = 1;
  deserveDate: Date = new Date();
  assignDate: Date = new Date();
  documentNumber = '';
  isComplementry = false;
  Files: Files = new Files();
  files: any[] = [];

  toFormData() {
    const data = new FormData();
    if (this._id) {
      data.append('_id', this._id);
    }
    data.append('stage', this.stage.toString());
    data.append('deserveDate', this.deserveDate?.toString());
    data.append('assignDate', this.assignDate?.toString());
    data.append('documentNumber', this.documentNumber);
    data.append('isComplementry', this.isComplementry.toString());
    this.Files.appendFilesToForm(data);
    return data;
  }
}
