<div class="card summary" #content id="content">
  <div class="card-header">
    <h4 class="pl-5"><i class="fa fa-list"></i> خلاصة خدمة (عامه)</h4>
    <div class="title">
      <label class="px-2 d-none d-print-block">
        تاريخ تنظيم ألأستماره :
        {{ currentDate | formatDate | arabicDate }}</label
      >
    </div>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="print()" title="طباعة ألأستمارة">
        <i class="fa fa-print fa-1x"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body p-0 bg-white" *ngIf="info">
    <table id="summary" class="table border-0 my-0">
      <tbody class="p-0 m-0">
        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">ألأسم ألرباعي وأللقب</div>
              <div class="col-4 value">
                {{ info.basicinfo ? info.basicinfo.fullName : '' }}
              </div>
              <div class="col-3 title text-center">ألجنس</div>
              <div class="col-2 value">
                {{ info.basicinfo ? info.basicinfo.gender : '' }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">أسم ألأم ألثلاثي</div>
              <div class="col-9 value">
                {{ info.basicinfo ? info.basicinfo.motherName : '' }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">محل ألولاده</div>
              <div class="col-4 value">{{ info.placeOfBirth }}</div>
              <div class="col-3 title text-center">تاريخ ألولاده</div>
              <div class="col-2 value">
                {{
                  (info.basicinfo ? info.basicinfo.birthDate : '') | arabicDate
                }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">أخر شهادة حاصل عليها</div>
              <div class="col-9 px-0 border-0">
                <div class="row">
                  <div class="col-4 value">
                    {{ info.degree ? info.degree.type : '' }}
                  </div>
                  <div class="col-8 border-0 px-0">
                    <div class="row">
                      <div class="col-5 title">تاريخ ألحصول عليها</div>
                      <div class="col-7 value">
                        {{
                          (info.degree ? info.degree.degreeDate : '')
                            | formatDate
                            | arabicDate
                        }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 title">بلد ألدراسه</div>
                      <div class="col-7 value">
                        {{ info.degree ? info.degree.country : '' }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 title">ألتخصص ألدقيق</div>
                      <div class="col-7 value">
                        {{ info.degree ? info.degree.field : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 title">ألجهة ألمانحة للشهادة</div>
                  <div class="col-8 value">
                    <app-workplace-tree-view
                      *ngIf="info.degree && info.degree.issuer"
                      [treeData]="info.degree.issuer"></app-workplace-tree-view>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">ألعنوان ألوظيفي ألحالي</div>
              <div class="col-4 value">
                {{ info.grade ? info.grade.jobTitle : '' }}
              </div>
              <div class="col-3 title text-center">تاريخ ألحصول عليه</div>
              <div class="col-2 value">
                {{
                  (info.grade ? info.grade.deserveDate : '')
                    | formatDate
                    | arabicDate
                }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">تاريخ أول تعيين بالوظيفة</div>
              <div class="col-2 value">
                {{
                  (info.employmentinfo ? info.employmentinfo.hireDate : '')
                    | formatDate
                    | arabicDate
                }}
              </div>
              <div class="col-2 title text-center">مكان ألتعيين</div>
              <div class="col-5 value">
                <app-workplace-tree-view
                  *ngIf="info.firstPlace"
                  [treeData]="info.firstPlace"></app-workplace-tree-view>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-6 title">
                تاريخ أول مباشرة في وزارة ألتعليم ألعالي
              </div>
              <div class="col-6 value">
                {{
                  (info.employmentinfo ? info.employmentinfo.mohStartDate : '')
                    | arabicDate
                }}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-3 title">تاريخ أعادة ألتعيين</div>
              <div class="col-3 value">
                {{
                  (info.employmentinfo
                    ? info.employmentinfo.rehireinfo.rehireDate
                    : ''
                  ) | arabicDate
                }}
              </div>
              <div class="col-4 title">تاريخ ألمباشرة بعد أعادة ألتعيين</div>
              <div class="col-2 value">
                {{
                  (info.employmentinfo
                    ? info.employmentinfo.rehireinfo.rehireStartDate
                    : ''
                  ) | arabicDate
                }}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-6 title">تاريخ أنهاء ألخدمة</div>
              <div class="col-6 value">
                <span>
                  {{
                    (info.employmentinfo
                      ? info.employmentinfo.terminationInfo.terminationDate
                      : ''
                    ) | arabicDate
                  }}</span
                >
                <span
                  class="mr-3"
                  *ngIf="
                    info.employmentinfo &&
                    info.employmentinfo.terminationInfo.employeeStatus
                  ">
                  ( {{ info.employmentinfo.terminationInfo.employeeStatus }} )
                </span>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-6 title">
                مدة ألخدمة خارج وزارة ألتعليم ألعالي وألبحث ألعلمي
              </div>
              <div class="col-6 px-0 border-0">
                <div class="row">
                  <div class="col-4 title text-center">يوم</div>
                  <div class="col-4 title text-center">شهر</div>
                  <div class="col-4 title text-center">سنه</div>
                </div>

                <div class="row">
                  <div class="col-4 value text-center">
                    {{ outOfMohServiceDuration.days | translateDigits: 'ar' }}
                  </div>
                  <div class="col-4 value text-center">
                    {{ outOfMohServiceDuration.months | translateDigits: 'ar' }}
                  </div>
                  <div class="col-4 value text-center">
                    {{ outOfMohServiceDuration.years | translateDigits: 'ar' }}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-6 title">
                مدة ألخدمة داخل وزارة ألتعليم ألعالي وألبحث ألعلمي
              </div>
              <div class="col-6 px-0 border-0">
                <div class="row">
                  <div class="col-4 title text-center">يوم</div>
                  <div class="col-4 title text-center">شهر</div>
                  <div class="col-4 title text-center">سنه</div>
                </div>

                <div class="row">
                  <div class="col-4 value text-center">
                    {{ inMohServiceDuration.days | translateDigits: 'ar' }}
                  </div>
                  <div class="col-4 value text-center">
                    {{ inMohServiceDuration.months | translateDigits: 'ar' }}
                  </div>
                  <div class="col-4 value text-center">
                    {{ inMohServiceDuration.years | translateDigits: 'ar' }}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-6 title">مكان ألعمل ألحالي</div>
              <div class="col-6 value pr-2">
                <app-workplace-tree-view
                  *ngIf="info.workplace"
                  [treeData]="info.workplace"></app-workplace-tree-view>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-4 title">ألدرجة ألوظيفية ألحالية</div>
              <div class="col-2 value">
                {{ info.grade ? info.grade.grade : '' }}
              </div>
              <div class="col-2 title text-center">ألمرحله</div>
              <div class="col-4 value">
                {{
                  info.stage ? info.stage.stage : ('' | translateDigits: 'ar')
                }}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-4 middle title">ألعقوبات</div>
              <div class="col-8 value" *ngIf="!punishments">-- لا يوجد --</div>
              <div class="col-8 px-0 border-0" *ngIf="punishments">
                <div class="row">
                  <div class="col-6 title">ألجهة ألمصدره (نوع ألعقوبة)</div>
                  <div class="col-4 title">رقم وتاريخ ألعقوبه</div>
                  <div class="col-2 title">ألتأخير</div>
                </div>
                <div class="row" *ngFor="let item of punishments.list">
                  <div class="col-6 value">
                    <span>{{
                      item.issuer + ' ( ' + item.punishmentType + ' ) '
                    }}</span>
                  </div>
                  <div class="col-4 value d-flex justify-content-between px-1">
                    <span>{{
                      item.documentNumber | translateDigits: 'ar'
                    }}</span>
                    <span>{{ item.issueDate | arabicDate }}</span>
                  </div>
                  <div class="col-2 value d-flex justify-content-between">
                    <span>
                      {{ item.affectMonths.toString() | translateDigits: 'ar' }}
                    </span>
                    <span> شهر</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-10 title">مدة ألتأخير ألكلية</div>

                  <div class="col-2 title value d-flex justify-content-between">
                    <span>
                      {{ punishments.total.toString() | translateDigits: 'ar' }}
                    </span>
                    <span> شهر</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title middle">
                <p>كتب ألشكر من رئيس ألجامعة</p>
              </div>
              <div class="col-8 value" *ngIf="!awards">-- لا يوجد --</div>
              <div class="col-8 px-0 border-0" *ngIf="awards">
                <div class="row">
                  <div class="col-6 title">ألجهة ألمانحة للشكر</div>
                  <div class="col-4 title">رقم وتاريخ ألشكر</div>
                  <div class="col-2 title">ألقدم</div>
                </div>
                <div class="row" *ngFor="let item of awards.list">
                  <div class="col-6 value">{{ item.issuer }}</div>
                  <div class="col-4 value d-flex justify-content-between px-1">
                    <span>{{
                      item.documentNumber | translateDigits: 'ar'
                    }}</span>
                    <span>{{ item.issueDate | arabicDate }}</span>
                  </div>
                  <div class="col-2 value d-flex justify-content-between">
                    <span>
                      {{ item.affectMonths.toString() | translateDigits: 'ar' }}
                    </span>
                    <span> شهر</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-10 title">مدة ألقدم ألكلية</div>
                  <div class="col-2 title value d-flex justify-content-between">
                    <span>
                      {{ awards.total.toString() | translateDigits: 'ar' }}
                    </span>
                    <span> شهر</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-4 title middle">ألغيابات</div>
              <div class="col-8 value" *ngIf="!absenseVacations">
                -- لا يوجد --
              </div>
              <div class="col-8 px-0 border-0" *ngIf="absenseVacations">
                <div class="row">
                  <div class="col-3 title">للفتره من</div>
                  <div class="col-3 title">ألى</div>
                  <div class="col-6 title">مدة ألغياب</div>
                </div>
                <div class="row" *ngFor="let item of absenseVacations.list">
                  <div class="col-3 value">
                    {{ item.fromDate | arabicDate }}
                  </div>
                  <div class="col-3 value">{{ item.toDate | arabicDate }}</div>
                  <div class="col-6 value d-flex justify-content-between">
                    <span>{{
                      item.duration.toString() | translateDigits: 'ar'
                    }}</span>
                    <span>يوم</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 title middle">وألبالغه</div>
                  <div class="col-6 value d-flex justify-content-between">
                    <span>{{
                      absenseVacations.duration.toString()
                        | translateDigits: 'ar'
                    }}</span>
                    <span>يوم</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-4 title middle">ألأجازات ألطويلة براتب تام</div>
              <div class="col-8 value" *ngIf="!longVacations">
                -- لا يوجد --
              </div>
              <div class="col-8 px-0 border-0" *ngIf="longVacations">
                <div class="row">
                  <div class="col-3 title">للفترة من</div>
                  <div class="col-3 title">و لغاية</div>
                  <div class="col-3 title">مدة ألأجازه</div>
                  <div class="col-3 title">نوع ألأجازه</div>
                </div>
                <div class="row" *ngFor="let item of longVacations.list">
                  <div class="col-3 value">
                    {{ item.fromDate | arabicDate }}
                  </div>
                  <div class="col-3 value">{{ item.toDate | arabicDate }}</div>
                  <div class="col-3 value d-flex justify-content-between">
                    <span>{{
                      item.duration.toString() | translateDigits: 'ar'
                    }}</span>
                    <span>يوم</span>
                  </div>
                  <div class="col-3 value">{{ item.type }}</div>
                </div>
                <div class="row">
                  <div class="col-3 title middle">وألبالغه</div>
                  <div class="col-9 px-0 border-0">
                    <div class="row">
                      <div class="col-4 title text-center">يوم</div>
                      <div class="col-4 title text-center">شهر</div>
                      <div class="col-4 title text-center">سنه</div>
                    </div>
                    <div class="row">
                      <div class="col-4 value text-center">
                        {{
                          longVacations.durationObject.days
                            | translateDigits: 'ar'
                        }}
                      </div>
                      <div class="col-4 value text-center">
                        {{
                          longVacations.durationObject.months
                            | translateDigits: 'ar'
                        }}
                      </div>
                      <div class="col-4 value text-center">
                        {{
                          longVacations.durationObject.years
                            | translateDigits: 'ar'
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-4 title middle">ألأجازات ألطويلة بدون راتب</div>
              <div class="col-8 value" *ngIf="!unpaidVacations">
                -- لا يوجد --
              </div>
              <div class="col-8 px-0 border-0" *ngIf="unpaidVacations">
                <div class="row">
                  <div class="col-3 title">للفترة من</div>
                  <div class="col-3 title">و لغاية</div>
                  <div class="col-3 title">مدة ألأجازه</div>
                  <div class="col-3 title">نوع ألأجازه</div>
                </div>
                <div class="row" *ngFor="let item of unpaidVacations.list">
                  <div class="col-3 value">
                    {{ item.fromDate | arabicDate }}
                  </div>
                  <div class="col-3 value">{{ item.toDate | arabicDate }}</div>
                  <div class="col-3 value d-flex justify-content-between">
                    <span>{{
                      item.duration.toString() | translateDigits: 'ar'
                    }}</span>
                    <span>يوم</span>
                  </div>
                  <div class="col-3 value">{{ item.type }}</div>
                </div>
                <div class="row">
                  <div class="col-3 title middle">وألبالغه</div>
                  <div class="col-9 px-0 border-0">
                    <div class="row">
                      <div class="col-4 title text-center">يوم</div>
                      <div class="col-4 title text-center">شهر</div>
                      <div class="col-4 title text-center">سنه</div>
                    </div>
                    <div class="row">
                      <div class="col-4 value text-center">
                        {{
                          unpaidVacations.durationObject.days
                            | translateDigits: 'ar'
                        }}
                      </div>
                      <div class="col-4 value text-center">
                        {{
                          unpaidVacations.durationObject.months
                            | translateDigits: 'ar'
                        }}
                      </div>
                      <div class="col-4 value text-center">
                        {{
                          unpaidVacations.durationObject.years
                            | translateDigits: 'ar'
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-4 title middle">أجازة أربع أو خمس سنوات</div>
              <div class="col-8 value" *ngIf="!fiveYearsVacations">
                -- لا يوجد --
              </div>
              <div class="col-8 px-0 border-0" *ngIf="fiveYearsVacations">
                <div class="row">
                  <div class="col-3 title">للفترة من</div>
                  <div class="col-3 title">و لغاية</div>
                  <div class="col-3 title">مدة ألأجازه</div>
                  <div class="col-3 title">نوع ألأجازه</div>
                </div>
                <div class="row" *ngFor="let item of fiveYearsVacations.list">
                  <div class="col-3 value">
                    {{ item.fromDate | arabicDate }}
                  </div>
                  <div class="col-3 value">{{ item.toDate | arabicDate }}</div>
                  <div class="col-3 value d-flex justify-content-between">
                    <span>{{
                      item.duration.toString() | translateDigits: 'ar'
                    }}</span>
                    <span>يوم</span>
                  </div>
                  <div class="col-3 value">{{ item.type }}</div>
                </div>
                <div class="row">
                  <div class="col-3 title middle">وألبالغه</div>
                  <div class="col-9 px-0 border-0">
                    <div class="row">
                      <div class="col-4 title text-center">يوم</div>
                      <div class="col-4 title text-center">شهر</div>
                      <div class="col-4 title text-center">سنه</div>
                    </div>
                    <div class="row">
                      <div class="col-4 value text-center">
                        {{
                          fiveYearsVacations.durationObject.days
                            | translateDigits: 'ar'
                        }}
                      </div>
                      <div class="col-4 value text-center">
                        {{
                          fiveYearsVacations.durationObject.months
                            | translateDigits: 'ar'
                        }}
                      </div>
                      <div class="col-4 value text-center">
                        {{
                          fiveYearsVacations.durationObject.years
                            | translateDigits: 'ar'
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="row">
              <div class="col-6 title">مدة خدمة ألعقد</div>
              <div class="col-6 px-0 border-0">
                <div class="row">
                  <div class="col-4 title text-center">يوم</div>
                  <div class="col-4 title text-center">شهر</div>
                  <div class="col-4 title text-center">سنه</div>
                </div>
                <div class="row">
                  <div class="col-4 value text-center">
                    {{ contractServiceDuration.days | translateDigits: 'ar' }}
                  </div>
                  <div class="col-4 value text-center">
                    {{ contractServiceDuration.months | translateDigits: 'ar' }}
                  </div>
                  <div class="col-4 value text-center">
                    {{ contractServiceDuration.years | translateDigits: 'ar' }}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row font-weight-bold my-10">
      <div class="col-md-3 text-center">منظم ألأستماره</div>
      <div class="col-md-3 text-center">مسؤول ألموارد ألبشريه</div>
      <div class="col-md-3 text-center">مدقق ألكليه</div>
      <div class="col-md-3 text-center">عميد ألكليه</div>
    </div>
  </div>
</div>
