import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DateUtil } from 'src/app/classes/Utils';
import { VacationsService } from 'src/app/services/employee/vacations/vacations.service';
import { FilesService } from 'src/app/services/files/file.service';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmationDialogComponent } from 'src/app/shared-components/dialogs/dialogs';

import { DatatableUtil } from '../../../../classes/datatable';
import { VacationUtilService } from '../vacation-util.service';

@Component({
  selector: 'app-vacation-list',
  templateUrl: './vacation-list.component.html'
})
export class VacationListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: string;
  @Input() title = '';
  items: any[] = [];

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();
  isFilter = false;
  SelectedItem: any = null;
  totalDuration = '';
  totalDurationDays = 0;
  constructor(
    private vacationHttpService: VacationsService,
    public vacationUtilService: VacationUtilService,
    private Activeroute: ActivatedRoute,
    public modal: ModalService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.vacationUtilService.getSubmissionEvent().subscribe(() => {
      this.findAll();
    });
  }

  async ngAfterViewInit() {
    this.vacationUtilService.userId =
      await this.Activeroute.parent.parent.snapshot.params['userId'];
    this.dt.setDtElement(this.dtElement);
    this.findAll();
    this.dt.trigger();
  }

  findAll() {
    if (this.isFilter) {
      this.filter();
      return;
    }
    this.vacationHttpService
      .findAll(
        this.vacationUtilService.userId,
        this.type,
        this.vacationUtilService.isCurrentDate
      )
      .subscribe(response => {
        this.items = response;
        this.dt.reload();
        this.findTotalDurations();
        this.vacationUtilService.changeViewEvent('list');
      });
  }

  onFilterChange(event: boolean) {
    this.isFilter = event;
    this.findAll();
  }

  filter() {
    const filterDates = {
      fromDate: this.vacationUtilService.filterDates.fromDate,
      toDate: this.vacationUtilService.filterDates.toDate,
      isCurrentDate: this.vacationUtilService.isCurrentDate
    };

    this.vacationHttpService
      .filter(this.vacationUtilService.userId, this.type, filterDates)
      .subscribe(response => {
        this.items = response;
        this.dt.reload();
        this.findTotalDurations();
        this.vacationUtilService.changeViewEvent('list');
      });
  }

  formatDate(date: string) {
    return DateUtil.formatDate(date);
  }

  findDuration(item: any) {
    return DateUtil.durationYMD(item.fromDate, item.toDate);
  }
  findActualDuration(duration: number) {
    return DateUtil.formatTotalDuration(duration);
  }

  findTotalDurations() {
    this.totalDurationDays = 0;
    this.items.forEach((item: any) => {
      this.totalDurationDays += item.duration;
    });
    this.totalDuration = DateUtil.formatTotalDuration(this.totalDurationDays);
  }

  newOrEdit(view: string) {
    if (view == 'edit') {
      this.vacationUtilService.SelectedVacation = this.SelectedItem;
    }
    this.vacationUtilService.changeViewEvent(view);
  }

  delet() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      position: {
        top: '65px'
      },
      data: {
        title: ` حذف ألأجازه `,
        warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات ألأجازة بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
        confirmationMessage: 'هل أنت متأكد من أتمام عملية ألحذف ؟',
        yesNo: { yes: 'نعم', no: 'كلا' }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.vacationHttpService.remove(this.SelectedItem._id).subscribe(() => {
          this.findAll();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.vacationUtilService.getSubmissionEvent().subscribe();
    this.dt.unsubscribe();
  }
}

@Component({
  selector: 'app-vacation-form',
  templateUrl: './vacaation-form.component.html'
})
export class VacationFormComponent implements OnInit {
  @Input() Item: any;
  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();

  Util: DateUtil = new DateUtil();
  totalDuration = '';
  isLoading = false;

  constructor(
    private vacationHttpService: VacationsService,
    public vacationUtilService: VacationUtilService,
    private cdRef: ChangeDetectorRef,
    private fileService: FilesService
  ) {}

  ngOnInit(): void {
    this.Item.fromDate = new Date(this.Item.fromDate);
    this.Item.toDate = new Date(this.Item.toDate);
    if (this.Item.type != 'أعتياديه قصيره') {
      this.Item.BooknumberDate = new Date(this.Item.BooknumberDate);
      this.Item.fromBooknumberDate = new Date(this.Item.fromBooknumberDate);
      this.Item.toBooknumberDate = new Date(this.Item.toBooknumberDate);
    }
    this.findDuration();
    this.getVacationFiles();
  }

  findDuration() {
    this.totalDuration = DateUtil.durationYMD(
      this.Item.fromDate,
      this.Item.toDate
    );
    this.Item.duration = DateUtil.dateDifInDays(
      this.Item.fromDate,
      this.Item.toDate
    );
  }

  submit() {
    this.isLoading = true;
    this.Item.duration = DateUtil.dateDifInDays(
      this.Item.fromDate,
      this.Item.toDate
    );
    if (this.Item._id) {
      this.vacationHttpService
        .update(this.Item._id, this.Item.toFormData())
        .subscribe({
          next: () => {
            this.vacationUtilService.trigerSubmissionEvent();
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    } else {
      this.vacationHttpService
        .create(this.vacationUtilService.userId, this.Item.toFormData())
        .subscribe({
          next: () => {
            this.vacationUtilService.trigerSubmissionEvent();
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }

  removeFile(vacationId: string, fileId: string) {
    this.vacationHttpService.removeFile(vacationId, { fileId }).subscribe();
  }

  onDaysChange() {
    this.Item.toDate = new Date(
      this.Item.fromDate.getTime() + this.Item.duration * 24 * 60 * 60 * 1000
    );
    this.cdRef.detectChanges();
  }

  onCountryChange(country: string) {
    this.Item.studyCountry = country;
    if (country == 'ألعراق') {
      this.Item.studyType = 'داخل ألقطر';
    } else {
      this.Item.studyType = this.vacationUtilService.studyTypes[0];
    }
  }

  getVacationFiles() {
    if (this.Item._id) {
      this.fileService.getOwnerFiles(this.Item._id).subscribe(response => {
        this.Item.files = response;
      });
    }
  }
}
