import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';
@Injectable({
  providedIn: 'root'
})
export class RoleGuards implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    if (this.authService.isLoggedIn()) {
      if (!expectedRole || this.authService.hasRole(expectedRole)) {
        return true;
      }
      this.router.navigate(['/welcome']);
    }
    this.router.navigate(['/login']);
    return false;
  }
}
