<div *ngIf="Item === null">
  <div class="card">
    <div class="card-header">
      <h4 class="pr-1"><i class="fa fa-star px-2"></i> أضافة لقب جديد</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button
          class="btn btn-print"
          title="رجوع ألى ألقائمه"
          (click)="viewChange.emit('list')">
          <i class="fa fa-share fa-lg"></i>
        </button>
      </div>
    </div>
    <div
      class="card-body bg-white"
      cdkDropList
      style="min-height: 400px; background-image: none"
      id="container"
      (cdkDropListDropped)="drop($event)">
      <div class="middle h-100"></div>
    </div>
  </div>
</div>
<div class="card" [id]="Item.type" *ngIf="Item !== null">
  <div class="card-header">
    <h4 class="pr-1"><i class="fa fa-star px-2"></i> {{ Item.type }}</h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button
        class="btn btn-print"
        (click)="submit()"
        title="حفظ أللقب ألعلمي ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <app-datepicker
          [input]="Item.promotionDate"
          [name]="'fromDate'"
          [label]="'تاريخ ألحصول على أللقب  '"
          (dateChange)="Item.promotionDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <app-datepicker
          [input]="Item.documentDate"
          [name]="'documentDate'"
          [label]="'تاريخ ألأمر ألأداري '"
          (dateChange)="Item.documentDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <label for="documentNumber" class="form-label">
          رقم ألأمر ألأداري :</label
        >
        <div class="input-group">
          <input
            type="text"
            id="documentNumber"
            name="documentNumber"
            #documentnumber="ngModel"
            class="form-control text-center"
            [(ngModel)]="Item.documentNumber" />
          <div class="input-group-prepend">
            <span class="input-group-text font-bold"> # </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label for="generalSpecialization" class="form-label"
          >ألتخصص ألعام :</label
        >
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            name="generalSpecialization"
            id="generalSpecialization"
            placeholder=""
            [(ngModel)]="Item.generalSpecialization"
            required
            #generalSpecialization="ngModel"
            [ngClass]="{
              'is-invalid': generalSpecialization.errors && isSubmitted
            }" />
        </div>
      </div>
      <div class="col-md-4">
        <label for="fieldOfSpecialization" class="form-label"
          >ألتخصص ألدقيق :</label
        >
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            name="fieldOfSpecialization"
            id="fieldOfSpecialization"
            placeholder=""
            [(ngModel)]="Item.fieldOfSpecialization"
            required
            #fieldOfSpecialization="ngModel"
            [ngClass]="{
              'is-invalid': fieldOfSpecialization.errors && isSubmitted
            }" />
        </div>
      </div>
      <div class="col-md-4">
        <label for="county" class="form-label">سبب ألترقية :</label>
        <select
          class="custom-select shadow-none"
          id="reason"
          name="reason"
          [(ngModel)]="Item.promotionReason"
          required>
          <option *ngFor="let item of reasons; let i = index" [value]="item">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
          <mat-label class="w-50 px-3">ألملاحضات </mat-label>
          <textarea
            matInput
            placeholder=""
            name="notes"
            rows="3"
            class="py-0"
            [(ngModel)]="Item.notes"></textarea>
          <mat-icon matSuffix class="text-secondary">comment</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <app-files
          [Files]="Item.Files"
          [uploadedFiles]="Item.files"
          (fileDelete)="removeFile(Item._id, $event)"
          [header]="'ألأوامر ألأدارية ألخاصة بالترقية'">
        </app-files>
      </div>
    </div>
  </div>
</div>
