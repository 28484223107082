<form>
  <div class="row">
    <div class="col-md-4">
      <app-selectpicker
        [values]="countries"
        [selected]="Item.country"
        [label]="'بلد ألدراسة'"
        (valueChange)="onCountryChange($event)">
      </app-selectpicker>
    </div>
    <div class="col-md-4" *ngIf="isContryIraq()">
      <app-selectpicker
        [values]="provinces"
        [selected]="Item.province"
        [label]="'ألمحافظة'"
        (valueChange)="onProvinceChange($event)">
      </app-selectpicker>
    </div>
    <div class="col-md-4" *ngIf="isContryIraq()">
      <label for="county" class="form-label">ألمدينة :</label>
      <select
        class="custom-select shadow-none"
        id="county"
        name="county"
        [(ngModel)]="Item.county"
        required>
        <option *ngFor="let Item of cities; let i = index" [value]="Item">
          {{ Item }}
        </option>
      </select>
    </div>
    <div class="col-md-4" *ngIf="!isContryIraq()">
      <label for="province" class="form-label">ألمحافظة أو ألولاية :</label>
      <input
        type="text"
        class="form-control"
        name="province"
        id="province"
        placeholder=""
        [(ngModel)]="Item.province"
        required
        #province="ngModel"
        [ngClass]="{ 'is-invalid': province.errors }" />
    </div>
    <div class="col-md-4" *ngIf="!isContryIraq()">
      <label for="county" class="form-label">ألمدينة :</label>
      <input
        type="text"
        class="form-control"
        name="county"
        id="county"
        placeholder=""
        [(ngModel)]="Item.county"
        required
        #county="ngModel"
        [ngClass]="{ 'is-invalid': county.errors }" />
    </div>
  </div>
</form>
