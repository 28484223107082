<div class="card" *ngIf="view === 'list'">
  <div class="card-header">
    <h4>
      <i class="fa fa-male fa-lg pr-2"></i>
      <i class="fa fa-female fa-lg pl-2"></i>
      الحالة ألزوجية
    </h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button
        class="btn btn-print"
        *ngIf="utilService.EmployeeFamilyInfo._id && !isSingle"
        (click)="newOrEdit('new')"
        title="أضافة {{ utilService.isMale ? 'ألزوجة' : 'ألزوج' }}">
        <i class="fa fa-plus-circle fa-2x"></i>
      </button>
      <button
        type="button"
        (click)="submit()"
        class="btn btn-print"
        title="حفظ ألمعلومات ">
        <i class="fa fa-save fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body pb-0 px-0">
    <form #frm="ngForm">
      <div class="row px-3">
        <div class="col-md-4 pt-1">
          <div class="">
            <label class="form-label">ألحالة ألزوجيه </label>
            <span class="float-none float-md-left pr-2 font-bold">:</span>
          </div>
        </div>
        <div class="col-md-4">
          <select
            #mySelect
            id="childMaritialStatus"
            class="custom-select form-control"
            [(ngModel)]="utilService.EmployeeFamilyInfo.maritialStatus"
            name="childMAritialStatus"
            required>
            <option
              *ngFor="let item of utilService.MaritialStatuses; let i = index"
              [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </form>

    <div *ngIf="utilService.EmployeeFamilyInfo.spouses.length !== 0">
      <table
        class="table table-bordered table-striped border-0 my-0 border-bottom-0">
        <thead>
          <tr>
            <th class="d-none d-md-table-cell">#</th>
            <th>أسم {{ utilService.isMale ? 'ألزوجة' : 'ألزوج' }} ألكامل</th>
            <th class="d-none d-md-table-cell">تاريخ ألزواج</th>
            <th class="text-center">
              {{ utilService.isMale ? 'متعينه' : 'متعين' }} ؟
            </th>
            <th class="text-center" style="width: 15%">
              <i class="fa fa-ellipsis-v"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of utilService.EmployeeFamilyInfo.spouses;
              let i = index
            ">
            <th class="d-none d-md-table-cell">{{ i + 1 }}</th>
            <td class="pr-3">{{ item.spouseName }}</td>
            <td class="pr-3 d-none d-md-table-cell">
              {{ item.marriageDate?.toString() | formatDate }}
            </td>
            <td class="text-center">
              <i
                *ngIf="item.isSpouseEmployeed === true"
                class="fa fa-check fa-lg text-success"></i>
              <i
                *ngIf="item.isSpouseEmployeed === false"
                class="fa fa-times fa-lg text-danger"></i>
            </td>
            <th class="text-center">
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  <i class="fa fa-ellipsis-v text-secondary"></i>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('edit')">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل ألمعلومات
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="this.SelectedItem = item; this.delete()">
                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2"></i>
                    حذف {{ utilService.isMale ? 'ألزوجة' : 'ألزوج' }}
                  </li>
                </ul>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-spouse-form
  *ngIf="view !== 'list'"
  [Item]="this.SelectedItem"
  [isMale]="utilService.isMale"
  [InfoId]="utilService.EmployeeFamilyInfo._id"
  (viewChange)="this.utilService.getFamilyInfo(); this.view = 'list'">
</app-spouse-form>
