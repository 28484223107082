import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StructureService } from 'src/app/services/system-manager/structure-http-service';
@Component({
  selector: 'app-structure-dialog',
  templateUrl: './templates/structure-dialog.html'
})
export class StructureDialogComponent implements OnInit {
  treeData: any[] = [];
  searchTerm = '';
  constructor(
    public dialogRef: MatDialogRef<StructureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected structureService: StructureService
  ) {}
  ngOnInit(): void {
    if (this.data.id) {
      this.structureService
        .findAll({ _id: this.data.id })
        .subscribe(response => {
          this.treeData = response;
        });
    } else {
      this.structureService.findAll({ parent: null }).subscribe(response => {
        this.treeData = response;
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-pre-structure-dialog',
  templateUrl: './templates/structure-dialog.html'
})
export class PreloadedStructureDialogComponent implements OnInit {
  treeData: any[] = [];
  searchTerm = '';
  constructor(
    public dialogRef: MatDialogRef<StructureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected structureService: StructureService
  ) {}
  ngOnInit(): void {
    this.treeData = this.data.treeData;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './templates/confirmation-dialog.html'
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-no-data-message',
  templateUrl: './templates/nodata.html'
})
export class NoDataComponent {
  @Input() message = '';
}
