import { SafeUrl } from '@angular/platform-browser';
export class Files {
  Filelist: Array<File> = [];
  addFile() {
    const file: File = new File([''], '', { type: '' });
    this.Filelist.push(file);
  }
  removeFile(i: number) {
    this.Filelist.splice(i, 1);
  }
  isValid(): boolean {
    for (const file of this.Filelist) {
      if (file.name.length == 0) return false;
    }
    return true;
  }
  hasFiles() {
    return this.Filelist.length > 0;
  }
  isValidFile(file: File) {
    return file.name != '';
  }
  appendFilesToForm(data: FormData) {
    this.Filelist.forEach(file => {
      if (this.isValidFile(file)) {
        data.append(
          encodeURIComponent(file.name),
          file,
          encodeURIComponent(file.name)
        );
      }
    });
  }
}

export class DatabaseFile {
  filename = 'أمر أداري';
  filetype = 'pdf';
  size = '3.2';
}

export class ImageFile {
  file: File = new File([''], '', { type: '' });
  url: SafeUrl = null;
}

export interface ImageDimension {
  width: string;
  height: string;
}
