import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
export const dtOptions = {
  language: {
    search: 'بحث: ',
    lengthMenu: 'عرض _MENU_ سجلات',
    info: ' عرض السجلات من <b>_START_ </b> الى <b>_END_</b> من اصل<b> _TOTAL_</b> سجل',
    infoEmpty: '',
    infoFiltered: '(فرز من <b>_MAX_</b> سجل)',
    paginate: {
      first: 'الاولى',
      previous: 'السابق',
      next: 'التالي',
      last: 'الاخيرة'
    },
    emptyTable: 'لم يتم العثور على أي بيانات في ألجدول !'
  },
  fixedColumns: true,
  stateSave: true,
  dom: 'lBfrtip',
  buttons: ['copy', 'excel', 'pdf']
};

export class DatatableUtil {
  dtElement: DataTableDirective;
  dtTrigger = new Subject<string>();
  dtOptions: DataTables.Settings = {};
  constructor() {
    this.dtOptions = dtOptions;
  }
  setDtElement(dtElement: DataTableDirective) {
    this.dtElement = dtElement;
  }
  reload(): void {
    if ('dtInstance' in this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next('');
      });
    }
  }
  trigger(): void {
    this.dtTrigger.next('');
  }
  unsubscribe(): void {
    this.dtTrigger.unsubscribe();
  }
}
