<div class="card" [ngClass]="{ 'd-none': view !== 'list' }">
  <div class="card-header">
    <h4><i class="fa fa-users"></i> موظفين {{ node.name }}</h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button
        class="btn btn-print"
        (click)="newOrEdit('new')"
        title="أضافة موظف">
        <i class="fa fa-user-plus fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-block px-0">
    <table
      id="timeoff"
      datatable
      [dtOptions]="this.dt.dtOptions"
      [dtTrigger]="this.dt.dtTrigger"
      class="table table-bordered table-striped border-0">
      <thead>
        <tr>
          <th>#</th>
          <th class="width-40">ألاسم</th>
          <th class="width-40">ألكلية أو ألمركز</th>
          <th class="text-center">
            <i class="fa fa-ellipsis-v"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of users; let i = index">
          <th>{{ i + 1 }}</th>
          <td class="pr-3">
            <div style="display: flex; flex-direction: column; gap: 4px">
              <span>{{ item.fullname }}</span>
              <span>{{ item.email }}</span>
            </div>
          </td>
          <td class="pr-3">{{ item.workplace.name }}</td>
          <th class="text-center">
            <div class="dropdown">
              <button
                class="btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <i class="fa fa-ellipsis-v text-secondary"></i>
              </button>
              <ul
                class="dropdown-menu bg-creamy"
                aria-labelledby="dropdownMenuButton">
                <li
                  class="dropdown-item"
                  (click)="newOrEdit('edit'); SelectedUser = item">
                  <i class="fa fa-user-edit pl-2 pr-0 text-secondary"></i>
                  تعديل بيانات ألمستخدم
                </li>
                <li
                  class="dropdown-item"
                  (click)="newOrEdit('password'); SelectedUser = item">
                  <i class="fa fa-key px-1 pl-2 text-secondary"></i>
                  تغيير كلمة ألمرور
                </li>
                <li
                  class="dropdown-item"
                  (click)="SelectedUser = item; changeManager()">
                  <i class="fa fa-user-tie px-1 pl-2 text-secondary"></i>
                  تعيين كــ مدير أداري
                </li>
                <li
                  class="dropdown-item"
                  (click)="SelectedUser = item; removeUser()">
                  <i class="fa fa-trash text-danger fa-lg px-1 pl-2"></i>
                  حذف {{ item.type }}
                </li>
              </ul>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-user-form
  *ngIf="view === 'new'"
  [entityId]="node._id"
  (viewChange)="this.findEmployees()">
</app-user-form>
<app-user-form
  *ngIf="view === 'edit' || view === 'password'"
  [entityId]="node._id"
  [userId]="SelectedUser._id"
  [action]="this.view"
  (viewChange)="this.findEmployees()">
</app-user-form>
