import { Injectable } from '@angular/core';
import { ItemNode } from 'src/app/models/system/structure';

@Injectable({
  providedIn: 'root'
})
export class WorkplaceUtilService {
  getFullPath(workplace: any) {
    if (!workplace) return 'غير منتسب الى جهة';
    if (workplace?.ancestors?.length == 0) return workplace.name;
    let path = '';
    for (let i = 0; i < workplace?.ancestors?.length; i++) {
      path += workplace.ancestors[i]?.name + ' - ';
    }
    return path + workplace.name;
  }

  getMainPath(workplace: any) {
    if (workplace?.ancestors?.length == 0) return workplace.name;
    else if (workplace?.ancestors?.length == 1)
      return workplace.ancestors[0].name + ' - ' + workplace.name;
  }

  getCollegePath(workplace: any) {
    let path = '';
    for (let i = 1; i < workplace?.ancestors?.length; i++) {
      path += workplace.ancestors[i].name + ' - ';
    }
    return path + workplace.name;
  }

  getFullnameMainPath(fullname: string) {
    const parts = fullname.split('-').map(part => part.trim());
    let mainpath: string;
    if (parts.length <= 2) {
      mainpath = parts.join(' - ');
    } else {
      mainpath = parts.slice(0, 2).join(' - ');
    }
    return mainpath;
  }

  getFullnameSecondaryPath(fullname: string) {
    const parts = fullname.split('-').map(part => part.trim());
    let secondarypath: string;
    if (parts.length <= 2) {
      secondarypath = parts.join(' - ');
    } else {
      secondarypath = parts.slice(2).join(' - ');
    }
    return secondarypath;
  }

  getBranchesList(workplace: any) {
    const list: string[] = [];
    for (const ancestor of workplace.ancestors) {
      list.push(ancestor.name);
    }
    list.push(workplace.name);
    return list;
  }

  convertToTree(array: string[]): any {
    if (array.length == 0) return [];

    const root: ItemNode = new ItemNode(array[0], '', []);
    let currentNode: ItemNode = root;
    for (let i = 1; i < array.length; i++) {
      const newNode: ItemNode = new ItemNode(array[i], '', []);
      currentNode.children.push(newNode);
      currentNode = newNode;
    }
    const list: ItemNode[] = [];
    list.push(root);
    return list;
  }
}
