<form
  #frm="ngForm"
  class="needs-validation"
  (ngSubmit)="employmentUtileService.submit()">
  <div class="card">
    <div class="card-header">
      <h4>
        <i
          class="fa fa-toggle-on text-success pl-2"
          *ngIf="
            employmentUtileService.EmploymentInfo.employeeStatus === 'مستمر'
          ">
        </i>
        <i
          class="fa fa-toggle-on text-secondary fa-rotate-180 pl-2"
          *ngIf="
            employmentUtileService.EmploymentInfo.employeeStatus !== 'مستمر'
          ">
        </i>
        حالة ألموظف
      </h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button type="submit" class="btn btn-print" title="حفظ ألمعلومات ">
          <i class="fa fa-save fa-lg"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <label for="employmentStatuses"> حالة ألموظف</label>
          <select
            #mySelect
            class="custom-select form-control"
            [(ngModel)]="employmentUtileService.EmploymentInfo.employeeStatus"
            name="county"
            [value]="employmentUtileService.EmploymentInfo.employeeStatus"
            required>
            <option
              *ngFor="let item of employmentStatuses; let i = index"
              [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div
          class="col-md-4"
          *ngIf="
            employmentUtileService.EmploymentInfo.employeeStatus !== 'مستمر'
          ">
          <app-datepicker
            [input]="employmentUtileService.EmploymentInfo.terminationDate"
            [name]="'terminationDate'"
            [label]="'تاريخ أنهاء الخدمة'"
            (dateChange)="
              employmentUtileService.EmploymentInfo.terminationDate = $event
            ">
          </app-datepicker>
        </div>
        <div
          class="col-md-4"
          *ngIf="
            employmentUtileService.EmploymentInfo.employeeStatus !== 'مستمر'
          ">
          <label for="terminationBooknumber" class="form-label">
            رقم ألامر ألأداري بأنهاء ألخدمه:</label
          >
          <div class="input-group">
            <input
              type="text"
              id="terminationBooknumber"
              name="terminationBooknumber"
              #terminationBooknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="
                employmentUtileService.EmploymentInfo.terminationBooknumber
              " />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-files
            [Files]="employmentUtileService.EmploymentInfo.TerminationFiles"
            [uploadedFiles]="
              employmentUtileService.EmploymentInfo.terminationfiles
            "
            (fileDelete)="
              employmentUtileService.removeFile($event, 'terminationfiles')
            "
            [header]="'ألاوامر ألادارية ألخاصة بالتقاعد او أنهاء ألخدمة '">
          </app-files>
        </div>
      </div>
    </div>
  </div>
</form>
