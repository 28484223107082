import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TimeUtil } from 'src/app/classes/Utils';
import { TimeOff } from 'src/app/models/hr/employee/vacations';
import { TimeOffService } from 'src/app/services/employee/vacations/time-off.service';
import { ModalService } from 'src/app/services/modal.service';

import { DatatableUtil } from '../../../../classes/datatable';
import { VacationUtilService } from '../vacation-util.service';

@Component({
  selector: 'app-time-off',
  templateUrl: './time-off.component.html'
})
export class TimeOffComponent implements AfterViewInit {
  public userId: string;
  view = '';
  timeOff: TimeOff = new TimeOff();
  SelectedItem: any;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();
  items: any[] = [];
  totalDurationHours: string;
  totalDurationDays = 0;
  isFilter = false;
  constructor(
    private timeOffService: TimeOffService,
    private Activeroute: ActivatedRoute,
    public vacationUtilService: VacationUtilService,
    public modal: ModalService
  ) {}

  async ngAfterViewInit() {
    this.userId =
      await this.Activeroute.parent.parent.snapshot.params['userId'];
    this.dt.setDtElement(this.dtElement);
    this.findAll();
    this.dt.trigger();
  }
  findAll() {
    if (this.isFilter == true) {
      this.filter();
      return;
    }
    this.timeOffService.findAll(this.userId).subscribe(response => {
      this.items = response;
      this.dt.reload();
      this.view = 'list';
      this.findTotalDurations();
    });
  }

  onFilterChange(event: boolean) {
    this.isFilter = event;
    this.findAll();
  }

  filter() {
    this.timeOffService
      .filter(this.userId, this.vacationUtilService.filterDates)
      .subscribe(response => {
        this.items = response;
        this.dt.reload();
        this.view = 'list';
        this.findTotalDurations();
      });
  }

  newOrEdit(view: string) {
    this.timeOff = new TimeOff();
    this.view = view;
    if (view == 'edit') {
      this.timeOff = Object.assign(this.timeOff, this.SelectedItem);
    }
    this.view = view;
  }

  delet() {
    // this.modal.hide();
    this.timeOffService.remove(this.SelectedItem._id).subscribe(() => {
      this.findAll();
    });
  }

  findTotalDurations() {
    let total = 0;
    this.items.forEach((timeOff: TimeOff) => {
      total += timeOff.duration;
    });
    this.totalDurationHours = TimeUtil.formatTime(total);
    this.totalDurationDays = TimeUtil.timeDurationToDays(total);
  }

  formatTime(duration: number) {
    return TimeUtil.formatTime(duration);
  }
  formatDate(date: string) {
    return TimeUtil.format(date);
  }
}

@Component({
  selector: 'app-time-off-form',
  templateUrl: './time-off.form.html'
})
export class TimeOffFormComponent implements OnInit {
  @Input() userId: string;
  @Input() Item = new TimeOff();
  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private timeOffService: TimeOffService) {}

  ngOnInit(): void {
    this.Item.date = new Date(this.Item.date);
  }

  submit() {
    this.Item.duration = TimeUtil.timeDuration(this.Item.from, this.Item.to);
    if (this.Item._id) {
      this.timeOffService.update(this.Item._id, this.Item).subscribe(() => {
        this.viewChange.emit('list');
      });
    } else {
      this.timeOffService.create(this.userId, this.Item).subscribe(() => {
        this.viewChange.emit('list');
      });
    }
  }

  fromTimeChange() {
    if (this.Item.from < '08:30') this.Item.from = '08:30';
    if (this.Item.from > '14:00') this.Item.from = '14:00';
    if (this.Item.from > this.Item.to) this.Item.to = this.Item.from;
  }
  toTimeChange() {
    if (this.Item.to < '08:30') this.Item.to = '08:30';
    if (this.Item.to > '14:00') this.Item.to = '14:00';
    if (this.Item.from > this.Item.to) this.Item.to = this.Item.from;
  }
}
