import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DataTablesModule } from 'angular-datatables';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgxPrintModule } from 'ngx-print';

import { LocalEditorModule } from '../editor/editor.module';
import { MaterialModule } from '../material';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { WorkplaceEmployeesComponent } from './data/workplace-employees/workplace-employees.component';
import {
  AdministrativePositionsComponent,
  MohPositionComponent,
  OtherPositionComponent,
  PositionFormComponent,
  PositionListComponent,
  UniversityPositionComponent
} from './employee/administrative-positions/administrative-positions.component';
import {
  AwardsComponent,
  AwardsFormComponent
} from './employee/awards-punishments/awards/awards.component';
import { AwardsPunishmentsComponent } from './employee/awards-punishments/awards-punishments.component';
import {
  PunishmentsComponent,
  PunishmentsFormComponent
} from './employee/awards-punishments/punishments/punishments.component';
import {
  BacicAddressInfoComponent,
  BacicContactInfoComponent,
  BacicInfoComponent,
  BacicNameInfoComponent,
  BacicPhotoComponent
} from './employee/bacic-info/bacic-info.component';
import { BonusesUpgradesComponent } from './employee/bonuses-upgrades/bonuses-upgrades.component';
import { UpgradeSummaryComponent } from './employee/bonuses-upgrades/upgrade-summary/upgrade-summary.component';
import {
  BonusFormComponent,
  BonusListComponent,
  UpgradeFormComponent,
  UpgradesTableComponent
} from './employee/bonuses-upgrades/upgrades/upgrades.component';
import {
  DegreeFormComponent,
  DegreeInfoComponent,
  DiplomaDegreeComponent,
  ElementryDegreeComponent,
  GraduateDegreeComponent,
  HighschoolDegreeComponent,
  IntermediateDegreeComponent,
  NoDegreeComponent,
  UndergraduateDegreeComponent
} from './employee/certificate-info/degrees-components';
import { LocationInfoComponent } from './employee/certificate-info/shared/location-info/location-info.component';
import {
  ClassesComponent,
  CommitesComponent,
  EmployeeActivitiesComponent,
  PapersComponent,
  WorkshopsComponent
} from './employee/employee-activities/employee-activities.component';
import {
  CurrentWorkplacesComponent,
  EmployeeWorkplacesComponent,
  MohWorkplacesComponent,
  OtherWorkplacesComponent,
  TemporaryWorkplacesComponent,
  UniversityWorkplacesComponent,
  WorkplaceFormComponent,
  WorkplaceListComponent
} from './employee/employee-workplaces/employee-workplaces.component';
import {
  EmploymentBasicComponent,
  EmploymentInfoComponent,
  EmploymentNotesComponent,
  EmploymentStatusComponent
} from './employee/employment-info/employment-info.component';
import {
  FacultyPromotionsComponent,
  PromotionFormComponent
} from './employee/faculty-promotions/faculty-promotions.component';
import {
  ChildFormComponent,
  ChildrenInfoComponent,
  FamilyInfoComponent,
  MaritialInfoComponent,
  SpouseFormComponent
} from './employee/family-info/family-info.component';
import { MyinfoComponent } from './employee/myinfo/myinfo.component';
import {
  AddressCardComponent,
  CertificateOfNationalityComponent,
  CivilIdComponent,
  NationalIdComponent,
  OfficialDocumentsComponent,
  OfficialDocumentsFormComponent,
  PassportComponent,
  RationCardComponent,
  UniversityIdComponent
} from './employee/official-documents/official-documents.component';
import {
  MotherhoodVacationFormComponent,
  MotherhoodVacationsComponent
} from './employee/vacations/motherhood-vacation/motherhoode-vacation';
import {
  TimeOffComponent,
  TimeOffFormComponent
} from './employee/vacations/time-off/time-off.component';
import {
  VacationFormComponent,
  VacationListComponent
} from './employee/vacations/vacation-forms/vacation-forms.component';
import { VacationSumarryComponent } from './employee/vacations/vacation-sumarry/vacation-sumarry.component';
import {
  AbsenseVacationComponent,
  CareVacationComponent,
  CompanionshipVacationComponent,
  DisabilityVacationComponent,
  FiveYearsVacationsComponent,
  NormalLongVacationsComponent,
  NormalShortVacationsComponent,
  SickLeaveVacationsComponent,
  StudyVacationsComponent,
  UnpaidVacationsComponent,
  VacationsComponent
} from './employee/vacations/vacations.component';
import { EmployeesComponent } from './hr-admin/employees/employees.component';
import { HrRoutingModule } from './hr-routing.module';
@NgModule({
  declarations: [
    MyinfoComponent,
    BacicInfoComponent,
    BacicAddressInfoComponent,
    BacicNameInfoComponent,
    BacicContactInfoComponent,
    BacicPhotoComponent,
    /***** Components of Official Degrees **************/
    DegreeInfoComponent,
    NoDegreeComponent,
    ElementryDegreeComponent,
    IntermediateDegreeComponent,
    HighschoolDegreeComponent,
    UndergraduateDegreeComponent,
    GraduateDegreeComponent,
    DiplomaDegreeComponent,
    DegreeFormComponent,

    /***** Components of Official Documents **************/
    OfficialDocumentsComponent,
    NationalIdComponent,
    CivilIdComponent,
    AddressCardComponent,
    RationCardComponent,
    CertificateOfNationalityComponent,
    PassportComponent,
    UniversityIdComponent,
    OfficialDocumentsFormComponent,

    /******* Employment Info components *********** */
    EmploymentInfoComponent,
    EmploymentBasicComponent,
    EmploymentNotesComponent,
    EmploymentStatusComponent,

    /**** Vacations Components      *******/
    VacationsComponent,

    NormalLongVacationsComponent,
    NormalShortVacationsComponent,
    StudyVacationsComponent,

    FiveYearsVacationsComponent,
    UnpaidVacationsComponent,
    SickLeaveVacationsComponent,
    CompanionshipVacationComponent,
    CareVacationComponent,

    MotherhoodVacationsComponent,
    MotherhoodVacationFormComponent,
    AbsenseVacationComponent,
    DisabilityVacationComponent,

    /************ Hr Admin components *****************/
    EmployeesComponent,
    FamilyInfoComponent,
    MaritialInfoComponent,
    ChildrenInfoComponent,
    SpouseFormComponent,
    ChildFormComponent,
    LocationInfoComponent,
    TimeOffComponent,
    TimeOffFormComponent,
    VacationListComponent,
    VacationFormComponent,
    VacationSumarryComponent,
    BonusesUpgradesComponent,
    UpgradesTableComponent,
    UpgradeFormComponent,
    UpgradeSummaryComponent,
    BonusListComponent,
    BonusFormComponent,
    AwardsPunishmentsComponent,
    AwardsComponent,
    PunishmentsComponent,
    AwardsFormComponent,
    PunishmentsFormComponent,

    /************ Workplaces *****************/
    EmployeeWorkplacesComponent,
    UniversityWorkplacesComponent,
    OtherWorkplacesComponent,
    CurrentWorkplacesComponent,
    WorkplaceListComponent,
    WorkplaceFormComponent,
    TemporaryWorkplacesComponent,
    MohWorkplacesComponent,
    AdministrativePositionsComponent,
    PositionListComponent,
    PositionFormComponent,
    UniversityPositionComponent,
    MohPositionComponent,
    OtherPositionComponent,
    FacultyPromotionsComponent,
    PromotionFormComponent,
    EmployeeActivitiesComponent,
    PapersComponent,
    CommitesComponent,
    ClassesComponent,
    WorkshopsComponent,
    WorkplaceEmployeesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MaterialModule,
    DataTablesModule,
    NgxPrintModule,
    HrRoutingModule,
    ModalModule.forRoot(),
    SharedComponentsModule,
    LocalEditorModule
  ],
  providers: [BsModalRef],
  exports: [MyinfoComponent, BacicInfoComponent]
})
export class HrModule {}
