<div class="card">
  <div class="card-header">
    <h4 *ngIf="!Item._id"><i class="fa fa-clock"></i> أضافة أجازة ألزمنية</h4>
    <h4 *ngIf="Item._id"><i class="fa fa-clock"></i> تعديل ألاجازة ألزمنية</h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button
        class="btn btn-print"
        (click)="submit()"
        title="حفظ ألاجازة ألزمنية">
        <i class="fa fa-save fa-lg"></i>
      </button>

      <button
        class="btn btn-print"
        (click)="viewChange.emit('list')"
        title="رجوع ألى ألاجازات ألزمنية">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <app-datepicker
          [input]="Item.date"
          [name]="'toDate'"
          [label]="'تاريخ ألأجازة'"
          (dateChange)="Item.date = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <label for="starttime" class="form-label">من الساعه :</label>
        <input
          type="time"
          class="form-control"
          name="starttime"
          [(ngModel)]="Item.from"
          id="starttime"
          (change)="fromTimeChange()"
          min="09:00"
          max="14:00"
          required />
      </div>
      <div class="col-md-4">
        <label for="endtime" class="form-label">ألى ألساعه :</label>
        <input
          type="time"
          class="form-control"
          name="endtime"
          [(ngModel)]="Item.to"
          id="endtime"
          (change)="toTimeChange()"
          required
          min="09:00"
          max="14:00" />
      </div>
    </div>
  </div>
</div>
