import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConstantsUtils } from 'src/app/classes/constants';
import { ImageFile } from 'src/app/classes/Files';
import { Employee } from 'src/app/models/hr/employee/employee';
import { BasicinfoService } from 'src/app/services/employee/basicinfo.service';
import { UsersService } from 'src/app/services/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class BasicInfoUtilService {
  employee: Employee;
  userId: string;

  provinces: string[] = ConstantsUtils.getProvincesList();
  cities: string[] = [];
  profileImage: ImageFile = new ImageFile();

  isLoading = false;

  constructor(
    public basicInfoService: BasicinfoService,
    private userService: UsersService
  ) {}

  getBasicInfo() {
    this.basicInfoService.findOne(this.userId).subscribe(response => {
      if (response == null) {
        this.employee = new Employee();
        this.onProvinceChange(this.employee.address.province);
      } else {
        Object.assign(this.employee, response);
        this.employee.birthDate = new Date(response.birthDate);
        this.cities = ConstantsUtils.getProvinceCitiesByName(
          response.address.province
        );
      }
    });
  }

  getUserPhoto() {
    this.userService.findOne(this.userId).subscribe(user => {
      if (user && user.photo) {
        this.profileImage.url = this.userService.getUserPhoto(user.photo);
      }
    });
  }

  submit(frm: NgForm) {
    if (frm.valid == false) return;
    this.isLoading = true;
    this.basicInfoService.createOrUpdate(this.userId, this.employee).subscribe({
      next: () => {
        this.getBasicInfo();
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => (this.isLoading = false)
    });
  }

  uploadPhoto() {
    const data = new FormData();
    data.append(
      'photo',
      this.profileImage.file,
      encodeURIComponent(this.profileImage.file.name)
    );
    this.userService.uploadPhoto(this.userId, data).subscribe();
  }

  onProvinceChange(province: any) {
    this.employee.address.province = province;
    this.cities = ConstantsUtils.getProvinceCitiesByName(province);
    this.employee.address.county = this.cities[0];
  }
}
