import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from 'src/app/classes/Utils';
@Pipe({
  name: 'translateDigits'
})
export class TranslateDigitsPipe implements PipeTransform {
  transform(value: any, language: string): unknown {
    if (language === 'ar') {
      const digits = '0123456789';
      const arabicDigits = '٠١٢٣٤٥٦٧٨٩';
      let newValue = '';
      for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i);
        const index = digits.indexOf(char);
        newValue += index !== -1 ? arabicDigits.charAt(index) : char;
      }
      return newValue;
    } else {
      return value;
    }
  }
}
@Pipe({
  name: 'arabicDate'
})
export class ArabicDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translateDigitsPipe: TranslateDigitsPipe
  ) {}

  transform(value: any): any {
    try {
      const date = this.datePipe.transform(value, 'dd-MM-yyyy');
      return this.translateDigitsPipe.transform(date, 'ar');
    } catch (e) {
      return '';
    }
  }
}

@Pipe({
  name: 'formatDate'
})
export class FormatDate implements PipeTransform {
  transform(date: string) {
    try {
      return DateUtil.formatDate(date);
    } catch (e) {
      return date;
    }
  }
}
