<div class="card">
  <div class="card-header d-flex">
    <h4 class="pl-5"><i class="fa fa-university"> </i>جهة ألعمل ألحالية</h4>

    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button
        class="btn btn-print btn-block"
        title="تحديث جهة العمل الحالية "
        (click)="openDialog()">
        <i class="fa fa-university mx-2"></i>
      </button>
      <button
        class="btn btn-print"
        (click)="submitMainWorkplace()"
        title="حفظ ألمعلومات ">
        <i class="fa fa-save fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-8 mb-2">
        <div
          class="border px-3 border-radius-25 bg-white border-success border-success"
          *ngIf="MainWorkplace.fullname.length">
          <app-workplace-tree-view
            [treeData]="
              getWorkplaceTree(MainWorkplace)
            "></app-workplace-tree-view>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="h-100"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          ">
          <div
            class="d-flex justify-content-between border pt-2 pr-3 badge-pill mt-0 mt-sm-1 bg-white border-success">
            <label class="d-block w-50">
              ملاك ألموظف
              <span class="float-left font-weight-bold px-2">:</span>
            </label>
            <div class="d-flex w-50">
              <span>{{ MainWorkplace.employmentType }} </span>
            </div>
          </div>

          <div
            class="d-flex justify-content-between border pt-2 pr-3 badge-pill my-2 bg-white border-success">
            <label class="d-block w-50">
              نوع ألأنتساب
              <span class="float-left font-weight-bold px-2">:</span>
            </label>
            <div class="d-flex w-50">
              <span>{{ MainWorkplace.movementType }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <app-datepicker
          [input]="MainWorkplace.fromDate"
          [name]="'startDate'"
          [label]="'تاريخ ألمباشره'"
          (dateChange)="MainWorkplace.fromDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <app-datepicker
          [input]="MainWorkplace.fromDocumentDate"
          [name]="'fromDocumentDate'"
          [label]="'تاريخ ألأمر ألأداري بالمباشره '"
          (dateChange)="MainWorkplace.fromDocumentDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <label for="fromDocumentNumber" class="form-label">
          رقم ألامر ألأداري باالمباشره:</label
        >
        <div class="input-group">
          <input
            type="text"
            id="fromDocumentNumber"
            name="fromDocumentNumber"
            #fromDocumentNumber="ngModel"
            class="form-control text-center"
            [(ngModel)]="MainWorkplace.fromDocumentNumber" />
          <div class="input-group-prepend">
            <span class="input-group-text font-bold"> # </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
          <mat-label class="px-3">ألملاحضات </mat-label>
          <textarea
            matInput
            placeholder=""
            name="notes"
            rows="3"
            class="py-0"
            [(ngModel)]="MainWorkplace.notes"></textarea>
          <mat-icon matSuffix class="text-secondary">comment</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <app-files
          [Files]="MainWorkplace.Files"
          [uploadedFiles]="MainWorkplace.files"
          (fileDelete)="removeFile(MainWorkplace._id, $event)"
          [header]="'ألأوامر ألأدارية ألخاصة  بالنقل ألمباشره'">
        </app-files>
      </div>
    </div>
  </div>
</div>
