import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpLoadingService } from '../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeePositionService {
  private url = environment.ApiUrl + '/employeepositions/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findAll(userId: string, query: any): Observable<any> {
    return this.http.get(this.url + userId, {
      params: {
        query: JSON.stringify(query)
      }
    });
  }

  create(userId: string, data: any) {
    return this.http.post(this.url + userId, data);
  }

  update(positionId: string, data: any) {
    return this.http.patch(this.url + positionId, data);
  }

  removePosition(positionId: string): Observable<any> {
    return this.http.delete(`${this.url}${positionId}`);
  }

  removePositionFile(positionId: string, data: any) {
    return this.http.patch(this.url + 'removefile/' + positionId, data);
  }
}
