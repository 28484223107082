<div class="card mt-6">
  <div class="card-header">
    <h4><i class="fas fa-edit pl-3"></i> تعديل ألدرجة {{ Item.grade }}</h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألدرجه ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <label for="fullname">ألدرجة ألوظيفية :</label>
        <input
          type="text"
          class="form-control"
          id="fullname"
          name="fullname"
          [(ngModel)]="Item.grade" />
      </div>
      <div class="col-md-4 offset-md-4">
        <label for="minimumDegree" class="form-label">ألشهادة ألدنيا :</label>
        <select
          #mySelect
          class="custom-select form-control"
          id="minimumDegree"
          [(ngModel)]="Item.minimumDegree"
          name="entityType"
          id="entityType"
          required>
          <option *ngFor="let item of degrees; let i = index" [value]="item">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label for="salary"> مقدار ألراتب ألأسمي :</label>
        <input
          type="number"
          class="form-control text-center"
          id="salary"
          name="salary"
          [(ngModel)]="Item.salary" />
      </div>

      <div class="col-md-4 offset-md-4">
        <label for="salary"> عدد ألعلاوات ألسنوية :</label>
        <input
          type="number"
          class="form-control text-center"
          id="numberOfStages"
          name="numberOfStages"
          [(ngModel)]="Item.numberOfStages" />
      </div>
    </div>
  </div>
</div>
