import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataPersistanceService } from 'src/app/services/data-persistence/data-persistance';
import { DgreeService } from 'src/app/services/employee/dgrees.service';
import { StructureService } from 'src/app/services/system-manager/structure-http-service';
import { PreloadedStructureDialogComponent } from 'src/app/shared-components/dialogs/dialogs';

import {
  ElementryDegreeForm,
  GraduateDegreeForm,
  HisghschoolDegreeForm,
  IntermediateDegreeForm,
  NoDegree,
  UndergraduateDegreeForm
} from '../../../models/hr/employee/degrees';
@Injectable({
  providedIn: 'root'
})
export class DegreeUtilService {
  degreeTypes = [
    { title: 'دكتوراه', type: 'دكتوراه', max: 1 },
    { title: 'ماجستير', type: 'ماجستير', max: 1 },
    { title: 'دبلوم عالي', type: 'دبلوم عالي', max: 1 },
    { title: 'بكالوريوس', type: 'بكالوريوس', max: 1 },
    { title: 'دبلوم', type: 'دبلوم', max: 1 },
    { title: 'أعدادية', type: 'أعدادية', max: 1 },
    { title: 'متوسطه', type: 'متوسطه', max: 1 },
    { title: 'أبتدائيه', type: 'أبتدائيه', max: 1 },
    { title: 'بدون شهاده', type: 'أمي', max: 1 }
  ];
  isDegreeFormSubmitted: boolean;

  constructor(
    private degreeHttpSevice: DgreeService,
    private dialog: MatDialog,
    private DataPersistant: DataPersistanceService,
    private structureService: StructureService
  ) {}
  isContryIraq(country: string): boolean {
    return country == 'ألعراق';
  }

  jsonToDegreeObject(degree: any) {
    let degreeObject: any = null;
    switch (degree.type) {
      case 'أمي': {
        degreeObject = Object.assign(new NoDegree(degree.type), degree);
        break;
      }
      case 'يقرأ ويكتب': {
        degreeObject = Object.assign(new NoDegree(degree.type), degree);
        break;
      }
      case 'أبتدائيه': {
        degreeObject = Object.assign(
          new ElementryDegreeForm(degree.type),
          degree
        );
        break;
      }
      case 'متوسطه': {
        degreeObject = Object.assign(
          new IntermediateDegreeForm(degree.type),
          degree
        );
        break;
      }
      case 'أعدادية': {
        degreeObject = Object.assign(
          new HisghschoolDegreeForm(degree.type),
          degree
        );
        break;
      }
      case 'دبلوم': {
        degreeObject = Object.assign(
          new UndergraduateDegreeForm(degree.type),
          degree
        );
        break;
      }
      case 'بكالوريوس': {
        degreeObject = Object.assign(
          new UndergraduateDegreeForm(degree.type),
          degree
        );
        break;
      }
      case 'دبلوم عالي': {
        degreeObject = Object.assign(
          new GraduateDegreeForm(degree.type),
          degree
        );
        break;
      }
      case 'ماجستير': {
        degreeObject = Object.assign(
          new GraduateDegreeForm(degree.type),
          degree
        );
        break;
      }
      case 'دكتوراه': {
        degreeObject = Object.assign(
          new GraduateDegreeForm(degree.type),
          degree
        );
        break;
      }
    }
    return degreeObject;
  }

  createDegreeObject(type: any) {
    let SelectedDegree: any = null;
    switch (type) {
      case 'أمي': {
        SelectedDegree = new NoDegree(type);
        break;
      }
      case 'أبتدائيه': {
        SelectedDegree = new ElementryDegreeForm(type);
        break;
      }
      case 'متوسطه': {
        SelectedDegree = new IntermediateDegreeForm(type);
        break;
      }
      case 'أعدادية': {
        SelectedDegree = new HisghschoolDegreeForm(type);
        break;
      }
      case 'دبلوم': {
        SelectedDegree = new UndergraduateDegreeForm(type);
        break;
      }
      case 'بكالوريوس': {
        SelectedDegree = new UndergraduateDegreeForm(type);
        break;
      }
      case 'دبلوم عالي': {
        SelectedDegree = new GraduateDegreeForm(type);
        break;
      }
      case 'ماجستير': {
        SelectedDegree = new GraduateDegreeForm(type);
        break;
      }
      case 'دكتوراه': {
        SelectedDegree = new GraduateDegreeForm(type);
        break;
      }
    }
    return SelectedDegree;
  }

  removeFile(degreeId: string, fileId: string) {
    this.degreeHttpSevice.removeDegreeFile(degreeId, { fileId }).subscribe();
  }

  async openUniversityDialog(item: any) {
    const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
      width: '61vw',
      position: {
        top: '65px'
      },
      data: {
        title: 'ألجامعات ألعراقية',
        treeData: await this.DataPersistant.getIraqiUniversities()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clearUniversityData(item);
        this.getWorkplace(result._id, item);
      }
    });
  }

  getWorkplace(selectedworkplaceId: string, item: any) {
    this.structureService.findOne(selectedworkplaceId).subscribe(response => {
      const ancestors = response.ancestors;
      item.university = ancestors.length >= 2 ? ancestors[1].name : '';
      item.college = ancestors.length >= 3 ? ancestors[2].name : '';
      item.department = ancestors.length >= 3 ? response.name : '';
    });
  }

  clearUniversityData(item: any) {
    item.university = '';
    item.college = '';
    item.department = '';
  }
}
