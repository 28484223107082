// src/app/components/spinner/spinner.component.ts
import { Component } from '@angular/core';

import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-spinner-v2',
  template: `
    <div *ngIf="isLoading | async" class="spinner-overlay-v2">
      <!-- <div class="spinner-overlay-v2"> -->
      <div class="spinner-v2"></div>
      <div class="progress-v2">
        <div *ngIf="uploadProgress | async as upload" class="progress-bar-v2">
          Upload Progress: {{ upload }}%
        </div>
        <div
          *ngIf="downloadProgress | async as download"
          class="progress-bar-v2">
          Download Progress: {{ download }}%
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
  isLoading = this.spinnerService.spinner$;
  uploadProgress = this.spinnerService.uploadProgress$;
  downloadProgress = this.spinnerService.downloadProgress$;

  constructor(private spinnerService: SpinnerService) {}
}
