<div class="files bg-creamy border border-radius-20">
  <div class="row header mb-0" *ngIf="header">
    <div class="col-md-12 py-1 px-1">
      <h4 class="w-100">
        <a
          [href]="'#' + id"
          data-toggle="collapse"
          aria-expanded="false"
          class="dropdown-toggle">
          <i class="fa fa-university px-2 text-secondary"></i
          ><span class="text-secondary">{{ header + ' :' }}</span>
        </a>
      </h4>
    </div>
  </div>
  <div class="collapse" [id]="id">
    <hr class="my-0 pt-0" />
    <div class="row px-5">
      <app-workplace-tree-view [treeData]="treeData"></app-workplace-tree-view>
    </div>
  </div>
</div>
