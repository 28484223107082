<div class="container">
  <div class="content-container my-7 mx-md-4">
    <div class="card mt-7" [ngClass]="{ 'd-none': view !== 'list' }">
      <div class="card-header">
        <h4><i class="fas fa-users pl-3"></i> {{ role.name }}</h4>
        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
          <button
            class="btn btn-print"
            title="رجوع ألى ألقائمه"
            (click)="viewChange.emit('list')">
            <i class="fa fa-share fa-lg"></i>
          </button>
        </div>
      </div>
      <div class="card-block px-0">
        <table
          id="timeoff"
          datatable
          [dtOptions]="this.dt.dtOptions"
          [dtTrigger]="this.dt.dtTrigger"
          class="table table-bordered table-striped border-0">
          <thead>
            <tr>
              <th>#</th>
              <th class="width-20">ألاسم</th>
              <th class="width-30">ألجهة ألمنتسب أليها</th>
              <th class="width-30">ألجهة ألمسؤول عنها</th>
              <th class="text-center width-10">
                <i class="fa fa-ellipsis-v"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of users; let i = index">
              <th>{{ i + 1 }}</th>
              <td>
                <div style="display: flex; flex-direction: column; gap: 4px">
                  <span>{{ item.userId.fullname }}</span>
                  <span>{{ item.userId.email }}</span>
                </div>
              </td>
              <td
                [title]="
                  workplaceUtilsevice.getFullPath(item.userId.workplace)
                ">
                {{ workplaceUtilsevice.getFullPath(item.userId.workplace) }}
              </td>
              <td [title]="workplaceUtilsevice.getFullPath(item.workplaceId)">
                {{ workplaceUtilsevice.getFullPath(item.workplaceId) }}
              </td>
              <th class="text-center">
                <div class="dropdown">
                  <button
                    class="btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i class="fa fa-ellipsis-v text-secondary"></i>
                  </button>
                  <ul
                    class="dropdown-menu bg-creamy"
                    aria-labelledby="dropdownMenuButton">
                    <li
                      class="dropdown-item"
                      (click)="SelectedUser = item; view = 'user'">
                      <i class="fa fa-user fa-lg pl-2 pr-1 text-secondary"></i>
                      تعديل صلاحيات ألمستخدم
                    </li>
                  </ul>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <app-user-roles
      *ngIf="view === 'user'"
      [userId]="SelectedUser.userId._id"
      (viewChange)="this.findAll()"></app-user-roles>
  </div>
</div>
