import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DataTablesModule } from 'angular-datatables';

import { MaterialModule } from '../material';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { FinancialAllowancesComponent } from './components/financial-allowances/financial-allowances.component';
import {
  MinistryStructureComponent,
  OrganizationEntityFormComponent,
  OrganizationStructuresComponent,
  OrganizationUsersComponent,
  UniversityStructureComponent
} from './organization-chart/organization-chart.component';
import { NodeDatabaseService } from './organization-chart/service';
import { ContractGradesComponent } from './system-grades/contract-grades/contract-grades.component';
import { DailyGradesComponent } from './system-grades/daily-grades/daily-grades.component';
import {
  GradesFormComponent,
  JobTitlesFormComponent,
  PermanentGradesComponent,
  StagesFormComponent
} from './system-grades/permanent-grades/permanent-grades';
import { SystemGradesComponent } from './system-grades/system-grades.component';
import { SystemManagerComponent } from './system-manager/system-manager.component';
import { SystemManagerRoutingModule } from './system-manager-routing.module';
import {
  UserRolesComponent,
  UsersManagmentComponent
} from './system-roles/accounts-managments/accounts-manangement';
import {
  RolesManagementComponent,
  RoleUsersComponent
} from './system-roles/roles-management/roles-management.component';
import { SystemRolesComponent } from './system-roles/system-roles';

@NgModule({
  declarations: [
    SystemManagerComponent,
    OrganizationStructuresComponent,
    OrganizationUsersComponent,
    MinistryStructureComponent,
    UniversityStructureComponent,
    OrganizationEntityFormComponent,

    UniversityStructureComponent,
    SystemGradesComponent,
    GradesFormComponent,
    JobTitlesFormComponent,
    StagesFormComponent,
    RolesManagementComponent,
    UserRolesComponent,
    FinancialAllowancesComponent,
    PermanentGradesComponent,
    ContractGradesComponent,
    DailyGradesComponent,
    SystemRolesComponent,
    RoleUsersComponent,
    UsersManagmentComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedComponentsModule,
    SystemManagerRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule
  ],
  providers: [NodeDatabaseService]
})
export class SystemManagerModule {}
