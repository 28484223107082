import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerSubject = new BehaviorSubject<boolean>(false);
  private uploadProgressSubject = new BehaviorSubject<number>(0);
  private downloadProgressSubject = new BehaviorSubject<number>(0);

  spinner$ = this.spinnerSubject.asObservable();
  uploadProgress$ = this.uploadProgressSubject.asObservable();
  downloadProgress$ = this.downloadProgressSubject.asObservable();

  show() {
    this.spinnerSubject.next(true);
  }

  hide() {
    this.spinnerSubject.next(false);
  }

  setUploadProgress(progress: number) {
    this.uploadProgressSubject.next(progress);
  }

  setDownloadProgress(progress: number) {
    this.downloadProgressSubject.next(progress);
  }
}
