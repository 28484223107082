<app-position-list
  [ngClass]="{ 'd-none': view !== 'list' }"
  [query]="query"
  [title]="title"></app-position-list>

<app-position-form
  *ngIf="view !== 'list'"
  [Item]="position"
  [titles]="titles"
  [type]="title"></app-position-form>
