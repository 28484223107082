<div class="wrapper">
  <div class="contents">
    <div class="container">
      <app-page-header-info title2="ألمعلومات ألعائلية"></app-page-header-info>
      <div class="content-container my-7 mx-md-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="sidebar left-sidebar border-right">
    <ul class="list-unstyled mb-10">
      <li class="border-top-0">
        <a routerLink="maritialinfo" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> ألمعلومات ألزوجيه
        </a>
      </li>
      <li *ngIf="this.utilService.showChildern">
        <a routerLink="childreninfo" routerLinkActive="active">
          <i class="fa fa-list px-4"></i> معلومات ألاطفال
        </a>
      </li>
    </ul>
  </div>
</div>
