export class Employee {
  /*****  Arabic Name Info *******/
  aFirstName = '';
  aFatherName = '';
  aGrandFatherName = '';
  aFourthName = '';
  aSureName = '';

  /*****  English Name Info *******/
  eFirstName = '';
  eFatherName = '';
  eGrandFatherName = '';
  eFourthName = '';
  eSureName = '';

  /*****  Mother Name Info *******/
  motherName = '';
  birthDate: Date = new Date();
  gender = 'ذكر';
  mobile = '';
  email = '';
  address: Address = new Address();

  religion = 'مسلم';
  nationalisim = 'عربي';

  getArabicFullName(): string {
    return (
      this.aFirstName +
      ' ' +
      this.aFatherName +
      '  ' +
      this.aGrandFatherName +
      ' ' +
      this.aFourthName +
      '  ' +
      this.aSureName
    );
  }
  getEnglishFullName(): string {
    return (
      this.eFirstName +
      ' ' +
      this.eFatherName +
      '  ' +
      this.eGrandFatherName +
      ' ' +
      this.eFourthName +
      '  ' +
      this.eSureName
    );
  }
}

export class Address {
  province = 'نينوى';
  county = 'ألموصل';
  city = '';
  quarter = '';
  nearestPlace = '';
  street = '';
  subStreet = '';
  unitNumber = '';
}
