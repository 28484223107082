<div class="card">
  <div class="card-header">
    <h4 *ngIf="!userId"><i class="fa fa-user-plus"> </i> أضافة مستخدم جديد</h4>
    <h4 *ngIf="userId">
      <i class="fa fa-user-edit"> </i> تعديل بيانات ألمستخدم
    </h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألمعلومات ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="userForm">
      <div class="row" *ngIf="action !== 'password'">
        <div class="col-md-5">
          <label for="fullname">ألأسم ألرباعي وأللقب :</label>

          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="fullname"
              formControlName="fullname"
              [(ngModel)]="User.fullname"
              [ngClass]="{ 'is-invalid': isSubmmited && u.fullname.errors }" />
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-user"></i></span>
            </div>
            <div
              *ngIf="isSubmmited && u.fullname.errors"
              class="invalid-feedback">
              <div *ngIf="u.fullname.errors.required">
                أسم ألموظف ألكامل مطلوب
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 offset-md-2">
          <label class="form-label" for="email">ألبريد ألالكتروني :</label>
          <div class="input-group">
            <input
              type="email"
              formControlName="email"
              class="form-control"
              id="email"
              dir="ltr"
              [(ngModel)]="User.email"
              [ngClass]="{ 'is-invalid': isSubmmited && u.email.errors }" />
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa fa-envelope"></i
              ></span>
            </div>
            <div *ngIf="isSubmmited && u.email.errors" class="invalid-feedback">
              <div *ngIf="u.email.errors.required">البريد الالكتروني مطلوب</div>
              <div *ngIf="u.email.errors.email">البريد الالكتروني غير صالح</div>
              <div *ngIf="u.email.errors.emailDomain">
                نطاق ألبريد ألالكتروني غير صحيح . يجب أستخدام uomosul.edu.iq
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form [formGroup]="passwordForm" *ngIf="action !== 'edit'">
      <div class="row">
        <div class="col-md-5 form-group">
          <label for="password" class="form-label"> كلمة ألمرور :</label>
          <div class="input-group">
            <input
              type="text"
              dir="ltr"
              formControlName="password"
              id="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': isSubmmited && f.password.errors }" />
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-lock"></i></span>
            </div>
            <div
              *ngIf="isSubmmited && f.password.errors"
              class="invalid-feedback">
              <div *ngIf="f.password.errors.required">كلمة ألمرور مطلوبه</div>
              <div *ngIf="f.password.errors.minlength">
                كلمة ألمرور يجب ان تكون من ستة حروف على ألأقل
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 offset-md-2 form-group">
          <label for="confirmPassword"> تأكيد كلمة ألمرور :</label>
          <div class="input-group">
            <input
              type="text"
              dir="ltr"
              formControlName="confirmPassword"
              class="form-control"
              id="confirmPassword"
              [ngClass]="{
                'is-invalid': isSubmmited && f.confirmPassword.errors
              }" />
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-lock"></i></span>
            </div>
            <div
              *ngIf="isSubmmited && f.confirmPassword.errors"
              class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">
                تأكيد كلمة ألمرور مطلوبه
              </div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">
                كلمة ألمرور غير متطابقه
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
