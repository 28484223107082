<div class="wrapper">
  <div class="contents">
    <app-page-header-info
      [title2]="'ألمخصصات ألمالية'"
      icon="fa fa-laptop"></app-page-header-info>

    <div class="content-container m-7">
      <div [ngClass]="{ 'd-none': view !== 'list' }">
        <div class="card mt-6">
          <div class="card-header">
            <h4>
              <i class="fas fa-money-bill-alt pl-3"></i> ألمخصصات ألمالية
              {{ this.category ? ' : ' + this.category : '' }}
            </h4>
            <div
              class="toolbar d-flex d-inline-flex w-30 justify-content-between"
              *ngIf="category">
              <button
                class="btn btn-print"
                (click)="newOrEdit('new')"
                title="أضافة دور جديد">
                <i class="fa fa-plus-circle fa-2x"></i>
              </button>
            </div>
          </div>
          <div class="card-block px-0">
            <table
              id="upgradesummary"
              datatable
              [dtOptions]="this.dt.dtOptions"
              [dtTrigger]="this.dt.dtTrigger"
              class="table table-bordered table-striped border-0 my-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th class="w-25">{{ this.category }}</th>
                  <th class="text-center">ألمخصصات (ألنسبة ألمئوية)</th>
                  <th class="text-center" style="width: 15%">
                    <i class="fa fa-ellipsis-v"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of Items; let i = index">
                  <th>{{ i + 1 }}</th>
                  <th class="text-secondary pl-3">
                    {{ item.type }}
                  </th>
                  <td class="pr-3 text-center">
                    {{ item.allowancesPercentage }}
                  </td>
                  <th class="text-center">
                    <div class="dropdown">
                      <button
                        class="btn"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true">
                        <i class="fa fa-ellipsis-v text-secondary"></i>
                      </button>
                      <ul
                        class="dropdown-menu bg-creamy"
                        aria-labelledby="dropdownMenuButton">
                        <li
                          class="dropdown-item"
                          (click)="SelectedItem = item; newOrEdit('edit')">
                          <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                          تعديل {{ this.category }}
                        </li>
                      </ul>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card mt-6" *ngIf="view === 'new' || view === 'edit'">
        <div class="card-header">
          <h4 *ngIf="view === 'new'">
            <i class="fa fa-plus fa-lg"></i>
            {{ this.SelectedItem.category }} : أضافة
            {{ this.category }}
          </h4>
          <h4 *ngIf="view === 'edit'">
            <i class="fa fa-edit fa-lg"></i> تعديل
            {{ this.SelectedItem.category }}
          </h4>
          <div
            class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button class="btn btn-print" (click)="submit()" title="حفظ  ">
              <i class="fa fa-save fa-lg"></i>
            </button>
            <button
              class="btn btn-print"
              title="رجوع ألى ألقائمه"
              (click)="this.view = 'list'">
              <i class="fa fa-share fa-lg"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <label for="type" class="form-label">
                {{ this.category }} :</label
              >
              <input
                type="text"
                class="form-control"
                name="type"
                id="type"
                [(ngModel)]="SelectedItem.type" />
            </div>
            <div class="col-md-4 offset-md-3">
              <label for="allowances" class="form-label">
                ألمخصصات (ألنسبة ألمئوية) :
              </label>
              <input
                type="number"
                dir="ltr"
                lang="en"
                class="form-control text-center"
                name="rolename"
                id="rolename"
                min="0"
                max="200"
                [(ngModel)]="SelectedItem.allowancesPercentage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar left-sidebar border-right">
    <ul class="list-unstyled mb-10">
      <li
        *ngFor="let item of allowancesCategories; let i = index"
        [ngClass]="{ 'border-top-0': i === 0 }">
        <a
          (click)="category = item; this.findByCategory()"
          [ngClass]="{ active: category === item }">
          <i class="fa fa-list text-secondary px-4"></i> {{ item }}
        </a>
      </li>
    </ul>
  </div>
</div>
