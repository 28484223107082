<div class="wrapper">
  <div class="contents">
    <div class="container">
      <app-page-header-info title2="ألنشاطات وألفعاليات"></app-page-header-info>
      <div class="content-container my-7 mx-md-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div
    class="sidebar left-sidebar border-right"
    style="font-size: 12px !important">
    <button class="btn close-sidebar-button border border-radius-25 btn-light">
      <i class="fa fa-times hover-danger text-dark"></i>
    </button>
    <ul class="list-unstyled mb-10">
      <li class="border-top-0">
        <a routerLink="papers" routerLinkActive="active">
          <i class="fa fa-file-alt px-3"></i> ألبحوث ألمنشورة
        </a>
      </li>

      <li>
        <a routerLink="classes" routerLinkActive="active">
          <i class="fa fa-chalkboard-teacher pl-2 pr-3"></i> ألمناهج ألتدريسية
        </a>
      </li>

      <li>
        <a routerLink="commites" routerLinkActive="active">
          <i class="fa fa-user-friends pl-2 pr-3"></i> أللجان ألفنية وألأدارية
        </a>
      </li>
      <li>
        <a routerLink="workshops" routerLinkActive="active">
          <i class="fa fa-file-video px-3"></i> ورش ألعمل
        </a>
      </li>
    </ul>
  </div>
</div>
