import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkplaceViewsHandler {
  public userId: string = null;
  private viewSubject = new Subject<string>();
  private submissionSubject = new Subject();
  public SelectedItem: any = null;

  hasTemporaryWorkkplace = false;

  changeViewEvent(view: string) {
    this.viewSubject.next(view);
  }

  getViewEvent(): Observable<string> {
    return this.viewSubject.asObservable();
  }

  trigerSubmissionEvent() {
    this.submissionSubject.next(true);
  }
  getSubmissionEvent() {
    return this.submissionSubject.asObservable();
  }
}
