<div>
  <h1
    mat-dialog-title
    class="cairo text-secondary border-bottom my-0 pb-3"
    style="font-size: 17px"
    dir="rtl">
    <i class="fas fa-university pl-2"></i>
    {{ data.title }}
    <button
      mat-icon-button
      class="float-left mt-2 hover-danger"
      (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div mat-dialog-content class="bg-creamy pt-3 border-top">
    <app-tree
      [treeData]="treeData"
      [searchTerm]="searchTerm"
      class="m-0"></app-tree>
  </div>
</div>
