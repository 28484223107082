import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpLoadingService } from '../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class SystemGradeService {
  private url = environment.ApiUrl + '/systemgrades/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findAll(): Observable<any> {
    return this.http.get(this.url + `?cache_bust=${new Date().getTime()}`);
  }

  insertMany(): Observable<any> {
    return this.http.get(
      this.url + `insertmany?cache_bust=${new Date().getTime()}`
    );
  }
  update(gradeId: string, data: any): Observable<any> {
    return this.http.patch(this.url + gradeId, data);
  }

  remove(gradeId: string): Observable<any> {
    return this.http.delete(this.url + gradeId);
  }

  removeAll(): Observable<any> {
    return this.http.delete(this.url);
  }
}
