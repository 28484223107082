<div>
  <app-datse-filter
    [filterDates]="vacationUtilService.filterDates"
    header="فرز {{ title }} حسب ألفترة"
    (filterChange)="onFilterChange($event)">
  </app-datse-filter>
  <div class="card mt-5">
    <div class="card-header">
      <h4><i class="fa fa-calendar"></i> {{ title }}</h4>
      <div class="icheck-greensea ml-3 my-2 d-none d-md-block">
        <input
          class="box"
          type="checkbox"
          name="isCurrentDate"
          [(ngModel)]="vacationUtilService.isCurrentDate"
          id="isCurrentDate"
          (change)="this.findAll()" />
        <label for="isCurrentDate"> فرز الى تاريخ أليوم ؟</label>
      </div>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button
          class="btn btn-print"
          (click)="newOrEdit('new')"
          title="أضافة أجازة  جديدة">
          <i class="fa fa-plus-circle fa-2x"></i>
        </button>
      </div>
    </div>
    <div class="card-body px-0">
      <table
        id="timeoff"
        datatable
        [dtOptions]="this.dt.dtOptions"
        [dtTrigger]="this.dt.dtTrigger"
        class="table w-100 table-bordered table-striped border-0">
        <thead>
          <tr>
            <th class="text-right">#</th>
            <th>من تاريخ</th>
            <th>ألى تاريخ</th>
            <th class="d-none d-md-table-cell">
              {{
                type === 'غياب' ? 'فترة ألغياب ألكلية' : 'مدة ألأجازة ألكلية '
              }}
            </th>
            <th class="d-none d-md-table-cell">ألمده ألمقطوعه</th>
            <th class="text-center">
              <i class="fa fa-ellipsis-v"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i = index">
            <th>{{ i + 1 }}</th>
            <td class="pr-3">
              {{ item.fromDate | formatDate | arabicDate }}
            </td>
            <td class="pr-3">
              {{ item.toDate | formatDate | arabicDate }}
            </td>
            <td class="pr-3 d-none d-md-table-cell">
              {{ findDuration(item) | translateDigits: 'ar' }}
            </td>
            <td class="pr-3 d-none d-md-table-cell">
              {{ findActualDuration(item.duration) | translateDigits: 'ar' }}
            </td>

            <th class="text-center">
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  <i class="fa fa-ellipsis-v text-secondary"></i>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('edit')">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل ألاجازة
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="this.SelectedItem = item; this.delet()">
                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2"></i>
                    حذف ألاجازة
                  </li>
                </ul>
              </div>
            </th>
          </tr>
        </tbody>
      </table>

      <hr />
      <div class="row px-3 table-statistics">
        <div class="col-6 col-md-4">
          <label>مجموع {{ title }} </label>
        </div>
        <div class="col-6 col-md-4">
          <ul>
            <li>
              <span class="pl-2">
                {{ totalDurationDays + ' يوم' | translateDigits: 'ar' }}
              </span>
            </li>
            <li>
              <span class="pl-2">
                {{ totalDuration | translateDigits: 'ar' }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
