<form
  #frm="ngForm"
  class="needs-validation"
  (ngSubmit)="basicInfoService.submit(frm)">
  <div class="card">
    <div class="card-header">
      <h4><i class="fa fa-phone"></i> معلومات ألاتصال</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button type="submit" class="btn btn-print" title="حفظ ألمعلومات ">
          <i class="fa fa-save fa-lg"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <label for="email1" class="form-label">ألبريد ألالكتروني :</label>
          <div class="input-group">
            <input
              type="email"
              class="form-control"
              name="email1"
              id="email1"
              placeholder="you@uofmosul.edu"
              [(ngModel)]="basicInfoService.employee.email"
              required
              #email="ngModel"
              email="true"
              [ngClass]="{ 'is-invalid': email.errors && frm.submitted }" />
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa fa-envelope" aria-hidden="true"></i
              ></span>
            </div>
            <div *ngIf="email.invalid" class="invalid-feedback">
              <div *ngIf="email.errors.email !== null">
                البريد ألالكتروني غير صالح
              </div>
              <div *ngIf="email.errors.required">البريد ألالكتروني مطلوب</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 offset-md-4">
          <label for="mobile" class="form-label">الموبايل :</label>
          <div class="input-group">
            <input
              type="mobile"
              class="form-control"
              name="mobile"
              id="mobile"
              placeholder=""
              [(ngModel)]="basicInfoService.employee.mobile"
              required
              pattern="^((\\+91-?)|0)?[0-9]{10}$"
              #mobile="ngModel"
              [ngClass]="{ 'is-invalid': mobile.errors && frm.submitted }" />
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa fa-mobile-alt" aria-hidden="true"></i
              ></span>
            </div>
            <div *ngIf="mobile.invalid" class="invalid-feedback">
              <div *ngIf="mobile.errors.required">رقم ألموبايل مطلوب</div>
              <div *ngIf="mobile.errors.pattern">رقم ألموبايل غير صالح</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
