<div class="card">
  <div class="card-header">
    <h4 *ngIf="!Item._id && Item.type !== 'غياب'">
      <i class="fa fa-plus"></i> أجازة جديده
    </h4>
    <h4 *ngIf="!Item._id && Item.type === 'غياب'">
      <i class="fa fa-plus"></i> أضافة غياب جديد
    </h4>
    <h4 *ngIf="Item._id && Item.type !== 'غياب'">
      <i class="fa fa-edit"></i> تعديل ألاجازة
    </h4>
    <h4 *ngIf="Item._id && Item.type === 'غياب'">
      <i class="fa fa-edit"></i> تعديل ألغياب
    </h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألاجازة ">
        <i class="fa fa-save fa-lg"></i>
      </button>

      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="this.vacationUtilService.changeViewEvent('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <form #frm="ngForm">
      <div class="row">
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.fromDate"
            [name]="'fromDate'"
            [label]="'من تـاريخ'"
            (dateChange)="Item.fromDate = $event; findDuration()">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.toDate"
            [name]="'toDate'"
            [label]="'ألى تـاريخ'"
            (dateChange)="Item.toDate = $event; findDuration()">
          </app-datepicker>
        </div>
        <div class="col-md-4" *ngIf="Item.type !== 'غياب'">
          <label class="control-label" for="v-d-days"> مدة ألاجازة :</label>
          <span class="badge badge-pill badge-secondary float-left mt-1">{{
            totalDuration
          }}</span>
          <div class="input-group">
            <input
              type="number"
              name="numberOfDayes"
              class="form-control text-center"
              (change)="onDaysChange()"
              [(ngModel)]="Item.duration"
              [placeholder]="Item.duration === 0 ? 'ألأجازة بعدد ألأيام' : ''"
              id="v-d-days" />
            <div class="input-group-append">
              <span class="input-group-text">يوم</span>
            </div>
          </div>
        </div>

        <div class="col-md-4" *ngIf="Item.type === 'غياب'">
          <label class="control-label" for="v-d-days"> مدة ألغياب :</label>
          <span class="badge text-secondary float-left mt-1">{{
            totalDuration
          }}</span>
          <div class="input-group">
            <input
              type="number"
              class="form-control text-center"
              (change)="onDaysChange()"
              [(ngModel)]="Item.duration"
              [placeholder]="Item.duration === 0 ? 'ألغياب بعدد ألأيام' : ''"
              id="v-d-days" />
            <div class="input-group-append">
              <span class="input-group-text">يوم</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="Item.type !== 'أعتياديه قصيره' && Item.type !== 'غياب'">
        <div class="col-md-4">
          <label for="paymentType" class="form-label mr-1"> نوع ألراتب :</label>
          <div class="input-group">
            <select
              class="custom-select shadow-none"
              id="paymentType"
              name="paymentType"
              [(ngModel)]="Item.paymentType"
              required>
              <option
                *ngFor="
                  let item of vacationUtilService.paymentTypes;
                  let j = index
                "
                [value]="item">
                {{ item }}
              </option>
            </select>
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-money-bill text-secondary"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <label class="form-label mr-1">لا تحتسب خدمة لأغراض :</label>
          <div
            class="d-flex justify-content-around border px-2 pt-0 border-radius-20 bg-white">
            <div class="icheck-greensea ml-3">
              <input
                class="box"
                type="checkbox"
                name="upgrade"
                [(ngModel)]="Item.upgradeCountless"
                id="upgrade" />
              <label for="upgrade">
                {{ vacationUtilService.serviceTypes[0] }}
              </label>
            </div>
            <div class="icheck-greensea ml-3">
              <input
                class="box"
                type="checkbox"
                name="retirement"
                [(ngModel)]="Item.retirementCountless"
                id="retirement" />
              <label for="retirement">
                {{ vacationUtilService.serviceTypes[1] }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row mb-1"
        *ngIf="Item.type !== 'أعتياديه قصيره' && Item.type !== 'غياب'">
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.BooknumberDate"
            [name]="'BooknumberDate'"
            [label]="'تاريخ ألأمر ألأداري للأجازه'"
            (dateChange)="Item.BooknumberDate = $event">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <label for="Item.BoonumberDate" class="form-label">
            رقم ألأمر ألأدري للأجازة :</label
          >
          <div class="input-group">
            <input
              type="text"
              id="Item.Booknumber"
              name="Booknumber"
              #Item.Booknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="Item.Booknumber" />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row mb-1"
        *ngIf="Item.type !== 'أعتياديه قصيره' && Item.type !== 'غياب'">
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.fromBooknumberDate"
            [name]="'fromBooknumberDate'"
            [label]="'تاريخ ألأمر ألأداري للأنفكاك'"
            (dateChange)="Item.fromBooknumberDate = $event">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <label for="Item.fromBooknumber" class="form-label">
            رقم ألأمر ألأدري للأنفكاك :</label
          >
          <div class="input-group">
            <input
              type="text"
              id="Item.fromBooknumber"
              name="fromBooknumber"
              #Item.fromBooknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="Item.fromBooknumber" />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        *ngIf="Item.type !== 'أعتياديه قصيره' && Item.type !== 'غياب'">
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.toBooknumberDate"
            [name]="'toBooknumberDate'"
            [label]="'تاريخ ألأمر ألأداري للمباشره'"
            (dateChange)="Item.toBooknumberDate = $event">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <label for="Item.fromBooknumber" class="form-label">
            رقم ألأمر ألأدري للمباشره :</label
          >
          <div class="input-group">
            <input
              type="text"
              id="Item.toBooknumber"
              name="toBooknumber"
              #Item.toBooknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="Item.toBooknumber" />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="Item.type === 'دراسيه'">
        <div class="col-md-4">
          <label for="Item.degreeType" class="form-label">ألشهادة :</label>
          <select
            class="custom-select shadow-none"
            id="Item.degreeType"
            name="degreeType"
            [(ngModel)]="Item.degreeType"
            required>
            <option
              *ngFor="
                let item of this.vacationUtilService.degreeTypes;
                let i = index
              "
              [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <app-selectpicker
            [values]="this.vacationUtilService.countries"
            [selected]="Item.studyCountry"
            [label]="'بلد ألدراسة'"
            (valueChange)="onCountryChange($event)">
          </app-selectpicker>
        </div>

        <div class="col-md-4">
          <label for="Item.studyType" class="form-label">نوع ألدراسة :</label>
          <select
            class="custom-select shadow-none"
            id="Item.studyType"
            name="studyType"
            [(ngModel)]="Item.studyType"
            required>
            <option
              *ngFor="
                let item of this.vacationUtilService.studyTypes;
                let i = index
              "
              [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
            <mat-label class="px-3">ألملاحضات </mat-label>
            <textarea
              matInput
              placeholder=""
              name="notes"
              rows="3"
              class="py-0"
              [(ngModel)]="Item.notes"></textarea>
            <mat-icon matSuffix class="text-secondary">comment</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </form>

    <app-files
      *ngIf="Item.Files"
      [Files]="Item.Files"
      [uploadedFiles]="Item.files"
      (fileDelete)="removeFile(Item._id, $event)"
      [header]="
        Item.type === 'غياب'
          ? 'ألأوامر ألأدارية ألخاصة بالغياب'
          : 'ألأوامر الخاصة بالأجازة وألانفكاك وألمباشره'
      ">
    </app-files>
  </div>
</div>
