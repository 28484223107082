<label [for]="id" class="form-label float-end">{{ label }}</label>
<div class="input-group">
  <input
    type="text"
    [id]="id"
    #datepicker
    placeholder="Datepicker"
    class="form-control"
    bsDatepicker
    (bsValueChange)="onDateChange($event)"
    [bsValue]="input"
    [bsConfig]="{
      isAnimated: true,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    }" />
  <div class="input-group-prepend">
    <span (click)="datepicker.click()" class="input-group-text"
      ><i class="fa fa-calendar-alt" aria-hidden="true"></i
    ></span>
  </div>
</div>
