import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpLoadingService } from '../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialAllowancesService {
  private url = environment.ApiUrl + '/allowances/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  create(data: any): Observable<any> {
    return this.http.post(this.url, data);
  }

  findAll(): Observable<any> {
    return this.http.get(this.url + `?cache_bust=${new Date().getTime()}`);
  }

  update(allowanceId: string, data: any): Observable<any> {
    return this.http.patch(this.url + allowanceId, data);
  }

  findByCategory(category: string): Observable<any> {
    return this.http.get(
      `${this.url}category/${category}?cache_bust=${new Date().getTime()}`
    );
  }

  remove(allowanceId: string): Observable<any> {
    return this.http.delete(this.url + allowanceId);
  }
}
