import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DatatableUtil } from 'src/app/classes/datatable';
import {
  Child,
  FamilyInfo,
  Spouse
} from 'src/app/models/hr/employee/familyinfo';
import {
  ChildInfoService,
  FamilyInfoService,
  SpouceInfoService
} from 'src/app/services/employee/family-info.service';
import { ConfirmationDialogComponent } from 'src/app/shared-components/dialogs/dialogs';

import { FamilyInfoUtilService } from './familyinfo-util-service';

@Component({
  selector: 'app-family-info',
  templateUrl: './family-info.component.html',
  styleUrls: ['./family-info.component.scss']
})
export class FamilyInfoComponent implements OnInit {
  constructor(
    public utilService: FamilyInfoUtilService,
    private Activeroute: ActivatedRoute
  ) {}
  async ngOnInit() {
    this.utilService.userId =
      await this.Activeroute.parent.snapshot.params['userId'];
    this.utilService.getBasicInfo();
  }
}

@Component({
  selector: 'app-family-maritialinfo',
  templateUrl: './templates/maritial-info.html'
})
export class MaritialInfoComponent implements OnInit {
  view = 'list';

  isLoading = false;

  SelectedItem: any = null;
  constructor(
    public utilService: FamilyInfoUtilService,
    public familyinfoService: FamilyInfoService,
    private spouceService: SpouceInfoService,
    private Activeroute: ActivatedRoute,
    private dialog: MatDialog
  ) {}
  async ngOnInit() {
    this.utilService.userId =
      await this.Activeroute.parent.parent.snapshot.params['userId'];
    this.utilService.getBasicInfo();
  }

  get isSingle(): boolean {
    return (
      this.utilService.EmployeeFamilyInfo.maritialStatus ==
      this.utilService.MaritialStatuses[0]
    );
  }

  get numberOfSpouses(): number {
    return this.utilService.EmployeeFamilyInfo.spouses.length;
  }

  get numberOfConfirmedSpouses(): number {
    return this.utilService.EmployeeFamilyInfo.spouses.filter(
      item => item._id != null
    ).length;
  }

  submit() {
    this.isLoading = true;
    if (this.utilService.EmployeeFamilyInfo._id) {
      this.familyinfoService
        .update(
          this.utilService.EmployeeFamilyInfo._id,
          this.utilService.EmployeeFamilyInfo.toFormData()
        )
        .subscribe({
          next: () => {
            this.utilService.getFamilyInfo();
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    } else {
      this.familyinfoService
        .create(
          this.utilService.userId,
          this.utilService.EmployeeFamilyInfo.toFormData()
        )
        .subscribe({
          next: () => {
            this.utilService.getFamilyInfo();
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }

  newOrEdit(view: string) {
    let spouse: Spouse = new Spouse();
    if (view == 'edit') {
      spouse = Object.assign(new Spouse(), this.SelectedItem);
      spouse.marriageDate = new Date(this.SelectedItem.marriageDate);
    }
    this.SelectedItem = spouse;
    this.view = view;
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      position: {
        top: '65px'
      },
      data: {
        title: ` حذف معلومات ${this.utilService.isMale ? 'ألزوجة' : 'ألزوج '}`,
        warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع ألبيانات   بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
        confirmationMessage: 'هل أنت متأكد من أتمام عملية ألحذف ؟',
        yesNo: { yes: 'نعم', no: 'كلا' }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spouceService
          .remove(this.utilService.EmployeeFamilyInfo._id, {
            spouseId: this.SelectedItem._id
          })
          .subscribe(() => {
            this.utilService.getFamilyInfo();
          });
      }
    });
  }
}

@Component({
  selector: 'app-spouse-form',
  templateUrl: './templates/spouse-form.component.html'
})
export class SpouseFormComponent {
  @Input() InfoId: string = null;
  @Input() Item: Spouse = null;
  @Input() isMale = true;

  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();

  isLoading = false;

  constructor(private spouseService: SpouceInfoService) {}

  submit(frm: NgForm) {
    if (frm.invalid) return;
    this.isLoading = true;
    if (this.Item._id) {
      this.spouseService.update(this.InfoId, this.Item.toFormData()).subscribe({
        next: () => {
          this.isLoading = false;
          this.viewChange.emit('true');
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    } else {
      this.spouseService.create(this.InfoId, this.Item.toFormData()).subscribe({
        next: () => {
          this.isLoading = false;
          this.viewChange.emit('true');
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
  }

  removeFile(fileId: string) {
    this.spouseService
      .removeFile(this.InfoId, { fileId, spouseId: this.Item._id })
      .subscribe(() => null);
  }
}

@Component({
  selector: 'app-family-childreninfo',
  templateUrl: './templates/children-info.html'
})
export class ChildrenInfoComponent implements AfterViewInit {
  SelectedItem: any = null;
  userId: string = null;
  view = 'list';
  EmployeeFamilyInfo: FamilyInfo = new FamilyInfo();
  children: any[] = [];
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();

  constructor(
    public childInfoService: ChildInfoService,
    public utilService: FamilyInfoUtilService,
    private familyinfoService: FamilyInfoService,
    private Activeroute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  async ngAfterViewInit() {
    this.userId =
      await this.Activeroute.parent.parent.snapshot.params['userId'];
    this.dt.setDtElement(this.dtElement);
    this.getFamilyInfo();
    this.dt.trigger();
  }

  getFamilyInfo() {
    this.familyinfoService.findOne(this.userId).subscribe(response => {
      if (response != null) {
        this.EmployeeFamilyInfo = Object.assign(new FamilyInfo(), response);
        this.children = this.EmployeeFamilyInfo.children;
        this.dt.reload();
        this.view = 'list';
      }
    });
  }

  newOrEdit(view: string) {
    let child: Child = new Child();
    if (view == 'edit') {
      child = Object.assign(child, this.SelectedItem);
      child.birthDate = new Date(this.SelectedItem.birthDate);
    }
    this.SelectedItem = child;
    this.view = view;
  }

  removeChild() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      position: {
        top: '65px'
      },
      data: {
        title: ` حذف ألطفل ${this.SelectedItem.name}`,
        warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات هذا ألشخص بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
        confirmationMessage: 'هل أنت متأكد من أتمام عملية ألحذف ؟',
        yesNo: { yes: 'نعم', no: 'كلا' }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.childInfoService
          .removeChild(this.EmployeeFamilyInfo._id, {
            childId: this.SelectedItem._id
          })
          .subscribe(() => {
            this.getFamilyInfo();
          });
      }
    });
  }
}

@Component({
  selector: 'app-child-form',
  templateUrl: './templates/child-form.component.html'
})
export class ChildFormComponent implements OnInit {
  @Input() InfoId: string = null;
  @Input() Item: Child = null;

  MaritialStatuses: string[] = [];

  isLoading = false;

  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public childInfoService: ChildInfoService,
    public utilService: FamilyInfoUtilService
  ) {}
  async ngOnInit() {
    if (this.Item.gender == 'ذكر') {
      this.MaritialStatuses = this.utilService.maleMaritialStatuses;
    } else {
      this.MaritialStatuses = this.utilService.femaleMaritialStatuses;
    }
  }

  onGenderChange() {
    this.MaritialStatuses =
      this.Item.gender == 'ذكر'
        ? this.utilService.maleMaritialStatuses
        : this.utilService.femaleMaritialStatuses;
    this.Item.maritialStatus = this.MaritialStatuses[0];
  }

  submit(frm: NgForm) {
    if (frm.invalid) return;
    this.isLoading = true;
    if (this.Item._id) {
      this.childInfoService
        .update(this.InfoId, this.Item.toFormData())
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.viewChange.emit('true');
          },
          error: () => {
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    } else {
      this.childInfoService
        .create(this.InfoId, this.Item.toFormData())
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.viewChange.emit('true');
          },
          error: () => {
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }

  removeFile(fileId: string) {
    this.childInfoService
      .removeFile(this.InfoId, { fileId, childId: this.Item._id })
      .subscribe(() => null);
  }
}
