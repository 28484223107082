import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbsenseVacation,
  CareVacation,
  CompanionshipVacation,
  DisabilityVacation,
  FiveYearsVacation,
  NormalLongVacation,
  SickLeaveVacation,
  StudyVacation,
  UnpaidVacation,
  Vacation
} from 'src/app/models/hr/employee/vacations';

import { VacationUtilService } from './vacation-util.service';

@Component({
  selector: 'app-vacations',
  templateUrl: './vacations.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class VacationsComponent {}

@Component({
  selector: 'app-vacation-sick-leave',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class SickLeaveVacationsComponent implements OnInit, OnDestroy {
  type = 'مرضيه';
  view = 'list';
  title = 'ألأجازات ألمرضيه';

  vacation: SickLeaveVacation = new SickLeaveVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new SickLeaveVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-vacation-unpaid',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class UnpaidVacationsComponent implements OnInit, OnDestroy {
  type = 'بدون راتب';
  view = 'list';
  title = 'ألأجازات بدون راتب';

  vacation: UnpaidVacation = new UnpaidVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new UnpaidVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-vacation-five-years',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class FiveYearsVacationsComponent implements OnInit, OnDestroy {
  type = 'خمس سنوات';
  view = 'list';
  title = 'أجازة خمس سنوات';

  vacation: FiveYearsVacation = new FiveYearsVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new FiveYearsVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-vacation-normal-short',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class NormalShortVacationsComponent implements OnInit, OnDestroy {
  type = 'أعتياديه قصيره';
  view = 'list';
  title = 'ألاجازات الاعتيادية ألقصيره';

  vacation: Vacation = new Vacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new Vacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-vacation-normal-long',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class NormalLongVacationsComponent implements OnInit, OnDestroy {
  type = 'أعتياديه طويله';
  view = 'list';
  title = 'ألاجازات ألاعتيادية ألطويله';

  vacation: NormalLongVacation = new NormalLongVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new NormalLongVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        new NormalLongVacation(this.type),
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-vacation-normal-long',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class StudyVacationsComponent implements OnInit, OnDestroy {
  type = 'دراسيه';
  view = 'list';
  title = 'ألأجازات ألدراسيه';

  vacation: StudyVacation = new StudyVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new StudyVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-companionship-vacation-long',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class CompanionshipVacationComponent implements OnInit, OnDestroy {
  type = 'مصاحبه زوجيه';
  view = 'list';
  title = 'أجازات ألمصاحبه ألزوجيه';

  vacation: CompanionshipVacation = new CompanionshipVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new CompanionshipVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-care-vacation-long',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class CareVacationComponent implements OnInit, OnDestroy {
  type = 'أعاله';
  view = 'list';
  title = 'أجازات ألأعاله';

  vacation: CareVacation = new CareVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new CareVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-disabiliry-vacation-long',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class DisabilityVacationComponent implements OnInit, OnDestroy {
  type = 'عجز صحي';
  view = 'list';
  title = 'أجازات  ألعجز ألصحي';

  vacation: DisabilityVacation = new DisabilityVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new DisabilityVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}

@Component({
  selector: 'app-absense-vacation-long',
  templateUrl: './templates/vacation.view.component.html',
  styleUrls: ['./vacations.component.scss']
})
export class AbsenseVacationComponent implements OnInit, OnDestroy {
  type = 'غياب';
  view = 'list';
  title = ' ألغيابات';

  vacation: AbsenseVacation = new AbsenseVacation(this.type);
  constructor(private vacationUtilService: VacationUtilService) {}

  ngOnInit(): void {
    this.vacationUtilService.getViewEvent().subscribe(view => {
      if (view == 'list') {
        this.view = view;
      } else {
        this.newOrEdit(view);
      }
    });
  }
  newOrEdit(view: string) {
    this.vacation = new AbsenseVacation(this.type);
    if (view == 'edit') {
      this.vacation = Object.assign(
        this.vacation,
        this.vacationUtilService.SelectedVacation
      );
    }
    this.view = view;
  }
  ngOnDestroy(): void {
    this.vacationUtilService.getViewEvent().subscribe();
  }
}
