import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { FilesService } from '../files/file.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private url: string = environment.ApiUrl + '/users/';
  constructor(
    private http: HttpClient,
    private fileService: FilesService
  ) {}

  create(data: any): Observable<any> {
    return this.http.post(this.url, data);
  }

  remove(id: string): Observable<any> {
    return this.http.delete(`${this.url}${id}`);
  }

  update(userId: string, data: any): Observable<any> {
    return this.http.patch(`${this.url}${userId}`, data);
  }

  findOne(id: string): Observable<any> {
    return this.http.get(`${this.url}${id}`);
  }

  findWorkPlaceEmployees(entityId: string): Observable<any> {
    return this.http.get(
      `${this.url}employees/${entityId}?cache_bust=${new Date().getTime()}`
    );
  }

  findAll(): Observable<any> {
    return this.http.get(this.url);
  }

  findRoleUsers(roleId: string): Observable<any> {
    return this.http.get(
      `${this.url}roleusers/${roleId}?cache_bust=${new Date().getTime()}`
    );
  }

  uploadPhoto(userId: string, photo: FormData) {
    return this.http.post(`${this.url}uploadphoto/${userId}`, photo);
  }

  getUserPhoto(fileId: string) {
    return this.fileService.getFileUrl(fileId);
  }

  updateWorkplace(userId: string, data: any): Observable<any> {
    return this.http.post(`${this.url}updateworkplace/${userId}`, data);
  }
}
