import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EmployeeSummariesService {
  private url = environment.ApiUrl + '/employeesummaries/';

  constructor(private http: HttpClient) {}

  findGeneralSummary(userId: string): Observable<any> {
    return this.http.get(
      this.url +
        'generalSummary/' +
        userId +
        `?cache_bust=${new Date().getTime()}`
    );
  }
}
