import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpLoadingService } from '../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class GradeService {
  private url = environment.ApiUrl + '/employeegrades/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findAll(userId: string): Observable<any> {
    return this.http.get(
      this.url + userId + `?cache_bust=${new Date().getTime()}`
    );
  }
  create(userId: string, data: any): Observable<any> {
    return this.http.post(this.url + userId, data);
  }

  update(gradeId: string, data: any): Observable<any> {
    return this.http.patch(this.url + gradeId, data);
  }

  remove(gradeId: string) {
    return this.http.delete(this.url + gradeId);
  }

  removeGradeFile(gradeId: string, data: any) {
    return this.http.patch(this.url + 'removefile/' + gradeId, data);
  }

  getCurrentGradeBonus(userId: string): Observable<any> {
    return this.http.get(this.url + 'currentgradebonus/' + userId);
  }
}

@Injectable({
  providedIn: 'root'
})
export class BonusService {
  private url = environment.ApiUrl + '/employeebonuses/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findAll(gradeId: string): Observable<any> {
    return this.http.get(
      this.url + gradeId + `?cache_bust=${new Date().getTime()}`
    );
  }
  create(gradeId: string, data: any): Observable<any> {
    return this.http.post(this.url + gradeId, data);
  }

  update(stageId: string, data: any): Observable<any> {
    return this.http.patch(this.url + stageId, data);
  }

  remove(bonusId: string) {
    return this.http.delete(this.url + bonusId);
  }

  removeBonusFile(stageId: string, data: any) {
    return this.http.patch(this.url + 'removefile/' + stageId, data);
  }
}
