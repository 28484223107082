<div class="row">
  <div class="col-md-12">
    <div class="d-flex">
      <i class="fa fa-exclamation-triangle fa-2x text-danger pl-5"></i>
      <p
        class="text-justify font-size-18"
        style="font-size: 16px !important; line-height: 28px"
        [innerHTML]="message"
        dir="rtl"></p>
    </div>
  </div>
</div>
