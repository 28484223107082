import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DatatableUtil } from 'src/app/classes/datatable';
import { ItemFlatNode, ItemNode } from 'src/app/models/system/structure';
import { DataInjectionService } from 'src/app/services/system-manager/data-injection-service';
import { UsersService } from 'src/app/services/users/users.service';

export class DataImporter {
  workplaceId: string = null;
  file: File = new File([''], '', { type: '' });

  isValidFile() {
    return this.file.name.length != 0;
  }
}

@Component({
  selector: 'app-data-import',
  templateUrl: 'data-injection.component.html'
})
export class DataImporterComponent implements AfterViewInit {
  @Input() node: ItemFlatNode;
  @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
  importer: DataImporter = new DataImporter();
  users: any[] = [];
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dt: DatatableUtil = new DatatableUtil();
  view = 'list';
  Item: ItemNode = new ItemNode();
  SelectedUser: any = null;

  constructor(
    private userService: UsersService,
    public router: Router,
    private dataInjectionService: DataInjectionService
  ) {}

  async ngAfterViewInit() {
    this.importer.workplaceId = this.node._id;
    this.dt.setDtElement(this.dtElement);
    this.findEmployees();
    this.dt.trigger();
  }

  findEmployees() {
    this.userService
      .findWorkPlaceEmployees(this.node._id)
      .subscribe(response => {
        this.users = response;
        this.view = 'list';
        this.dt.reload();
      });
  }

  newOrEdit(view: string) {
    this.view = view;
  }

  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.importer.file = <File>event.target.files[0];
    }
  }

  submit() {
    const data = new FormData();
    data.append('workplaceId', this.importer.workplaceId);
    data.append('file', this.importer.file, this.importer.file.name);

    this.dataInjectionService
      .create(this.importer.workplaceId, data)
      .subscribe(() => {
        this.findEmployees();
      });
  }

  deleteAll() {
    this.dataInjectionService
      .deleteAll(this.importer.workplaceId)
      .subscribe(() => {
        this.findEmployees();
      });
  }
}
