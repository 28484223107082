import { Injectable } from '@angular/core';

import { ConstantsUtils } from '../../../../classes/constants';
@Injectable({
  providedIn: 'root'
})
export class LocationService {
  getProvinces() {
    return ConstantsUtils.getProvincesList();
  }
  getCountries() {
    return ConstantsUtils.getCountriesList();
  }
  getCities(province: string): string[] {
    const cities = ConstantsUtils.getProvinceCitiesByName(province);
    return cities;
  }
}
