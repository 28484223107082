import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorComponent } from './components/error/error.comonent';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoginComponent } from './home/account/login/login.component';
import { FooterComponent } from './home/headers/footer/footer.component';
import { TopheaderComponent } from './home/headers/topheader/topheader.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './home/page-not-found/page-not-found.component';
import { ProfileComponent } from './home/profile/profile.component';
import { TestComponent } from './home/test/test.component';
import { WelcomePageComponent } from './home/welcome-page/welcome-page.component';
import { HrModule } from './hr/hr.module';
import { HrAdminComponent } from './hr/hr-admin/hr-admin/hr-admin.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { MaterialModule } from './material';
import { AppInterceptorService } from './services/shared/app-interceptor.service';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { SystemManagerModule } from './system-manager/system-manager.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopheaderComponent,
    FooterComponent,
    LoginComponent,
    PageNotFoundComponent,
    HrAdminComponent,
    WelcomePageComponent,
    ProfileComponent,
    TestComponent,
    SpinnerComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    ModalModule.forRoot(),

    HrModule,
    SystemManagerModule,
    SharedComponentsModule,
    EditorModule,
    AppRoutingModule,

    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    },
    BsModalRef
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
    console.log('configure sentry', trace);
  }
}
