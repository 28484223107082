import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { FilesService } from 'src/app/services/files/file.service';

import { Files, ImageDimension, ImageFile } from '../../classes/Files';
import { ConfirmationDialogComponent } from '../dialogs/dialogs';
//declare const $: any;
@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  @Input() Files: Files;
  @Input() header: string;
  @Input() uploadedFiles: Array<any> = [];
  @Output() fileDelete: EventEmitter<string> = new EventEmitter<string>();
  SelectedFile: any = null;
  id = 'files';
  constructor(
    public fileService: FilesService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.id = this.setElementId('files');
  }

  onFileSelected(i: number, event: any) {
    if (event.target.files.length > 0) {
      this.Files.Filelist[i] = <File>event.target.files[0];
    }
  }
  removeFile() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      position: {
        top: '65px'
      },
      data: {
        title: ` حذف ألملف `,
        warningMessage: `<span class="text-danger">تحذير : </span> حذف هذ ألملف سيؤدي ألى حذف ألملف بشكل نهائي من ألنظام ولا يمكن أسترجاعه فيما بعد .`,
        confirmationMessage: 'هل أنت متأكد من أتمام عملية ألحذف ؟',
        yesNo: { yes: 'نعم', no: 'كلا' }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.uploadedFiles.findIndex(
          file => file._id == this.SelectedFile._id
        );
        this.fileService.delete(this.SelectedFile._id).subscribe({
          next: () => {
            this.uploadedFiles.splice(index, 1);
          }
        });
        this.fileDelete.emit(this.SelectedFile._id);
      }
    });
  }

  setElementId(name: string): string {
    const id = name + Math.round(Math.random() * 100000);
    return id;
  }
  getFileSize(size: number) {
    const sizeInMB = (size / (1024 * 1024)).toFixed(2);
    return sizeInMB;
  }

  preview(id: string) {
    window.open(this.fileService.getFileUrl(id), '_blank');
  }

  download(id: string, filename: string) {
    saveAs(this.fileService.getFileUrl(id), filename);
  }

  downloadArchive() {
    const fileId = this.uploadedFiles[0]._id;
    const paths: string[] = this.uploadedFiles[0].path.split('/');
    const filename = paths[paths.length - 2] + '.zip';
    saveAs(this.fileService.downloadArchive(fileId), filename);
  }
}

@Component({
  selector: 'app-files-image-file-uploader',
  templateUrl: './image-file-uploader.component.html'
})
export class ImageFileUploaderComponent {
  @Input() image: ImageFile;
  @Input() dimensions: ImageDimension;
  @Output() fileChange: EventEmitter<ImageFile> = new EventEmitter();

  @ViewChild('inputfile') private inputfile: ElementRef<HTMLInputElement>;
  private isOver = false;
  constructor(private sanitizer: DomSanitizer) {}

  @HostListener('click') private onClick() {
    this.inputfile.nativeElement.click();
  }

  @HostListener('drop', ['$event']) private onDrop(event: DragEvent) {
    this.onDrag(event);
    if (event.dataTransfer.files) {
      this.image.file = <File>event.dataTransfer.files[0];
      this.image.url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(this.image.file)
      );
      this.fileChange.emit();
    }
  }

  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.image.file = <File>event.target.files[0];
      this.image.url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(this.image.file)
      );
      this.fileChange.emit();
    }
  }

  @HostListener('drag', ['$event'])
  @HostListener('dragstart', ['$event'])
  @HostListener('dragenter', ['$event'])
  @HostListener('dragover', ['$event'])
  @HostListener('dragleave', ['$event'])
  @HostListener('dragend', ['$event'])
  private onDrag(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isOver = ['dragenter', 'dragover'].includes(event.type);
  }
}
