<div class="document-container">
  <div class="row">
    <div class="col-md-4">
      <app-files-image-file-uploader
        [image]="item.faceOne"
        (fileChange)="documentHelper.uploadFile()"
        [dimensions]="item.dimensions">
      </app-files-image-file-uploader>
    </div>
    <div class="col-md-4 offset-md-4">
      <app-files-image-file-uploader
        [image]="item.faceTwo"
        (fileChange)="documentHelper.uploadFile()"
        [dimensions]="item.dimensions">
      </app-files-image-file-uploader>
    </div>
  </div>
</div>
