<div class="card">
  <div class="card-header">
    <h4 *ngIf="!Item._id"><i class="fa fa-plus"></i> أجازة جديده</h4>
    <h4 *ngIf="Item._id"><i class="fa fa-edit"></i> تعديل ألاجازة</h4>
    <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
      <button class="btn btn-print" (click)="submit()" title="حفظ ألاجازة ">
        <i class="fa fa-save fa-lg"></i>
      </button>
      <button
        class="btn btn-print"
        title="رجوع ألى ألقائمه"
        (click)="viewChange.emit('list')">
        <i class="fa fa-share fa-lg"></i>
      </button>
    </div>
  </div>
  <div class="card-body px-0">
    <div class="row mx-3 mb-5">
      <div class="col-md-4">
        <app-datepicker
          [input]="Item.expectedBirthDate"
          [name]="'fromDate'"
          [label]="'تاريخ ألولادة ألمتوقع'"
          (dateChange)="Item.expectedBirthDate = $event">
        </app-datepicker>
      </div>
      <div class="col-md-4">
        <app-datepicker
          [input]="Item.realBirthDate"
          [name]="'toDate'"
          [label]="'تاريخ ألولادة ألفعلي'"
          (dateChange)="Item.realBirthDate = $event">
        </app-datepicker>
      </div>

      <div class="col-md-4 pt-4">
        <button
          type="button"
          [disabled]="Item.stages.length === 4"
          class="btn btn-success btn-block px-2 mt-1"
          (click)="Item.addStage()"
          title="جميع ألنتائج">
          <i class="fas fa-plus fa-rotate-180 fa-1x pr-3"></i> أضافة مرحلة
        </button>
      </div>
    </div>

    <div class="row mx-3">
      <div class="col-md-12">
        <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
          <mat-label class="px-3">ألملاحضات </mat-label>
          <textarea
            matInput
            placeholder=""
            rows="3"
            class="py-0"
            [(ngModel)]="Item.notes"></textarea>
          <mat-icon matSuffix class="text-secondary">comment</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div *ngFor="let stage of Item.stages; let i = index" class="steps">
      <div class="row header-info mx-0">
        <span class="circle">{{ i + 1 }}.</span>
        <span class="title"
          ><i class="fa fa-calendar-alt text-secondary pl-2"></i
          >{{ stages[i] }}</span
        >
        <button
          class="btn remove-button"
          [disabled]="i + 1 < Item.stages.length || i === 0"
          (click)="this.SelectedStage = stage; SelectedIndex = i; this.delet()"
          title="حذف ألمرحله">
          <i class="fa fa-trash"></i>
        </button>
        <a
          href="#stage_{{ i }}"
          data-toggle="collapse"
          aria-expanded="true"
          class="dropdown-toggle collapse-button"></a>
      </div>

      <div class="collapse show px-5" id="stage_{{ i }}">
        <div class="row">
          <div class="col-md-4">
            <app-datepicker
              [input]="stage.fromDate"
              [name]="'fromDate_{{i}}'"
              [label]="'من تـاريخ'"
              (dateChange)="stage.fromDate = $event; findDuration(stage)">
            </app-datepicker>
          </div>
          <div class="col-md-4">
            <app-datepicker
              [input]="stage.toDate"
              [name]="'toDate_{{i}}'"
              [label]="'ألى تـاريخ'"
              (dateChange)="stage.toDate = $event; findDuration(stage)">
            </app-datepicker>
          </div>
          <div class="col-md-4">
            <label class="control-label" for="v-d-days"> مدة ألاجازة :</label>
            <span class="badge badge-pill badge-secondary float-left mt-1">{{
              getDuarationStr(stage)
            }}</span>
            <div class="input-group">
              <input
                type="number"
                class="form-control text-center"
                [(ngModel)]="stage.duration"
                (change)="onDaysChange(stage)" />
              <div class="input-group-append">
                <span class="input-group-text">يوم</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label for="paymentType_{{ i }}" class="form-label mr-1">
              نوع ألراتب :</label
            >
            <div class="input-group">
              <select
                class="custom-select shadow-none"
                id="paymentType_{{ i }}"
                name="paymentType"
                [(ngModel)]="stage.paymentType"
                required>
                <option
                  *ngFor="
                    let item of vacationUtilService.paymentTypes;
                    let j = index
                  "
                  [value]="item">
                  {{ item }}
                </option>
              </select>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-money-bill text-secondary"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <label class="form-label mr-1">لا تحتسب خدمة لأغراض :</label>
            <div class="d-flex justify-content-around border px-2 pt-0">
              <div class="icheck-greensea ml-3">
                <input
                  class="box"
                  type="checkbox"
                  name="upgrade_{{ i }}"
                  [(ngModel)]="stage.upgradeCountless"
                  id="upgrade_{{ i }}" />
                <label for="upgrade_{{ i }}">
                  {{ vacationUtilService.serviceTypes[0] }}
                </label>
              </div>
              <div class="icheck-greensea ml-3">
                <input
                  class="box"
                  type="checkbox"
                  name="retirement_{{ i }}"
                  [(ngModel)]="stage.retirementCountless"
                  id="retirement_{{ i }}" />
                <label for="retirement_{{ i }}">
                  {{ vacationUtilService.serviceTypes[1] }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-md-4">
            <app-datepicker
              [input]="stage.BooknumberDate"
              [name]="'BooknumberDate_{{i}}'"
              [label]="'تاريخ ألأمر ألأداري للأجازه'"
              (dateChange)="stage.BooknumberDate = $event">
            </app-datepicker>
          </div>
          <div class="col-md-4">
            <label for="Booknumber_{{ i }}" class="form-label">
              رقم ألأمر ألأدري للأجازة :</label
            >
            <div class="input-group">
              <input
                type="text"
                id="Booknumber_{{ i }}"
                name="Booknumber_{{ i }}"
                #ItemBooknumber="ngModel"
                class="form-control text-center"
                [(ngModel)]="stage.Booknumber" />
              <div class="input-group-prepend">
                <span class="input-group-text font-bold"> # </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" [ngClass]="{ 'mb-1': i !== 0 }">
          <div class="col-md-4">
            <app-datepicker
              [input]="stage.fromBooknumberDate"
              [name]="'fromBooknumberDate_{{i}}'"
              [label]="'تاريخ ألأمر ألأداري للأنفكاك'"
              (dateChange)="stage.fromBooknumberDate = $event">
            </app-datepicker>
          </div>
          <div class="col-md-4">
            <label for="fromBooknumber_{{ i }}" class="form-label">
              رقم ألأمر ألأدري للأنفكاك :</label
            >
            <div class="input-group">
              <input
                type="text"
                id="Item.fromBooknumber"
                name="fromBooknumber_{{ i }}"
                #fromBooknumber="ngModel"
                class="form-control text-center"
                [(ngModel)]="stage.fromBooknumber" />
              <div class="input-group-prepend">
                <span class="input-group-text font-bold"> # </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="i !== 0">
          <div class="col-md-4">
            <app-datepicker
              [input]="stage.toBooknumberDate"
              [name]="'toBooknumberDate_{{i}}'"
              [label]="'تاريخ ألأمر ألأداري للمباشره'"
              (dateChange)="stage.toBooknumberDate = $event">
            </app-datepicker>
          </div>
          <div class="col-md-4">
            <label for="Item.fromBooknumber" class="form-label">
              رقم ألأمر ألأدري للمباشره :</label
            >
            <div class="input-group">
              <input
                type="text"
                id="toBooknumber_{{ i }}"
                name="toBooknumber_{{ i }}"
                #toBooknumber="ngModel"
                class="form-control text-center"
                [(ngModel)]="stage.toBooknumber" />
              <div class="input-group-prepend">
                <span class="input-group-text font-bold"> # </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <app-files
              [Files]="stage.Files"
              [uploadedFiles]="stage.files"
              (fileDelete)="removeStageFile(stage._id, $event)"
              [header]="
                'ألاوامر ألادارية ألخاصة بالأجازة و ألأنفكاك وألمباشره'
              ">
            </app-files>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
