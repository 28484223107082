import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePeriod } from 'src/app/classes/Utils';

@Component({
  selector: 'app-datse-filter',
  templateUrl: './datse-filter.component.html',
  styleUrls: ['./datse-filter.component.scss']
})
export class DatseFilterComponent {
  id = 'datefilter';
  @Input() filterDates: DatePeriod = new DatePeriod();
  @Input() header = '';
  @Output() filterChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  isFilter = false;
}
