import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpLoadingService } from '../shared/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class BasicinfoService {
  private url = environment.ApiUrl + '/basicinfo/';
  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findOne(id: string): Observable<any> {
    return this.http.get(this.url + id);
  }
  createOrUpdate(userId: string, data: any): Observable<any> {
    return this.http.post(this.url + userId, data);
  }
}
