export const leaders: string[] = [
  'رئيس جامعة',
  'مساعد رئيس جامعة',
  'عميد',
  'رئيس قسم علمي',
  'مدير مركز',
  'مدير قسم أداري',
  'وزير',
  'وكيل وزير',
  'مدير عام',
  'رئيس ألجمهورية',
  'رئيس  مجلس النواب',
  'رئيس  مجلس ألوزراء',
  'عضو مجلس ألنواب',
  'محافظ',
  'مستشار',
  'رئيس المجلس العراقي للاختصاصات ألطبية',
  'رئيس مجلس شورى الدولة',
  'رئيس الجهاز',
  'رئيس جهاز الاشراف',
  'سفير',
  'مفتش عام',
  'الامين العام',
  'رئيس الديوان',
  'رئيس المحكمة',
  'رئيس هيئة التقاعد الوطنية',
  'نائب رئيس ديوان الرقابة المالية',
  'مستشار الامن القومي',
  'نائب المستشار',
  'نائب رئيس الهيئة الوطنية للاستثمار',
  'قاضي المحكمة',
  'قاضي محكمة التمييز',
  'رئيس هيئة الاشراف القضائي',
  'رئيس الادعاء العام',
  'نائب رئيس هيئة النزاهة',
  'نائب رئيس المفوضية',
  'رئيس الهيئة العراقية للحاسبات والمعلوماتية'
];
