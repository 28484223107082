export class ItemNode {
  _id: string = null;
  children: ItemNode[];
  name: string;
  type: string;
  level = 0;
  code = '';
  enName = '';
  constructor(name = '', type = '', children: ItemNode[] = null) {
    this.name = name;
    this.type = type;
    this.children = children;
  }
}

export class ItemFlatNode {
  name: string;
  level: number;
  type: string;
  _id: string;

  expandable: boolean;
}
