<form
  #frm="ngForm"
  class="needs-validation"
  (ngSubmit)="employmentUtileService.submit()">
  <div class="card">
    <div class="card-header">
      <h4><i class="fa fa-info-circle"></i> معلومات ألتعيين</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button type="submit" class="btn btn-print" title="حفظ ألمعلومات ">
          <i class="fa fa-save fa-lg"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-8 py-0">
          <label class=" ">ملاك ألموظف : </label>
          <div
            class="d-flex justify-content-between border px-3 border-radius-25 bg-white">
            <div
              class="icheck-greensea pt-1"
              *ngFor="let type of employmentTypes; let i = index">
              <input
                class="box"
                type="radio"
                name="employmentTypes"
                [(ngModel)]="
                  employmentUtileService.EmploymentInfo.employmentType
                "
                [value]="type"
                id="employmentTypes_{{ i }}" />
              <label for="employmentTypes_{{ i }}"> {{ type }} </label>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label for="employeeNumber" class="form-label">
            ألرقم ألوظيفي :</label
          >
          <div class="input-group">
            <input
              type="text"
              id="employeeNumber"
              name="employeeNumber"
              #employeeNumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="
                employmentUtileService.EmploymentInfo.employmentNumber
              " />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <app-datepicker
            [input]="employmentUtileService.EmploymentInfo.hireDate"
            [name]="'hireDate'"
            [label]="'تاريخ اول تعيين'"
            (dateChange)="
              employmentUtileService.EmploymentInfo.hireDate = $event
            ">
          </app-datepicker>
        </div>
        <div class="col-md-4">
          <app-datepicker
            [input]="employmentUtileService.EmploymentInfo.hireStartDate"
            [name]="'hireStartDate'"
            [label]="'تاريخ ألمباشره بعد اول تعيين'"
            (dateChange)="
              employmentUtileService.EmploymentInfo.hireStartDate = $event
            ">
          </app-datepicker>
        </div>

        <div class="col-md-4">
          <label for="hireBooknumber" class="form-label">
            رقم ألامر ألأداري بالتعيين:</label
          >
          <div class="input-group">
            <input
              type="text"
              id="hireBooknumber"
              name="hireBooknumber"
              #hireBooknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="
                employmentUtileService.EmploymentInfo.hireBooknumber
              " />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-md-5 mt-2">
          هل ألموظف متعين في وزارات أخرى سابقا ؟
          <span class="px-2 float-none float-md-left font-bold">:</span>
        </div>
        <div class="col-md-3 d-flex bg-white border-radius-20 border">
          <div class="icheck-greensea w-50">
            <input
              class="box"
              type="radio"
              name="isEmployeedPreviously"
              [(ngModel)]="
                employmentUtileService.EmploymentInfo.isEmployeedPreviously
              "
              [value]="true"
              id="isEmployeedPreviously_1" />
            <label for="isEmployeedPreviously_1"> نعم </label>
          </div>
          <div class="icheck-greensea w-50">
            <input
              class="box"
              type="radio"
              name="isEmployeedPreviously"
              [(ngModel)]="
                employmentUtileService.EmploymentInfo.isEmployeedPreviously
              "
              [value]="false"
              id="isEmployeedPreviously_2" />
            <label for="isEmployeedPreviously_2"> كلا</label>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="employmentUtileService.EmploymentInfo.isEmployeedPreviously">
        <div class="col-md-4">
          <app-datepicker
            [input]="employmentUtileService.EmploymentInfo.movingToMohDate"
            [name]="'movingToMohDate'"
            [label]="'تاريخ ألانتقال ألى وزارة ألتعليم ألعالي '"
            (dateChange)="
              employmentUtileService.EmploymentInfo.movingToMohDate = $event
            ">
          </app-datepicker>
        </div>

        <div class="col-md-4">
          <app-datepicker
            [input]="employmentUtileService.EmploymentInfo.movingToMohStartDate"
            [name]="'movingToMohStartDate'"
            [label]="'تاريخ ألمباشره في وزارة ألتعليم'"
            (dateChange)="
              employmentUtileService.EmploymentInfo.movingToMohStartDate =
                $event
            ">
          </app-datepicker>
        </div>

        <div class="col-md-4">
          <label for="movingToMohBooknumber" class="form-label">
            رقم ألامر ألأداري بالتنقل:</label
          >
          <div class="input-group">
            <input
              type="text"
              id="movingToMohBooknumber"
              name="movingToMohBooknumber"
              #movingToMohBooknumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="
                employmentUtileService.EmploymentInfo.movingToMohBooknumber
              " />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-md-5 mt-2">
          هل ألموظف لديه أعادة تعيين ؟
          <span class="px-2 float-none float-md-left font-bold">:</span>
        </div>
        <div class="col-md-3 d-flex border border-radius-25 bg-white">
          <div class="icheck-greensea w-50">
            <input
              class="box"
              type="radio"
              name="isRehired"
              [(ngModel)]="employmentUtileService.EmploymentInfo.isRehired"
              [value]="true"
              id="isRehired_1" />
            <label for="isRehired_1"> نعم </label>
          </div>
          <div class="icheck-greensea w-50">
            <input
              class="box"
              type="radio"
              name="isRehired"
              [(ngModel)]="employmentUtileService.EmploymentInfo.isRehired"
              [value]="false"
              id="isRehired_2" />
            <label for="isRehired_2"> كلا</label>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="employmentUtileService.EmploymentInfo.isRehired">
        <div class="col-md-4">
          <app-datepicker
            [input]="employmentUtileService.EmploymentInfo.rehireDate"
            [name]="'movingToMohDate'"
            [label]="'تاريخ أعادة ألتعيين    '"
            (dateChange)="
              employmentUtileService.EmploymentInfo.rehireDate = $event
            ">
          </app-datepicker>
        </div>

        <div class="col-md-4">
          <app-datepicker
            [input]="employmentUtileService.EmploymentInfo.rehireStartDate"
            [name]="'movingToMohStartDate'"
            [label]="'تاريخ ألمباشره بعد أعادة ألتعيين'"
            (dateChange)="
              employmentUtileService.EmploymentInfo.rehireStartDate = $event
            ">
          </app-datepicker>
        </div>

        <div class="col-md-4">
          <label for="rehireDocumentNumber" class="form-label">
            رقم ألامر ألأداري بأعادة ألتعيين:</label
          >
          <div class="input-group">
            <input
              type="text"
              id="rehireDocumentNumber"
              name="rehireDocumentNumber"
              #rehireDocumentNumber="ngModel"
              class="form-control text-center"
              [(ngModel)]="
                employmentUtileService.EmploymentInfo.rehireDocumentNumber
              " />
            <div class="input-group-prepend">
              <span class="input-group-text font-bold"> # </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-files
            [Files]="employmentUtileService.EmploymentInfo.EmploymentFiles"
            [uploadedFiles]="
              employmentUtileService.EmploymentInfo.employmentfiles
            "
            (fileDelete)="
              employmentUtileService.removeFile($event, 'employmentfiles')
            "
            [header]="
              'ألاوامر ألادارية ألخاصة بالتعيين أو ألتنقلات من خارج ألوزارة'
            "></app-files>
        </div>
      </div>
    </div>
  </div>
</form>
