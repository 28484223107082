<app-workplace-list
  [ngClass]="{ 'd-none': view !== 'list' }"
  [query]="query"
  [title]="title"></app-workplace-list>

<app-workplace-form
  *ngIf="view !== 'list'"
  [Item]="workplace"
  [titles]="titles"
  [type]="title"></app-workplace-form>
