<div [ngClass]="{ 'd-none': view !== 'list' }">
  <div class="card mt-6">
    <div class="card-header">
      <h4><i class="fas fa-signal pl-3"></i>جدول ألترفيعات</h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button
          class="btn btn-print"
          (click)="newOrEdit('new')"
          title="أضافة ترفيع">
          <i class="fa fa-plus-circle fa-2x"></i>
        </button>
      </div>
    </div>
    <div class="card-block px-0">
      <table
        id="upgradesummary"
        datatable
        [dtOptions]="this.dt.dtOptions"
        [dtTrigger]="this.dt.dtTrigger"
        class="table w-100 table-bordered table-striped border-0 my-0">
        <thead>
          <tr>
            <th class="d-none d-md-table-cell">#</th>
            <th class="d-sm-flex d-md-table-cell">ألدرجة ألوظيفية</th>
            <th class="d-none d-md-table-cell">ألعنوان ألوظيفي</th>
            <th>تاريخ ألاستحقاق</th>

            <th class="text-center width-10">
              <i class="fa fa-ellipsis-v"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i = index">
            <th class="d-none d-md-table-cell">{{ i + 1 }}</th>
            <td class="pr-md-3 d-block d-md-table-cell">
              {{ item.grade }}
            </td>
            <td class="pr-md-3 d-block d-md-table-cell">
              {{ item.jobTitle }}
            </td>
            <td class="pr-3">
              {{ item.deserveDate | formatDate }}
            </td>
            <th class="text-center w-10">
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  <i class="fa fa-ellipsis-v text-secondary"></i>
                </button>
                <ul
                  class="dropdown-menu bg-creamy"
                  aria-labelledby="dropdownMenuButton">
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('edit')">
                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                    تعديل ألدرجة {{ item.grade }}
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="SelectedItem = item; newOrEdit('bonuses')">
                    <i class="fa fa-table fa-lg px-1 text-secondary"></i>
                    جدول علاوات {{ item.grade }}
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="this.SelectedItem = item; this.delet()">
                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2"></i>
                    حذف ألدرجة {{ item.grade }}
                  </li>
                </ul>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-bonuses-list
  *ngIf="view === 'bonuses'"
  [userId]="userId"
  [title]="SelectedItem.grade"
  [gradeId]="SelectedItem._id"
  (viewChange)="this.findAll()"></app-bonuses-list>
<app-upgrade-form
  *ngIf="view === 'new'"
  [userId]="userId"
  (viewChange)="this.findAll()"></app-upgrade-form>
<app-upgrade-form
  *ngIf="view === 'edit'"
  [userId]="userId"
  [Item]="SelectedItem"
  (viewChange)="this.findAll()"></app-upgrade-form>
