<form #frm="ngForm" (ngSubmit)="submit(frm)">
  <div class="card">
    <div class="card-header">
      <h4 *ngIf="Item._id">
        <i class="fa fa-edit"></i> تعديل معلومات
        {{ isMale ? 'ألزوجة' : 'ألزوج' }}
      </h4>
      <h4 *ngIf="!Item._id">
        <i class="fa fa-edit"></i> معلومات
        {{ isMale ? 'ألزوجة' : 'ألزوج' }}
      </h4>
      <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
        <button type="submit" class="btn btn-print" title="حفظ ألمعلومات ">
          <i class="fa fa-save fa-lg"></i>
        </button>
        <button
          class="btn btn-print"
          title="رجوع ألى ألقائمه"
          (click)="viewChange.emit('list')">
          <i class="fa fa-share fa-lg"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <label for="partenername" class="form-label">
            أسم {{ isMale ? 'ألزوجة' : 'ألزوج' }} ألكامل
          </label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="partenername"
              id="partenername"
              placeholder=""
              [(ngModel)]="Item.spouseName"
              required
              #spouseName="ngModel"
              minlength="3"
              lang="ar"
              [ngClass]="{
                'is-invalid': spouseName.errors && frm.submitted
              }" />
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i
                  class="fa fa-female fa-1x"
                  *ngIf="isMale"
                  aria-hidden="true"></i>
                <i
                  class="fa fa-male fa-1x"
                  *ngIf="!isMale"
                  aria-hidden="true"></i>
              </span>
            </div>
            <div *ngIf="spouseName.invalid" class="invalid-feedback">
              <div *ngIf="spouseName.errors.required !== null">
                أسم {{ isMale ? 'ألزوجة' : 'ألزوج' }} مطلوب
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <app-datepicker
            [input]="Item.marriageDate"
            [name]="'marriageDate'"
            [label]="'تاريخ ألزواج'"
            (dateChange)="Item.marriageDate = $event">
          </app-datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label class="py-2"
            >هل {{ isMale ? 'ألزوجة موظفة' : 'ألزوج موظف' }} في أحد دوائر
            ألدولة؟
          </label>
          <span class="font-bold float-none float-md-left py-2">:</span>
        </div>
        <div class="col-md-4">
          <div class="d-flex border border-radius-25 bg-white px-2">
            <div class="icheck-greensea w-50">
              <input
                class="box"
                type="radio"
                name="IsPartenerEmployeed"
                [(ngModel)]="Item.isSpouseEmployeed"
                [value]="true"
                id="IsPartenerEmployeedYes" />
              <label for="IsPartenerEmployeedYes" class="px-5"> نعم </label>
            </div>
            <div class="icheck-greensea w-50">
              <input
                class="box"
                type="radio"
                name="IsPartenerEmployeed"
                [(ngModel)]="Item.isSpouseEmployeed"
                [value]="false"
                id="IsPartenerEmployeedNo" />
              <label for="IsPartenerEmployeedNo" class="px-5"> كلا </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-files
            [Files]="Item.Files"
            [uploadedFiles]="Item.files"
            (fileDelete)="removeFile($event)"
            [header]="'ألوثائق وألملفات ألخاصة بالزواج'">
          </app-files>
        </div>
      </div>
    </div>
  </div>
</form>
