import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpLoadingService } from 'src/app/services/shared/http-loading.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-myinfo',
  templateUrl: './myinfo.component.html',
  styleUrls: ['./myinfo.component.scss']
})
export class MyinfoComponent {
  constructor(
    private userService: UsersService,
    private loadingService: HttpLoadingService,
    private Activeroute: ActivatedRoute
  ) {
    this.Activeroute.params.subscribe(params => {
      const userId = params['userId'];
      this.userService.findOne(userId).subscribe(response => {
        this.loadingService.Title = response.fullname;
      });
    });
  }
}
