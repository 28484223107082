export class Role {
  _id: string = null;
  name = '';
  link = '';
  category = '';
  description = '';
  priviliges: string[] = [];
  constructor(category: string) {
    this.category = category;
  }
}

export class UserRole {
  roleId: string = null;
  userId: string = null;
  workplaceId: string = null;
  constructor(
    roleId: string = null,
    userId: string = null,
    workplaceId: string = null
  ) {
    this.roleId = roleId;
    this.userId = userId;
    this.workplaceId = workplaceId;
  }
}
